import React from "react";
import useText from '../../../hooks/useText'
import TextsSection from './TextsSection'
import { Tabs, Skeleton, Card } from 'antd';

const { TabPane } = Tabs;

const PageTexts = () => {
  const { texts, loading, updateText } = useText({ fetchTexts: true })

  const renderOption = (text) => (
    <TabPane tab={text.name} key={text.id}>
      <TextsSection data={text} onSave={updateText} />
    </TabPane>
  )

  if (loading) return <Skeleton active />

  return (
    <Card>
      <Tabs defaultActiveKey="1" tabPosition='right'>
        {texts.map(renderOption)}
      </Tabs>
    </Card>
  );
};

export default PageTexts;
