import React from "react";
import classNames from "classnames"
import colorClasses from '../../style/colorClasses.module.scss'
import classes from './Row.module.scss'

const Row = ({ color, children }) => (
  <div className={classNames(classes.row, colorClasses[`border-color-${color}`])}>
    {children}
  </div>
)

export default Row;
