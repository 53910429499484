import React from "react";
import classes from "./Interesting.module.scss";
import { Typography } from "antd";
import { TeamOutlined } from '@ant-design/icons';
import useMediaQuery from '../../../hooks/useMediaQuery'
import { Link } from "react-router-dom";
const { Text } = Typography;

const Interesting = () => {
  const { isMobile } = useMediaQuery();

  return (
    <Link to='/interested'>
      <div className={classes.interesting}>
        <TeamOutlined />
        {!isMobile && <Text className={classes.interestingText}>מתעניינים</Text>}
      </div>
    </Link>
  );
};

export default Interesting;
