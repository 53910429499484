import React, { useContext, useState, useEffect, useMemo } from "react";
import myFirebase, { auth } from "../firebase";
import firebase from "firebase/app";
import errorMessages from "../utils/errorMessages";
import { compactHash } from "../utils/utils";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loadUser, setLoadUser] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const clear = () => {
    setTimeout(() => {
      setError(false)
      setSuccess(false)
      setLoading(false)
    }, 0);
  }

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) getUser(user.uid);
      else setLoadUser(true);
    });

    return unsubscribe;
  }, []);

  const getUser = async id => {
    try {
      const user = await myFirebase.db.collection("users").doc(id).get();
      setCurrentUser(user.data());
      setLoadUser(true);
    } catch { }
  };

  const signUp = async values => {
    setLoading(true);
    try {
      const { email, password, ...rest } = values;
      const authUser = await auth.createUserWithEmailAndPassword(email, password);
      const id = authUser.user.uid;
      const user = { ...rest, email, id, courses: {} };
      await myFirebase.db.collection("users").doc(id).set(compactHash(user));
      setCurrentUser(user);
      setLoading(false);
      clear()
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
      clear()
    }
  };

  const signIn = async ({ email, password }) => {
    setLoading(true);
    setSuccess(false)
    try {
      await auth.signInWithEmailAndPassword(email, password);
      setLoading(false);
      setSuccess(true)
      clear()
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
      clear()
    }
  };

  const signOut = async () => {
    setLoading(true);
    try {
      await auth.signOut();
      setCurrentUser(null);
      setLoading(false);
      clear()
    } catch {
      setLoading(false);
      clear()
    }
  };

  const updateAuthsUser = async values => {
    setLoading(true);
    setSuccess(false);
    try {
      const { email, oldPassword, password } = values;
      const user = auth.currentUser;
      const newEmail = user.email === email ? null : email;

      if (newEmail || password) {
        const credential = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
        await user.reauthenticateWithCredential(credential);
        if (newEmail) await user.updateEmail(newEmail);
        if (password) await user.updatePassword(password);
      }

      await myFirebase.db.collection("users").doc(user.uid).update(compactHash({ email, id: user.uid }));
      setLoading(false);
      setSuccess(true);
      setCurrentUser({ ...currentUser, email: newEmail || currentUser.email })
      clear()
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
      clear()
    }
  };

  const updateDetailsUser = async values => {
    setLoading(true);
    setSuccess(false);
    try {
      const user = auth.currentUser;
      await myFirebase.db.collection("users").doc(user.uid).update(compactHash(values));
      setLoading(false);
      setSuccess(true);
      clear()
      setCurrentUser({ ...currentUser, ...values })
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
      clear()
    }
  };

  const forgotPassword = async ({ email }) => {
    setLoading(true);
    setSuccess(false);
    try {
      await auth.sendPasswordResetEmail(email);
      setLoading(false);
      setSuccess(true);
      clear()
    } catch {
      setError(errorMessages(error.code));
      setLoading(false);
      clear()
    }
  };

  const value = useMemo(
    () => ({
      currentUser,
      signUp,
      signIn,
      signOut,
      updateDetailsUser,
      updateAuthsUser,
      forgotPassword,
      loading,
      error,
      success
    }),
    [currentUser, loading, error, success]
  );

  return (
    <AuthContext.Provider value={value}>
      {loadUser && children}
    </AuthContext.Provider>
  );
}
