import React from "react";
import MiddleLayout from "../common/MiddleLayout"
import classes from './AdminLayout.module.scss'

const AdminLayout = ({ children }) => (
  <MiddleLayout className={classes.middleLayout}>
    {children}
  </MiddleLayout>
)

export default AdminLayout;
