import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { AuthProvider } from "./contexts/AuthContext";
import { CourseProvider } from "./contexts/CourseContext";
import { CacheProvider } from "./contexts/CacheContext";
import { StorageProvider } from "./contexts/StorageContext";
import Routes from "./components/portal/Routes";
import classes from "./App.scss";
import "antd/dist/antd.css";
import { Layout, ConfigProvider } from 'antd';
import TopBar from "./components/portal/TopBar/TopBar"
import FloatContactUs from './components/portal/ContactUs/FloatContactUs'
import Footer from "./components/portal/Footer/Footer"
import ScrollToTop from './components/common/ScrollToTop'
import useMediaQuery from './hooks/useMediaQuery'

const { Content } = Layout;

const App = () => {
  const { isMobile, isTablet, isLaptop } = useMediaQuery();

  const getMargin = () => {
    if (isMobile) return '55px'
    if (isTablet) return '60px'
    if (isLaptop) return '65px'
    return '75px'
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        <AuthProvider>
          <CourseProvider>
            <StorageProvider>
              <CacheProvider>
                <ConfigProvider direction="rtl">
                  <Layout className={classes.layout}>
                    <TopBar />
                    <FloatContactUs />
                    <Content style={{ marginTop: getMargin() }}>
                      <div className="site-layout-content">
                        <Routes />
                      </div>
                    </Content>
                    <Footer />
                  </Layout>
                </ConfigProvider>
              </CacheProvider>
            </StorageProvider>
          </CourseProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
