import myFirebase from "../firebase";
import { useState } from "react";
import chunk from 'lodash.chunk'

const useLesson = (params = {}) => {
  const { startLoading } = params
  const [lessons, setLessons] = useState({});
  const [loading, setLoading] = useState(startLoading);

  const setLessonsData = (newLessons) => {
    setLessons(newLessons)
    setLoading(false)
  }

  const getLesson = async id => {
    try {
      let imageUrl = 'error';
      const lessonData = await myFirebase.db.collection("lessons").doc(id).get();
      const storageRef = myFirebase.storage.ref();
      const imageRef = storageRef.child(`LessonsImages/${id}`);
      try {
        imageUrl = await imageRef.getDownloadURL();
      } catch { }
      return ({ ...lessonData.data(), imageUrl })
    } catch {
      console.log('error occurred while loading the lesson')
    }
  };

  const getLessons = async (ids) => {
    console.log('THIS IS GET LESSON')
    try {
      setLoading(false)
      if (!ids.length) return null
      const lessons = []
      const promises = []
      const storageRef = myFirebase.storage.ref();
      setLoading(true)
      const chunkIds = chunk(ids, 10)
      const lessonsHash = {}
      for (const ids of chunkIds) {
        await myFirebase.db.collection("lessons").where(myFirebase.fieldPath.documentId(), 'in', ids).get()
          .then(async (querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const imageRef = storageRef.child(`LessonsImages/${doc.id}`);
              const imageUrl = imageRef.getDownloadURL();
              promises.push(imageUrl)
              lessons.push(doc.data());
            });
          })
      }
      const urlsList = await Promise.all(promises.map(p => p.catch(() => false)))
      urlsList.forEach((imageUrl, index) => lessons[index] = { ...lessons[index], imageUrl })
      for (const lesson of lessons) {
        lessonsHash[lesson.id] = lesson
      }
      setLoading(false)
      setLessons(lessonsHash)
      return lessonsHash
    } catch (e) {
      setLoading(false)
      console.log('error occurred while loading the lessons: ', e)
    }
  };

  const updateCurrentLesson = async ({ userId, courseId, newCourses }) => {
    try {
      await myFirebase.db.collection("users").doc(userId).update({ courses: newCourses });
    } catch {
      console.log('error occurred while updateCurrentLesson')
    }
  };

  const updateLesson = async ({ lessonId, lesson }) => {
    try {
      await myFirebase.db
        .collection("lessons")
        .doc(lessonId)
        .update(lesson);
    } catch { }
  }

  const canMoveToNextLesson = async ({ userId, courseId, lessonNumber }) => {
    try {
      const userData = await myFirebase.db.collection("users").doc(userId).get();
      const user = userData.data();
      return Number(user.courses[courseId]) > lessonNumber;
    } catch {
      console.log('error occurred while loading canMoveToNextLesson')
    }
  };
  return { getLesson, getLessons, canMoveToNextLesson, updateCurrentLesson, updateLesson, lessons, loading, setLessonsData }
};

export default useLesson;
