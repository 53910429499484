import React from "react"
import classes from "./SignUpButton.module.scss"
import { Button } from "antd";
import { FormOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import useMediaQuery from '../../../hooks/useMediaQuery'

const SignUpButton = () => {
  const { isMobile } = useMediaQuery();

  return (
    <Link to='/signup'>
      <Button className={classes.button} size={isMobile ? 'medium' : 'large'}>
        <FormOutlined />
        {!isMobile && 'הצטרפות'}
      </Button>
    </Link>
  );
};

export default SignUpButton;
