import React from "react"
import { Typography, Divider } from "antd"
import {FacebookFilled, InstagramFilled, YoutubeFilled} from '@ant-design/icons';
import MiddleLayout from '../../common/MiddleLayout'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { Link } from "react-router-dom";
import classNames from "classnames"
import classes from "./Footer.module.scss"

const { Text } = Typography

const Footer = () => {
  const { isMobile } = useMediaQuery()

  const renderDivider = () => (
    isMobile && <Divider className={classes.divider} />
  )

  const renderListLink = (to, text) => (
    <Link to={to}>
      <Text>{text}</Text>
    </Link>
  )

  return (
    <div>
    <footer className={classes.footer}>
      <MiddleLayout className={classes.layout} stretch={false}>
        <div className={classes.content}>
          <div className={classes.listWrapper}>
            <ul className={classNames(classes.list, classes.firstList)}>
              <li className={classes.item}>
                {renderListLink('/courses', 'הקורסים שלנו')}
              </li>
              <li className={classes.item}>
                {renderListLink('/how_to_study', 'איך ללמוד')}
              </li>
              <li className={classes.item}>
                {renderListLink('/interested', 'לפני שנרשמים')}
              </li>
            </ul>
            {renderDivider()}
          </div>
          <div className={classes.listWrapper}>
            <ul className={classes.list}>
              <li className={classes.item}>
                {renderListLink('/', 'מכלול - המרכז ללימודים טכניים')}
              </li>
              <li className={classes.item}>
                <Text>
                  <a href='tel:17005557100'>6372*</a>
                </Text>
              </li>
              <li className={classes.item}>
                <Text>
                  <a href="mailto:michlol.office@gmail.com">michlol.office@gmail.com</a>
                </Text>
              </li>
            </ul>
            {renderDivider()}
          </div>
          <div className={classes.listWrapper}>
            <ul className={classes.list}>
              <li className={classes.item}>
                {renderListLink('/about', 'אודות')}
              </li>
              <li className={classes.item}>
                {renderListLink('/terms', 'תקנון האתר')}
              </li>
              <li className={classes.item}>
                {renderListLink('/cancellation_policies', 'מדיניות ביטולים')}
              </li>
              <div className={classes.socialButton}>
                <li className={classNames(classes.item, classes.icons)}>
                  <a rel="noopener noreferrer" href='https://www.facebook.com/michlol.tsc/' target="_blank">
                    <FacebookFilled className={classes.facebook} />
                  </a>
                </li>
                <li className={classNames(classes.item, classes.icons)}>
                  <a rel="noopener noreferrer" href='https://www.instagram.com/internationallockingacademy/' target="_blank">
                    <InstagramFilled className={classes.instagram} />
                  </a>
                </li>
                <li className={classNames(classes.item, classes.icons)}>
                  <a rel="noopener noreferrer" href='https://www.youtube.com/channel/UCh69PJOPJzRngNphwaWdb6Q' target="_blank">
                    <YoutubeFilled className={classes.youtube} />
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
        {/* <div>
          <img
            src='https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/logo.png?alt=media&token=4ce2ca6e-56b3-43bf-8c2a-9a3bb310e5ba'
            alt="לוגו"
            title="לוגו"
            className={classes.logo}
          />
        </div> */}
      </MiddleLayout>
    </footer>
      <div className={classes.appsForce}>
        <Text className={classes.appsForceTitle}>Created by the AppsForce Development team © 2021</Text>
      </div>
    </div>
  );
};

export default Footer;
