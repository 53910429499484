import { useMediaQuery as useMQ } from 'react-responsive'

const useMediaQuery = () => {
  const isMobile = useMQ({ maxWidth: 767 })
  const isTablet = useMQ({ minWidth: 768, maxWidth: 991 })
  const isLaptop = useMQ({ minWidth: 992, maxWidth: 1199 })
  const isDesktop = useMQ({ minWidth: 1200, maxWidth: 1459 })
  const isDesktopL = useMQ({ minWidth: 1460 })

  return { isMobile, isTablet, isLaptop, isDesktop, isDesktopL }

}

export default useMediaQuery
