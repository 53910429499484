import React from "react";
import classes from "./Grid.module.scss";
import { Skeleton } from 'antd';
import classNames from "classnames"

const Grid = ({ children, loading }) => {
  const renderLoadingItems = () => {
    const emptyItems = [...Array(10).keys()];
    return emptyItems.map(val => (
      <GridItem key={val}>
        <Skeleton.Button active className={classes.skeleton} />
      </GridItem>
    ));
  };

  return (
    <div className={classes.grid}>
      {!loading && children}
      {loading && renderLoadingItems()}
    </div>
  );
};

const GridItem = ({ children, className, ...props }) => (
  <div className={classNames(classes.gridItem, className)}>
    <div className={classes.item} {...props}>
      {children}
    </div>
  </div>
);

Grid.Item = GridItem;
export default Grid;
