import React from "react";
import SectionHeader from './SectionHeader'
import MiddleLayout from "../../common/MiddleLayout"
import { Steps, Popover, Typography } from 'antd';
import Slide from 'react-reveal/Slide';
import SectionScrollDetected from './SectionScrollDetected'
import useMediaQuery from '../../../hooks/useMediaQuery'
import classes from "./Section3.module.scss";
const { Step } = Steps;

const POPOVER_TEXT = [
  {
    title: 'קבוצות לימוד קטנות',
    text: 'קבוצות לימוד קטנות בין אם בחרת במפגשי הנחיה פרונטליים במרכז לימוד או במפגשים מקוונים אינטראקטיביים – מפגשי ההנחיה מתקיימים כולם בקבוצות קטנות, המאפשרות יחס אישי ותקשורת מרבית עם המורה ועם הסטודנטים האחרים.'
  },
  {
    title: 'כל חומרי הלימוד על הבית',
    text: 'ככל חומרי הלימוד לקורס פותחו במכלול והם מוצגים לך באתר הלימוד שלנו בזמן ההרשמה לקורס. יחד עם מפגשי הלימוד המעשי אתר הלימוד מהווה עבורך מעטפת לימודית מושלמת שזמינה עבורך בכל רגע.'
  },
  {
    title: 'אתר הקורסים',
    text: 'כל קורס במכלול מתחיל בלימוד מקוון דרך האתר, אליו מקבל הסטודנט שם משתמש וסיסמה על מנת שיוכל לצפות בשיעורי הקורס המקוונים. בשיעורים המקוונים תוכל לצפות בשיעורים מצולמים, מצגות, דוגמאות, ומבחנים.'
  },
  {
    title: 'ייעוץ ללימודים',
    text: 'במכלול אנו דוגלים בלימוד מעשי תוך לווי צמוד של צוות המורים יחד עם הסטודנטים.'
  },
  {
    title: 'לימוד מעשי',
    text: 'במכלול אנו דוגלים בלימוד מעשי תוך לווי צמוד של צוות המורים יחד עם הסטודנטים.'
  },
  {
    title: 'מוקד הפניות',
    text: 'מוקד הפניות של מכלול עומד לרשותך בכל שאלה הקשורה ללימודיך. במקרה הצורך, יידעו נציגי המוקד להפנות אותך לכתובת המתאימה במכללה וילוו אותך בתהליך.'
  },
]

const Section3 = ({ onVisisbleChange }) => {
  const { isMobile } = useMediaQuery()

  const customDot = (dot, { index }) => (
    <Popover
      content={
        <div className={classes.popover}>
          <Typography.Text className={classes.title}>{POPOVER_TEXT[index]?.title}</Typography.Text>
          <Typography.Text className={classes.text}>{POPOVER_TEXT[index]?.text}</Typography.Text>
        </div>
      }
    >
      <span className={classes.dot}>
        <span className={classes.innerDot} />
      </span>
    </Popover>
  );


  const src = isMobile ?
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection3-mobile.jpg?alt=media&token=51a140fb-075f-4dda-a618-cf180a256253' :
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection3.jpg?alt=media&token=cb27bfa0-9529-4200-a8f8-de24bd3a6d5b'

  return (
    <div className={classes.section} style={{ backgroundImage: `url(${src})` }}>
      <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
      <MiddleLayout>
        <SectionHeader
          number='3'
          color='dark-brand'
          title='איתך לאורך כל הדרך'
          text='ההצלחה שלך חשובה לנו, ולכן אנחנו מעמידים לרשותך "ארגז כלים" אקדמי שיסייע לך לממש את הפוטנציאל שלך'
        />
        <Slide right>
          <div className={classes.lineWrapper}>
            <Steps current={10} progressDot={customDot}>
              <Step title="קבוצות לימוד קטנות" />
              <Step title="כל חומרי הלימוד עד הבית" />
              <Step title="אתרי הקורסים" />
              <Step title="ייעוץ ללימודים" />
              <Step title="לימוד מעשי" />
              <Step title="מוקד הפניות" />
            </Steps>
          </div>
        </Slide>
      </MiddleLayout>
    </div>
  );
};

export default Section3;
