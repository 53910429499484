import React from "react";
import Home from './Home/Home'
import HowToStudy from './HowToStudy/HowToStudy'
import Interested from './Interested/Interested'
import Blog from './Blog/Blog'
import Terms from './Terms/Terms'
import CancellationPolicies from './CancellationPolicies/CancellationPolicies'
import SignUp from "./SignUp";
import SignInPage from "./SignInPage";
import Courses from "./Course/Courses";
import MyCourses from './Course/MyCourses'
import Lessons from "./Lesson/Lessons";
import Admin from "../admin/Admin";
import UserProfile from "./UserProfile/UserProfile";
import classes from "./Routes.module.scss";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import Lesson from "./Lesson/Lesson";
import Course from "./Course/Course";
import About from './About/About'

const Routes = () => {
  const { currentUser } = useAuth();

  const renderUserLoginRoutes = (isAdmin) => (
    <>
      <Route exact path="/profile">
        <UserProfile />
      </Route>
      <Route exact path="/my_courses">
        <MyCourses />
      </Route>
      {isAdmin && (
        <Route path="/admin">
          <Admin />
        </Route>
      )}
    </>
  )

  const renderGuestRoutes = () => (
    <>
      <Route exact path="/signup">
        <SignUp />
      </Route>
      <Route exact path="/login">
        <SignInPage />
      </Route>
    </>
  )

  return (
    <Layout>
      <Layout.Content className={classes.content}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/courses/:courseId">
            <Course />
          </Route>
          <Route exact path="/courses/:courseId/lessons">
            <Lessons />
          </Route>
          <Route exact path="/how_to_study">
            <HowToStudy />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/interested">
            <Interested />
          </Route>
          <Route exact path="/terms">
            <Terms />
          </Route>
          <Route exact path="/cancellation_policies">
            <CancellationPolicies />
          </Route>
          <Route exact path="/blog/:blogId">
            <Blog />
          </Route>
          <Route exact path="/courses">
            <Courses />
          </Route>
          <Route render={props => <Lesson key={props.match.params.lessonId} {...props} />} exact path="/courses/:courseId/lessons/:lessonId" />
          {currentUser ? renderUserLoginRoutes(currentUser.isAdmin) : renderGuestRoutes()}
          <Route>
            <Redirect to='/' />
          </Route>
        </Switch>
      </Layout.Content>
    </Layout>
  );
};

export default Routes;
