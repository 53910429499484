import React, { useEffect } from "react";
import classes from "./Carousel.module.scss";
import { Carousel as AntCarousel, Typography, Skeleton } from 'antd';
import useMediaQuery from '../../../hooks/useMediaQuery'
import { useStorage } from '../../../contexts/StorageContext'
import Fade from 'react-reveal/Fade';

const { Title, Text } = Typography;

const renderSkeleton = () => <div><Skeleton.Button active className={classes.skeleton} /></div>

const renderLoadingPage = () => (
  <div className={classes.carousel}>
    <AntCarousel autoplay dotPosition='right' effect='fade'>
      <div className={classes.item}>
        {renderSkeleton()}
      </div>
    </AntCarousel>
  </div>
)

const Carousel = () => {
  const { isMobile } = useMediaQuery()
  const { coverImages, getCoverImages, coverImagesCounter } = useStorage()

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (!coverImages.length) getCoverImages()
  }, [coverImages, getCoverImages])

  if (!coverImages.length || coverImagesCounter !== 0) return renderLoadingPage()

  const renderImage = ({ desktopUrl, mobileUrl, header, text }, index) => (
    <div className={classes.item} key={index}>
      <img
        src={isMobile ? mobileUrl : desktopUrl}
        alt=""
        title={header}
        className={classes.image}
      />
      <div className={classes.textWrapper}>
        <Fade top>
          <Title className={classes.header}>{header}</Title>
        </Fade>
        <Fade bottom>
          <Text className={classes.text}>{text}</Text>
        </Fade>
      </div>
    </div>
  )

  return (
    <div className={classes.carousel}>
      <AntCarousel autoplay dotPosition='right' effect='fade'>
        {coverImages.map(renderImage)}
      </AntCarousel>
    </div>
  );
};

export default Carousel;
