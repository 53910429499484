import React from "react";
import { Tooltip } from "antd";
import TableFilter from '../components/admin/TableFilter'
import { Link } from "react-router-dom";
import { CheckOutlined } from '@ant-design/icons';

export const getUsersColumns = ({ lastSortField, order, show }, onSearchChange) => {
	const allColumns = usersColumns(lastSortField, onSearchChange)
	const orderColumns = order.map(field => allColumns[field])
	const shownColumns = orderColumns.filter(field => show[field.key])
	return shownColumns
}

export const usersColumns = (lastSortField, onSearchChange) => ({
	firstName: {
		title: "שם פרטי",
		dataIndex: "firstName",
		key: "firstName",
		render: (firstName, { id }) => <Link to={`/admin/users/${id}`}>{firstName}</Link>,
		sorter: true,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField.field === 'firstName' && lastSortField.order,
		filterDropdown: () => <TableFilter onSearch={onSearchChange} field='firstName' />
	},
	lastName: {
		title: "שם משפחה",
		dataIndex: "lastName",
		key: "lastName",
		render: (lastName, { id }) => <Link to={`/admin/users/${id}`}>{lastName}</Link>,
		sorter: true,
		sortDirections: ['ascend', 'descend', 'ascend'],
		filterDropdown: () => <TableFilter onSearch={onSearchChange} field='lastName' />,
		sortOrder: lastSortField.field === 'lastName' && lastSortField.order,
	},
	email: {
		title: "מייל",
		dataIndex: "email",
		key: "email",
		sorter: true,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField.field === 'email' && lastSortField.order,
		ellipsis: true,
		filterDropdown: () => <TableFilter onSearch={onSearchChange} field='email' />,
		render: (email, { id }) => (
			<Tooltip placement="topLeft" title={email}>
				<Link to={`/admin/users/${id}`}>{email}</Link>
			</Tooltip>
		),
	},
	createdAt: {
		title: "נרשם",
		dataIndex: "createdAt",
		key: "createdAt",
		render: createdAt => createdAt?.toLocaleDateString?.() || createdAt?.toDate().toLocaleDateString(),
		sorter: true,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField.field === 'createdAt' && lastSortField.order,
	},
	phone: {
		title: "טלפון",
		dataIndex: "phone",
		key: "phone",
		sortDirections: ['ascend', 'descend', 'ascend'],
		filterDropdown: () => <TableFilter onSearch={onSearchChange} field='phone' />,
	},
	isActive: {
		title: "מאושר",
		dataIndex: "isActive",
		key: "isActive",
		render: isActive => isActive ? <CheckOutlined /> : '-',
		sorter: true,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField.field === 'isActive' && lastSortField.order
	}
})

export const getCoursesColumns = ({ lastSortField }) => [
	{
		title: "שם הקורס",
		render: (name, { id }) => <Link to={`/admin/courses/${id}`}>{name}</Link>,
		dataIndex: "name",
		key: "name",
		sorter: (a, b) => a.name.localeCompare(b.name),
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField?.field === 'name' && lastSortField?.order,
	},
	{
		title: "מספר שיעורים",
		dataIndex: "lessons",
		key: "lessons",
		render: lessons => Object.keys(lessons).length,
		sorter: (a, b) => Object.keys(a.lessons).length - Object.keys(b.lessons).length,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField?.field === 'lessons' && lastSortField?.order,
	},
	{
		title: "תאריך יצירה",
		dataIndex: "createdAt",
		key: "createdAt",
		render: createdAt => createdAt?.toDate()?.toLocaleDateString(),
		sorter: (a, b) => a.createdAt - b.createdAt,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField?.field === 'createdAt' && lastSortField?.order,
	}
]

export const getLessonsColumns = ({ courseId, lastSortField }) => [
	{
		title: "מספר השיעור",
		dataIndex: "number",
		key: "number",
		sorter: (a, b) => a.number - b.number,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField?.field === 'number' && lastSortField?.order
	},
	{
		title: "שם השיעור",
		render: (name, { id }) => <Link to={`/admin/courses/${courseId}/lessons/${id}/edit`}>{name}</Link>,
		dataIndex: "name",
		key: "name",
	},
	{
		title: "תאריך יצירה",
		dataIndex: "createdAt",
		key: "createdAt",
		render: createdAt => createdAt?.toDate()?.toLocaleDateString(),
	}
]

export const getBlogsColumns = ({ lastSortField }) => [
	{
		title: "שם הבלוג",
		dataIndex: "name",
		key: "name",
		render: (name, { id }) => <Link to={`/admin/blog/${id}`}>{name}</Link>,
		sorter: (a, b) => a.name.localeCompare(b.name),
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField?.field === 'name' && lastSortField?.order,
	},
	{
		title: "תאריך יצירה",
		dataIndex: "createdAt",
		key: "createdAt",
		render: createdAt => createdAt?.toDate()?.toLocaleDateString(),
		sorter: (a, b) => a.createdAt - b.createdAt,
		sortDirections: ['ascend', 'descend', 'ascend'],
		sortOrder: lastSortField?.field === 'createdAt' && lastSortField?.order,
	}
]
