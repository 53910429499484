import React, { useState } from "react";
import { Input, Button, Divider } from 'antd';
import message from '../../../utils/message'
import classes from "./TextsSection.module.scss";

const TextsSection = ({ data, onSave }) => {
  const [texts, setTexts] = useState(data.texts)

  const handleSave = () => {
    onSave({ ...data, texts })
    message.success("שמירה בוצעה בהצלחה")
  }

  const handleTextChange = (e, index, type) => {
    const newTexts = [...texts]
    newTexts[index][type] = e.target.value
    setTexts(newTexts)
  }

  return (
    <div>
      <ul className={classes.list}>
        {texts.map(({ header, text }, index) => (
          <li className={classes.item} key={index}>
            <div className={classes.content}>
              <Input addonBefore='כותרת' className={classes.input} value={header} onChange={(e) => handleTextChange(e, index, 'header')} />
              <Input addonBefore='טקסט' className={classes.input} value={text} onChange={(e) => handleTextChange(e, index, 'text')} />
            </div>
            <Divider />
          </li>
        ))}
      </ul>
      <Button type='primary' onClick={handleSave}>שמירה</Button>
    </div>
  );
};

export default TextsSection;
