import React, { useState } from "react";
import { Table, Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import useBlog from '../../../hooks/useBlog'
import classes from "./BlogsTable.module.scss";
import { getBlogsColumns } from '../../../utils/tableColumns'
import message from '../../../utils/message'
import { PlusOutlined } from '@ant-design/icons';

const BlogsTable = () => {
  const { blogs, loading, massDelete } = useBlog({ fetchBlogs: true })
  const [lastSortField, setLastSortField] = useState({ field: 'createdAt', order: 'descend' })
  const [selectedRowsValues, setSelectedRowsValues] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const handleChange = (pagination, filter, sorter) => {
    setLastSortField(sorter)
  }

  const handleMassDelete = () => {
    massDelete(selectedRowsValues)
    setSelectedRowsValues([])
    setSelectedRowKeys([])
    message.success('המשתמשים המסומנים נמחקו')
  }

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRowsValues) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRowsValues(newSelectedRowsValues)
    }
  };

  return (
    <div>
      <div className={classes.top}>
        <div className={classes.rightSide}>
          <Link to="/admin/blogs/add">
            <Button icon={<PlusOutlined />} type="primary">הוסף בלוג</Button>
          </Link>
        </div>
        <div className={classes.leftSide}>
          <Popconfirm
            title="האם אתה בטוח שתרצה למחוק את הבלוגים המסומנים?"
            onConfirm={handleMassDelete}
            okText="כן"
            cancelText="לא"
          >
            <Button danger type="primary" disabled={!selectedRowKeys.length}>מחק מסומנים</Button>
          </Popconfirm>
        </div>
      </div>

      <Table
        columns={getBlogsColumns({ lastSortField })}
        dataSource={blogs}
        onChange={handleChange}
        loading={loading}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default BlogsTable;
