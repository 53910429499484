import React, { useState } from "react";
import { Form, Input, Button, Card, Space, Image } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import classes from "./AddCourse.module.scss"
import myFirebase from "../../../firebase"
import message from '../../../utils/message'
import { useCourse } from "../../../contexts/CourseContext"
import { useHistory } from "react-router-dom"
import RichTextEditor from "../../common/RichTextEditor"
import FormWrapper from '../../common/Form/FormWrapper'

const AddCourse = () => {
  const [form] = Form.useForm();
  const [courseDescription, setCourseDescription] = useState('');
  const [imgCounter, setImgCounter] = useState(0);
  const [roundImgCounter, setRoundImgCounter] = useState(0);
  const [imgUrl, setImgUrl] = useState('error');
  const [roundImgUrl, setRoundImgUrl] = useState('error');
  const { addCourse } = useCourse();
  const ref = myFirebase.db.collection("courses").doc();
  const [newCourseId] = useState(ref.id);
  const history = useHistory();
  const inputRef = React.useRef(null);
  const roundInputRef = React.useRef(null);

  const onFinish = async values => {
    try {
      const { name, videoUrl, ...rest } = values;
      console.log({values})
      const course = { name, videoUrl, description: courseDescription, lessons: {}, id: newCourseId, ...rest }
      addCourse({ course });
      message.success("קורס חדש נוצר בהצלחה")
      history.push('/admin');
    } catch (error) {
      message.error("יצירת הקורס נכשלה")
    }
  };

  async function uploadImage({ file, path, set, setCounter, imgCounter }) {
    const metadata = {
      contentType: 'image/jpg'
    };
    let imageUrl = 'error';
    const storageRef = await myFirebase.storage.ref();
    const imgFile = storageRef.child(path);
    imgFile.put(file, metadata).then(async () => {
      message.success("העלאת תמונה בוצעה בהצלחה")
      setCounter(imgCounter + 1);
      const imageRef = storageRef.child(path);
      try {
        imageUrl = await imageRef.getDownloadURL();
        set(imageUrl);
      } catch { }
    })
      .catch(() => ({ title: 'העלאת תמונה נכשלה', description: '' }));
  }

  const handleImgChosen = (e) => uploadImage({ file: e.target.files[0], path: `CoursesImages/${newCourseId}`, set: setImgUrl, setCounter: setImgCounter, imgCounter });
  const handleRoundImgChosen = (e) => uploadImage({ file: e.target.files[0], path: `CoursesRoundImages/${newCourseId}`, set: setRoundImgUrl, setCounter: setRoundImgCounter, imgCounter: roundImgCounter });
  const handleOpenFileInput = () => inputRef.current.click();
  const handleOpenFileRoundInput = () => roundInputRef.current.click();

  return (
    <FormWrapper>
      <Card title="טופס הוספת קורס" className={classes.card}>
        <Form form={form} name="add_course" onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "יש להזין שם קורס" },
            ]}
          >
            <Input
              placeholder="שם הקורס"
            />
          </Form.Item>
          <Form.Item
            name="videoUrl"
            rules={[
              { required: true, message: "יש להזין קישור לוידאו הקורס" },
            ]}
          >
            <Input
              placeholder="קישור לוידאו הקורס"
            />
          </Form.Item>
          <div className={classes.tiny}>
            <RichTextEditor initialValue="תיאור השיעור" onChange={setCourseDescription} />
          </div>

          <Space direction='vertical' size={12}>
            {imgUrl !== 'error' && <Image
              src={imgUrl}
              width={200}
              key={imgCounter}
            />}
            <Button type="primary" icon={<UploadOutlined />} size='large' onClick={handleOpenFileInput}>
              העלה תמונת קורס לעמוד הראשי
              <input multiple={false} ref={inputRef} style={{ display: 'none' }} type="file" onChange={handleImgChosen} accept="image/png, image/jpeg, image/jpg" />
            </Button>
            רזולוצית התמונה - 450 × 170

            {roundImgUrl !== 'error' && <Image
              src={roundImgUrl}
              width={200}
              key={roundImgCounter}
            />}
            <Button type="primary" icon={<UploadOutlined />} size='large' onClick={handleOpenFileRoundInput}>
              העלה תמונת קורס לרשימת הקורסים
              <input multiple={false} ref={roundInputRef} style={{ display: 'none' }} type="file" onChange={handleRoundImgChosen} accept="image/png, image/jpeg, image/jpg" />
            </Button>
            רזולוצית התמונה - 196 × 196
          </Space>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                block
                className={classes.submitButton}
                type="primary"
                htmlType="submit"
                size='large'
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                הוסף קורס
              </Button>
            )}
          </Form.Item>
        </Form>
      </Card>
    </FormWrapper>
  );
};

export default AddCourse;
