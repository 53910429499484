import React from "react";
import SectionHeader from './SectionHeader'
import MiddleLayout from "../../common/MiddleLayout"
import SectionScrollDetected from './SectionScrollDetected'
import classes from "./Section2.module.scss";

const Section6 = ({ onVisisbleChange }) => (
  <div className={classes.section} style={{ backgroundColor: '#f6efef' }}>
    <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
    <MiddleLayout className={classes.layout}>
      <SectionHeader
        number='6'
        color='header-grey'
        title='לימוד בכל זמן ובכל מקום'
        text={`
          מכלול פיתחה לעצמה ייחודיות בשיטת הלימוד החדשנית שלה.
          כסטודנט תוכל ללמוד את המקצוע שבחרת מכל מקום בעולם דרך הפלאפון או המחשב.
          מערך הלימוד התאורטי שלנו בנוי באופן מקוון וברובו מורכב מסרטוני הדרכה המועברים ע"י סגל המורים והמרצים אשר שמו דגש על מקצועיות והנגשת חומר הלימוד ברמה הגבוה ביותר.
          `}
        textWidth='60%'
      />
    </MiddleLayout>
  </div>
);

export default Section6;
