import React from "react";
import { Input } from "antd";
import classes from './TableFilter.module.scss'

const { Search } = Input;

const TableFilter = ({ onSearch, field }) => {
	const handleChange = (text) => {
		onSearch({ field, text })
	}

	return (
		<div className={classes.filter}>
			<Search placeholder="חפש" onSearch={handleChange} style={{ width: 200 }} />
		</div>
	)
}

export default TableFilter;
