import React from "react";
import { NavLink } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
import classes from "./Navigation.module.scss";

const Navigation = () => {
  const { path } = useRouteMatch()

  return (
    <div className={classes.navigation}>
      <ul className={classes.list}>
        <li className={classes.item}>
          <NavLink to={`${path}/courses`} className={classes.link} activeClassName={classes.selected}>קורסים</NavLink>
        </li>
        <li className={classes.item}>
          <NavLink to={`${path}/users`} className={classes.link} activeClassName={classes.selected}>משתמשים</NavLink>
        </li>
        <li className={classes.item}>
          <NavLink to={`${path}/blogs`} className={classes.link} activeClassName={classes.selected}>בלוג</NavLink>
        </li>
        <li className={classes.item}>
          <NavLink to={`${path}/videos`} className={classes.link} activeClassName={classes.selected}>סרטונים</NavLink>
        </li>
        <li className={classes.item}>
          <NavLink to={`${path}/texts`} className={classes.link} activeClassName={classes.selected}>טקסטים</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Navigation;
