import React from "react";
import { Typography } from "antd"
import MiddleLayout from './MiddleLayout'
import ContactUs from '../portal/ContactUs/ContactUs'
import classes from './InfoPage.module.scss'
import classNames from "classnames"

const { Text } = Typography

const InfoPage = ({ image, title, icon, content }) => (
  <div>
    <div className={classes.image} style={{ backgroundImage: `url(${image})` }} />
    <MiddleLayout centerContent>
      <div className={classes.content}>
        <div className={classes.titleWrapper}>
          <Text className={classNames(classes.title)}>{title}</Text>
          {icon && <div className={classes.icon}>{icon}</div>}
        </div>
        <div>
          {content}
        </div>
      </div>
    </MiddleLayout>
    <MiddleLayout><ContactUs /></MiddleLayout>
  </div >
);

export default InfoPage;
