import { message as antdMessage } from "antd"

const message = {}

message.success = (content, duration) => {
  antdMessage.success({
    content,
    duration,
    style: {
      marginTop: '80px',
    },
  });
}

message.error = (content, duration) => {
  antdMessage.error({
    content,
    duration,
    style: {
      marginTop: '80px',
    },
  });
}

export default message
