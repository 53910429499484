import React, { useEffect } from "react";
import message from '../../../utils/message'
import { Tabs, Typography } from "antd";
import { useAuth } from "../../../contexts/AuthContext";
import MiddleLayout from "../../common/MiddleLayout";
import FormWrapper from '../../common/Form/FormWrapper'
import UserForm from "../../common/UserForm";
import UserChangeAuth from "./UserChangeAuth";
import classes from "./UserProfile.module.scss";
const { TabPane } = Tabs;

const UserProfile = () => {
  const { currentUser, updateAuthsUser, updateDetailsUser, error, loading, success } = useAuth()

  const handleAuthUpdate = values => updateAuthsUser(values)

  const handleDetailsUpdate = values => updateDetailsUser(values)

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (error) message.error(error)
  }, [error])

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (success) message.success("הפרטים עודכנו")
  }, [success])

  return (
    <MiddleLayout>
      <FormWrapper size='small'>
        <Typography.Text className={classes.header}>החשבון שלי</Typography.Text>
        <Tabs defaultActiveKey="1">
          <TabPane tab="פרטים" key="1">
            <UserForm
              onSave={handleDetailsUpdate}
              initialValues={currentUser}
              loading={loading}
              isUpdate
            />
          </TabPane>
          <TabPane tab="התחברות" key="2">
            <UserChangeAuth
              onSave={handleAuthUpdate}
              initialValues={currentUser}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </FormWrapper>
    </MiddleLayout>
  );
};

export default UserProfile;
