import React from "react";
import ContactUsForm from './ContactUsForm'
import { Typography } from "antd"
import classes from './ContactUs.module.scss'
import Slide from 'react-reveal/Slide'
import classNames from "classnames"
const { Text } = Typography

const ContactUs = ({ inRow }) => (
  <div className={classNames(classes.contactUs, { [classes.inRow]: inRow })}>
    <div className={classes.rightSide}>
      <div>
        <Text className={classes.title}>מתעניינים בלימודים?</Text>
        <Text className={classes.text}>
          נציג שלנו ייצור איתך קשר למידע נוסף ולקביעת פגישה עם יועץ ללימודים אקדמיים, שיסייע לך לבנות תכנית לימודים המותאמת למסלול החיים שלך
        </Text>
      </div>
      <ContactUsForm color='orange' />
    </div>
    <div className={classes.imageWrapper}>
      <Slide left>
        <img
          src='/images/contantUs.jpeg'
          className={classes.image}
        />
      </Slide>
    </div>
  </div>
)

export default ContactUs;
