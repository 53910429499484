import { useEffect } from "react";

const useOnKeyDown = (handler) => {

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    const handleKeydown = (event) => {
      handler(event);
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);
}

export default useOnKeyDown
