import React, { useState } from "react";
import MiddleLayout from "../../common/MiddleLayout"
import SectionHeader from './SectionHeader'
import { Typography } from 'antd';
import VisibilitySensor from 'react-visibility-sensor'
import CountUp from 'react-countup';
import SectionScrollDetected from './SectionScrollDetected'
import useMediaQuery from '../../../hooks/useMediaQuery'
import classes from "./Section1.module.scss";

const NumbersBox = ({ number, suffix, text }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  return (
    <div className={classes.box}>
      <Typography.Title className={classes.title}>
        <CountUp start={viewPortEntered ? null : 0} end={number} suffix={suffix}>
          {({ countUpRef }) => {
            return (
              <VisibilitySensor
                active={!viewPortEntered}
                onChange={isVisible => {
                  if (isVisible) {
                    setViewPortEntered(true);
                  }
                }}
                delayedCall
              >
                <span ref={countUpRef} />
              </VisibilitySensor>
            );
          }}
        </CountUp>
      </Typography.Title>
      <div className={classes.divider} />
      <Typography.Text className={classes.text}>{text}</Typography.Text>
    </div>
  )
}

const Section1 = ({ onVisisbleChange }) => {
  const { isMobile } = useMediaQuery()
  const src = isMobile ?
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection1-mobile.jpg?alt=media&token=d0f85369-77f7-4e28-8d14-e6f5492f4eb7' :
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection1.jpg?alt=media&token=fc47a7ad-e1f4-4b9a-8e15-3b85472b4070'

  return (
    <div className={classes.numbers} style={{ background: `url(${src})` }}>
      <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
      <MiddleLayout className={classes.layout}>
        <div className={classes.content}>
          <SectionHeader number='1' title='קצת מספרים' color='dark-brand' />
          <div className={classes.boxes}>
            <NumbersBox number={10} text='10 קורסים מקצועיים מוצעים לבחירה אשר ישלבו אותך כאיש מקצוע  במשק הישראלי, הקורסים המועברים הם הקורסים המובילים ביותר, עם אחוז העבודה הגבוה ביותר.' />
            <NumbersBox number={1} text='מכלול נמצא במקום הראשון כבית הספר החדשני ביותר בשיטת הלימוד שלו, המאפשרת למידה תאורטית מהבית בצורה מקוונת + מעבדות עבודה מעשית מהמתקדמות בישראל.' />
            <NumbersBox number={5876} text='5876 בוגרים סיימו בהצלחה את לימודיהם במכלול והשתלבו במעגל העבודה.' />
            <NumbersBox number={20} suffix='%' text='1 מתוך 5 סטודנטים בישראל בוחרים ללמוד במכלול.' />
          </div>
        </div>
      </MiddleLayout>
    </div>
  )
}

export default Section1
