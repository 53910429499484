import React, { useState } from "react";
import { Table, Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import { useCourse } from "../../../contexts/CourseContext"
import { getCoursesColumns } from '../../../utils/tableColumns'
import message from '../../../utils/message'
import classes from "./CoursesTable.module.scss";
import { PlusOutlined } from '@ant-design/icons';

const CoursesTable = () => {
	const { courses, loading, massDelete } = useCourse();
	const [lastSortField, setLastSortField] = useState({ field: 'createdAt', order: 'descend' })
	const [selectedRowsValues, setSelectedRowsValues] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([])

	const handleChange = (pagination, filter, sorter) => {
		setLastSortField(sorter)
	}

	const handleMassDelete = () => {
		massDelete(selectedRowsValues)
		setSelectedRowsValues([])
		setSelectedRowKeys([])
		message.success('הקורסים המסומנים נמחקו')
	}

	const rowSelection = {
		type: 'checkbox',
		selectedRowKeys,
		onChange: (newSelectedRowKeys, newSelectedRowsValues) => {
			setSelectedRowKeys(newSelectedRowKeys)
			setSelectedRowsValues(newSelectedRowsValues)
		}
	}

	return (
		<div>
			<div className={classes.top}>
				<div className={classes.rightSide}>
					<Link to="/admin/courses/add">
						<Button icon={<PlusOutlined />} type="primary">הוסף קורס</Button>
					</Link>
				</div>
				<div className={classes.leftSide}>
					<Popconfirm
						title="האם אתה בטוח שתרצה למחוק את הקורסים המסומנים?"
						onConfirm={handleMassDelete}
						okText="כן"
						cancelText="לא"
					>
						<Button danger type="primary" disabled={!selectedRowKeys.length}>מחק מסומנים</Button>
					</Popconfirm>
				</div>
			</div>

			<Table
				columns={getCoursesColumns({ lastSortField })}
				dataSource={courses}
				onChange={handleChange}
				loading={loading}
				rowSelection={rowSelection}
			/>
		</div>
	);
};

export default CoursesTable;
