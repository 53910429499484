const error_messages = code => ERRORS[code] || "שגיאה!";

const ERRORS = {
  "auth/email-already-in-use": "המייל תפוס, אנא החלף מייל",
  "auth/email-already-exists": "המייל תפוס, אנא החלף מייל",
  "auth/wrong-password": 'שגיאה, נסה שנית',
  "auth/user-not-found": 'שם משתמש לא קיים'
};

export default error_messages;
