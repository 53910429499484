import React, { useEffect, useState } from "react";
import { Card, Skeleton } from "antd"
import MiddleLayout from "../../common/MiddleLayout";
import { useParams } from "react-router-dom";
import { useCourse } from "../../../contexts/CourseContext"
import classes from "../Lesson/Lesson.module.scss"
import ContactUs from '../ContactUs/ContactUs'
import SocialButtons from '../SocialButtons/SocialButtons'
import classnames from 'classnames';

const Course = () => {
  const { courses } = useCourse();
  const { courseId } = useParams();
  const [course, setCourse] = useState();

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    setCourse(courses.find(course => course.id === courseId))
  }, [courses, courseId]);

  return (
    <MiddleLayout>
      {!course ? <Skeleton active /> :
        <div className={classes.course}>
          <Card title={course.name} className={classnames(classes.content, classes.card)}>
            <div dangerouslySetInnerHTML={{ __html: course.description }} />
          </Card>
          <ContactUs />
          <SocialButtons />
        </div>
      }
    </MiddleLayout>
  );
};

export default Course;
