import React, { useState, useRef } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Typography } from "antd";
import classes from "./Menu.module.scss";
import { NavLink } from "react-router-dom";
import useOnClickOutside from 'use-onclickoutside'
import classNames from "classnames";
import useOnKeyDown from '../../../hooks/useOnKeyDown'
import useMediaQuery from '../../../hooks/useMediaQuery'
const { Text } = Typography;

const Menu = () => {
  const { currentUser } = useAuth();
  const { isMobile } = useMediaQuery();
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const menuRef = useRef()

  const handleClickOutSide = () => setIsMenuOpen(false)

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 27) handleClickOutSide()
  }

  useOnClickOutside(menuRef, handleClickOutSide)
  useOnKeyDown(handleKeyDown)

  const handleHamburgerClick = () => setIsMenuOpen(!isMenuOpen)

  return (
    <>
      <div className={classes.menu} onClick={handleHamburgerClick} ref={menuRef}>
        <div className={classes.menuContent}>
          {isMenuOpen ?
            <div className={classes.xIcon}>
              {!isMobile && <div className={classes.contentConnect} />}
              <div className={classes.content}>
                <ul className={classes.list}>
                  <NavLink to="/" activeClassName={classes.selected} exact>
                    <li className={classNames(classes.item, classes.item1)}><Text className={classes.text}>ראשי</Text></li>
                  </NavLink>
                  <NavLink to="/courses" activeClassName={classes.selected} exact>
                    <li className={classNames(classes.item, classes.item2)}><Text className={classes.text}>הקורסים שלנו</Text></li>
                  </NavLink>
                  <NavLink to="/how_to_study" activeClassName={classes.selected} exact>
                    <li className={classNames(classes.item, classes.item3)}><Text className={classes.text}>איך לומדים</Text></li>
                  </NavLink>
                  <NavLink to="/interested" activeClassName={classes.selected} exact>
                    <li className={classNames(classes.item, classes.item4)}><Text className={classes.text}>לפני שנרשמים</Text></li>
                  </NavLink>
                  <NavLink to="/about" activeClassName={classes.selected} exact>
                    <li className={classNames(classes.item, classes.item5)}><Text className={classes.text}>אודות</Text></li>
                  </NavLink>
                  {/* <NavLink to="/about?tab=2" exact>
                    <li className={classNames(classes.item, classes.item6)}><Text className={classes.text}>בוגרי מכלול</Text></li>
                  </NavLink> */}
                  {isMobile && currentUser &&
                    <NavLink to="/my_courses" activeClassName={classes.selected} exact>
                      <li className={classNames(classes.item, classes.item7)}><Text className={classes.text}>הקורסים שלי</Text></li>
                    </NavLink>
                  }
                  {isMobile && !currentUser &&
                    <>
                      <NavLink to="/login" activeClassName={classes.selected} exact>
                        <li className={classNames(classes.item, classes.item6)}><Text className={classes.text}>התחבר</Text></li>
                      </NavLink>
                      <NavLink to="/signup" activeClassName={classes.selected} exact>
                        <li className={classNames(classes.item, classes.item7)}><Text className={classes.text}>הצטרפות</Text></li>
                      </NavLink>
                    </>
                  }
                  {currentUser?.isAdmin &&
                    <NavLink to="/admin" activeClassName={classes.selected} exact>
                      <li className={classNames(classes.item, classes.item8)}><Text className={classes.text}>מנהל</Text></li>
                    </NavLink>
                  }
                </ul>
              </div>
            </div>
            :
            <>
              <div className={classes.hamburgerIcon} />
              <div className={classes.hamburgerIcon} />
              <div className={classes.hamburgerIcon} />
            </>
          }
        </div>
      </div>
      {isMenuOpen && <div className={classes.overlay} />}
    </>
  );
};

export default Menu;
