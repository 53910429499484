import { useState } from "react";
import errorMessages from "../utils/errorMessages";
import firebase from "firebase/app";

const useEmailService = () => {
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState()
  const [error, setError] = useState()

  const sendEmail = async (values) => {
    setLoading(true)
    setSuccess(false)
    setError(false)
    try {
      const sendEmailFunc = firebase.functions().httpsCallable('sendEmail')
      await sendEmailFunc(values)
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setLoading(false)
      setSuccess(false)
      setError(errorMessages(error.code));
    }
  }

  return {
    success,
    loading,
    error,
    sendEmail,
  }
};

export default useEmailService;
