import React from "react"
import MiddleLayout from '../../common/MiddleLayout'
import { Typography, Tabs, Skeleton } from "antd";
import { Img } from 'react-image'
import ContactUs from '../ContactUs/ContactUs'
import SocialButtons from '../SocialButtons/SocialButtons'
import classes from "./HowToStudy.module.scss"
import useMediaQuery from '../../../hooks/useMediaQuery'

const { Text } = Typography
const { TabPane } = Tabs;

const HowToStudy = () => {
  const { isMobile } = useMediaQuery()
  const renderSkeleton = () => <div><Skeleton.Button active className={classes.skeleton} /></div>

  return (
    <MiddleLayout>
      <div className={classes.imageWrapper}>
        <Img
          src={isMobile ? 'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/headersImages%2Fhow_to_study_cover_mobile.png?alt=media&token=7ac9b856-b433-4bbe-9aba-b64025231d94' : 'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/headersImages%2Fhow_to_study_cover.png?alt=media&token=77c3e2c7-f068-4064-83d1-024d5b888b2f'}
          className={classes.image}
          loader={renderSkeleton()}
        />
        <Text className={classes.imageTitle}>איך לומדים במכלול?</Text>
      </div>
      <Tabs defaultActiveKey="1" className={classes.tab} size='large'>
        <TabPane tab="הקורסים של מכלול" key="1">
          <div className={classes.tabContent}>
            {`הקורסים של מכלול רכשו מוניטין רב-שנים כקורסים בעלי רמה גבוהה, שמפותחים על ידי צוותי מומחים ועוברים בקרת איכות קפדנית בשלב הפיתוח. במשך השנים, פותחו הקורסים ע"פ הדרישות במשק הישראלי.
            
הקורסים מותאמים ללמידה עצמית והם כוללים מגוון גדול של חומרי למידה מקוונים משולבים עם שיעורי מעשיים במעבדות עבודה מהמתקדמות בישראל, המעבדות מאובזרות בכל המכשירים, אביזרים וכלים הנדרשים המאפשרות ללמד ליישם את הלימוד ברמה הגבוהה ביותר.             

לכל קורס יש מערכת גישה לקורס הרלוונטי המאפשרת ללמד להתחבר באמצעות שם משתמש וסיסמה לקורס שרכש וללמוד בקלות בעזרת סרטוני הדרכה מפורטים והסברים רלוונטיים לשיעור הנלמד. בקורס המקוון קיימים מבחנים עם שאלות אמריקאיות, השאלות מתייחסות לכל שיעור בהתאם לשיעור הנלמד.             

מומלץ ביותר, במיוחד לסטודנטים חדשים, לפנות למערך הייעוץ במכלול לפני הרשמה לקורסים.             `}
          </div>
        </TabPane>
        <TabPane tab="למידה מותאמת אישית" key="2">
          <div className={classes.tabContent}>
            {`מכלול מאפשרת לכל אחד ואחת מכם גמישות מרבית בארגון הלימודים: אפשר להחליט אם ללמוד קורס אחד או מספר קורסים, להחליט אם למקד את הלימודים בתחומים מסוימים, לבחור את קבוצת הלימוד המתאימה לסגנון הלמידה שלכם ולתנאיכם האישיים, ללמוד מכל מקום ובכל זמן את חומרי הלימוד האיכותיים והידידותיים, ולהיעזר במפגשי הנחיה במגוון ימים ושעות במרכז הלימוד או במפגשי הנחיה מקוונים.
            
              הלימוד מתבצע באופן מקוון + שיעורים מעשיים בקבוצות קטנות ומצומצמות המאפשרות הבנה אמיתית של החומר הנלמד תוך תרגול מעשי ממשי במעבדות התרגול.
              
              לסטודנט קיימת האפשרות לבחור את שעות הלימוד, ניתן ללמוד במסלול בוקר, מסלול ערב, או לשלב מסלול בוקר וערב על מנת לא לפגוע בשגרת החיים של הלומד.`}
          </div>
        </TabPane>
        <TabPane tab="שיטת הלימוד" key="3">
          <div className={classes.tabContent}>
            {`הלימוד המעשי במכלול מתקיים בקבוצות קטנות ומצומצמות המאפשרות לסטודנט לתרגל יחד עם המורה תוך מתן תשומת לב לכל שאלה ועזרה. 
             
               כיתות הלימוד מאפשרות ללמד לתרגל באופן מעשי תוך הדמיה אמיתית של העבודה הרלוונטית בשטח, בכיתות יתרגל הסטודנט באופן צמוד עם המורה את החומר התאורטי שלמד בשיעורים המקוונים דרך הטלפון / מחשב.
               
               אנו מאמינים, כי קבוצות למידה קטנות של 3-8 תלמידים, מאפשרות לסטודנט לשאול שאלות מבלי להתבייש, לתרגל באופן מעשי נרחב את כל נושאי הלימוד אותם למד בשיעורים המקוונים במערכת הלימוד הממוחשבת שלנו, ולתרגם את מה שלמד בכיתה הלכה למעשה בשטח כנותן שירותים מקצועי שצבר את הניסיון המעשי שלו בכיתת הלימוד ולא בשטח.
               
אנו מאמינים כי לימוד מעשי בשיטה שלנו, מכין את הסטודנט לעבודה בשטח ונותן לו את הכלים להצליח כנותן שירותים מקצועי ברמה הגבוה ביותר.               

               כסטודנט עומדים לרשותך כל החומרים, הסרטונים והמצגות אותם למדת במערכת הלימוד שלנו, תוכל בכל זמן שתבחר להיכנס לשיעורים בעזרת שם המשתמש וסיסמה שניתן לך כשנרשמת לקורס ולחזור ולהיזכר שוב בחומר הלימוד.
               
אנו מעדכנים מדי שנה את החומרים, מוסיפים את התקנים החדשים, מוסיפים שיטות עבודה חדשות וכו'. תוכל תמיד להתעדכן בחומרים החדשים באתר הלימוד ללא עלות.               `}
          </div>
        </TabPane>
        <TabPane tab="צוות ההדרכה" key="4">
          <div className={classes.tabContent}>
            {`צוות ההוראה מורכב ממרכז ההוראה האחראי על הוראת הקורס בכל רחבי המכללה וממנחים של קבוצות הלימוד. צוות ההוראה של הקורס נמצא בקשר רציף עם הסטודנטים באמצעות אתרי הקורסים, בדואר אלקטרוני ובטלפון בעת הצורך, ואחראי על בדיקת המטלות והדרכת הסטודנטים בהכנתן, כמו גם על הכנת הבחינות ובדיקתן.
            
              במפגשי ההנחיה הפרונטליים והמקוונים כאחת, המתנהלים בקבוצות קטנות, מסייע המנחה בהתמקדות בנושאים מרכזיים בקורס, מתרגל, מקיים דיון בסוגיות נבחרות ומדריך בכתיבת המטלות ובהכנה לבחינה הסופית.`}
          </div>
        </TabPane>
      </Tabs>
      <ContactUs />
      <SocialButtons />
    </MiddleLayout>
  )
}

export default HowToStudy
