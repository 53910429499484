import React from "react";
import Slider from './Slider'
import useImage from "../../../hooks/useImage"
import useMediaQuery from '../../../hooks/useMediaQuery'
import { Skeleton, Image } from 'antd';
import classes from './ImageSlider.module.scss'


const emptyItems = [...Array(3).keys()];

const ImageSlider = ({ id, title, color }) => {
  const { images, loading } = useImage({ fetchImages: true, id });
  const { isMobile, isTablet } = useMediaQuery()

  const getChunkNumber = () => {
    let chunkNumber = 3
    if (isTablet) chunkNumber = 2
    if (isMobile) chunkNumber = 1
    if (images?.length < chunkNumber) chunkNumber = images?.length
    return chunkNumber
  }

  const renderLoading = () => (
    <Skeleton.Button active className={classes.skeleton} />
  )

  const chunkNumber = getChunkNumber()

  return (
    <Slider
      title={title}
      chunkNumber={chunkNumber}
      color={color}
      oneSlider={chunkNumber === images.length}
    >
      {!loading && images.map((link) => (
        <Slider.Item key={link} className={classes.item} isVideo>
          <Image src={link} className={classes.image} />
        </Slider.Item>
      ))}
      {loading && emptyItems.map((key) => (
        <Slider.Item key={key}>
          {renderLoading()}
        </Slider.Item>
      ))}
    </Slider>
  );
};

export default ImageSlider;
