import React from "react";
import classNames from "classnames"
import classes from "./MiddleLayout.module.scss";

const MiddleLayout = ({ children, className, stretch = true, centerContent, width }) => {
  return (
    <div
      className={classNames(
        classes.layout,
        className,
        {
          [classes.stretch]: stretch,
          [classes.centerContent]: centerContent
        }
      )}
      style={{ width }}
    >
      {children}
    </div>
  );
};

export default MiddleLayout;
