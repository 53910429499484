import { useState, useEffect } from "react";
import myFirebase from "../firebase";
import errorMessages from "../utils/errorMessages";

const { db } = myFirebase

const useText = (params = {}) => {
  const { fetchTexts, fetchTextsSection, id } = params

  const [loading, setLoading] = useState(fetchTexts || fetchTextsSection);
  const [error, setError] = useState();
  const [texts, setTexts] = useState([]);
  const [textsSection, setTextsSection] = useState({});


  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (fetchTexts) getTexts()
    if (fetchTextsSection) getTextsSection()
  }, [])

  const getTextsSection = async () => {
    setLoading(true)
    try {
      const textsSnapshot = await db.collection("texts").doc(id).get();
      setTextsSection(textsSnapshot.data())
      setLoading(false)
    } catch (error) {
      setError(errorMessages(error.code))
    }
  }

  const getTexts = async () => {
    setLoading(true)
    try {
      const textsSnapshot = await db.collection("texts").get();
      const textsList = []
      textsSnapshot.forEach(videoRef => {
        const data = videoRef.data()
        textsList.push(data)
      });
      setTexts(textsList)
      setLoading(false)
    } catch (error) {
      setError(errorMessages(error.code))
    }
  }

  const updateText = async (text) => {
    try {
      await db.collection("texts").doc(text.id).update(text);
    } catch {
      console.log('Error while updating texts')
    }
  }

  const data = {
    texts,
    textsSection,
    updateText,
    loading,
    error
  }

  return data
};

export default useText;
