import React from "react"
import classnames from 'classnames';
import classes from "./CircleImage.module.scss"

const CircleImage = ({ src, onClick, notAllowed, alt }) => (
  <div
    className={classnames(classes.image, { [classes.notAllowed]: notAllowed })}
    style={{ backgroundImage: `url(${src})` }}
    onClick={onClick}
    alt={alt}
  />
)

export default CircleImage
