import React, { useRef } from "react";
import { Carousel as AntCarousel, Typography, Button } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import chunk from 'lodash.chunk'
import classes from './Slider.module.scss'
import colorClasses from '../../../style/colorClasses.module.scss'
import classNames from "classnames"

const { Text } = Typography;

const Slider = ({ title, chunkNumber, color, oneSlider, children }) => {
  const carouselRef = useRef()

  const handleRightClick = () => {
    carouselRef.current.prev()
  }

  const handleLeftClick = () => {
    carouselRef.current.next()
  }

  const arrays = chunk(React.Children.toArray(children), chunkNumber)

  return (
    <div className={classes.slider}>
      <div className={classes.header}>
        <Text className={classNames(classes.text, colorClasses[`color-${color}`])}>{title}</Text>
        <div className={classes.buttons}>
          <Button
            className={classNames(classes.button, classes.rightArrow,
              colorClasses[`color-${color}`],
              colorClasses[`border-color-${color}`]
            )}
            onClick={handleRightClick}>
            <ArrowRightOutlined className={classes.icon} />
          </Button>
          <Button
            className={classNames(classes.button, classes.leftArrow,
              colorClasses[`background-color-${color}`],
              colorClasses[`border-color-${color}`]
            )}
            onClick={handleLeftClick}>
            <ArrowLeftOutlined className={classes.icon} />
          </Button>
        </div>
      </div>
      <AntCarousel dots={false} className={classes.carousel} ref={carouselRef}>
        {arrays.map((child, index) => (
          <div key={index}>
            <div className={classNames(classes.itemsWrapper, { [classes.oneSlider]: oneSlider })}>
              {child}
            </div>
          </div>
        ))}
      </AntCarousel>
    </div>
  );
};

const Item = ({ children, onClick, isVideo, className }) => (
  <div className={classNames(classes.item, className, { [classes.isVideo]: isVideo })} onClick={onClick}>
    {children}
  </div>
)

Slider.Item = Item
export default Slider;
