import React, { useState } from "react";
import { Table, Button, Popconfirm } from "antd";
import { Link } from "react-router-dom";
import classes from "./LessonsTable.module.scss";
import { getLessonsColumns } from '../../../utils/tableColumns'
import message from '../../../utils/message'
import { PlusOutlined } from '@ant-design/icons';

const LessonsTable = ({ lessons, courseId, deleteLesson }) => {
  const [lastSortField, setLastSortField] = useState({ field: 'number', order: 'ascend' })
  const [selectedRowsValues, setSelectedRowsValues] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const handleChange = (pagination, filter, sorter) => {
    setLastSortField(sorter)
  }

  const handleMassDelete = () => {
    deleteLesson(selectedRowsValues[0]?.id);
    setSelectedRowsValues([])
    setSelectedRowKeys([])
    message.success('השיעורים המסומנים נמחקו')
  }

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRowsValues) => {
      setSelectedRowKeys(newSelectedRowKeys)
      setSelectedRowsValues(newSelectedRowsValues)
    }
  };

  return (
    <div className={classes.lessonsTable}>
      <div className={classes.top}>
        <div className={classes.rightSide}>
          <Link to={`/admin/courses/${courseId}/lessons/add`}>
            <Button icon={<PlusOutlined />} className={classes.question} type="primary">הוסף שיעור</Button>
          </Link>
        </div>
        <div className={classes.leftSide}>
          <Popconfirm
            title="האם אתה בטוח שתרצה למחוק את השיעורים המסומנים?"
            onConfirm={handleMassDelete}
            okText="כן"
            cancelText="לא"
          >
            <Button danger type="primary" disabled={!selectedRowKeys.length}>מחק שיעור מסומן</Button>
          </Popconfirm>
        </div>
      </div>

      {lessons && <Table
        columns={getLessonsColumns({ courseId, lastSortField })}
        dataSource={Object.values(lessons || {})}
        onChange={handleChange}
        rowSelection={rowSelection}
        pagination={{
          pageSize: '200',
        }}
      />}
    </div>
  );
};

export default LessonsTable;
