import React from "react";
import VideosSection from './VideosSection'
import useVideo from '../../../hooks/useVideo'
import { Tabs, Skeleton, Card } from 'antd';

const { TabPane } = Tabs;

const Videos = () => {
  const { videos, loading, updateVideo } = useVideo({ fetchVideos: true })

  const renderOption = (video) => (
    <TabPane tab={video.name} key={video.id}>
      <VideosSection video={video} onSave={updateVideo} />
    </TabPane>
  )

  if (loading) return <Skeleton active />

  return (
    <Card>
      <Tabs defaultActiveKey="1" tabPosition='right'>
        {videos.map(renderOption)}
      </Tabs>
    </Card>
  );
};

export default Videos;
