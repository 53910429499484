import React, { useState, useRef, useEffect } from "react"
import classes from "./Popover.module.scss";
import useOnClickOutside from 'use-onclickoutside'
import useOnKeyDown from '../../hooks/useOnKeyDown'
import classNames from 'classnames'
import Flip from 'react-reveal/Flip';

const Popover = ({ children, content, forceClose, className, contentClassName,
  showOverlay, ignoreClickOutside, onVisibleChange, disabled, animation, width }) => {
  const [showPopover, setShowPopover] = useState(false)
  const popoverRef = useRef()

  const handleShowPopoverChange = (newVisible) => {
    if (!disabled) {
      if (onVisibleChange) onVisibleChange(newVisible)
      setShowPopover(newVisible)
    }
  }

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (forceClose) {
      if (onVisibleChange) onVisibleChange(false)
      setShowPopover(false)
    }
  }, [forceClose])

  const handleClickOutSide = () => {
    if (!ignoreClickOutside) handleShowPopoverChange(false)
  }

  const handleKeyDown = ({ keyCode }) => {
    if (keyCode === 27) handleShowPopoverChange(false)
  }

  useOnClickOutside(popoverRef, handleClickOutSide)
  useOnKeyDown(handleKeyDown)

  const handleClick = () => {
    handleShowPopoverChange(!showPopover)
  }

  const renderContent = () => (
    <div className={classNames(classes.content, contentClassName, { [classes.show]: showPopover })} style={{ width }}>
      {content()}
    </div>
  )

  const renderFlipAnimation = () => (
    <Flip left opposite when={showPopover}>
      {renderContent()}
    </Flip>
  )

  const renderContentWrapper = () => {
    if (animation === 'flip') return renderFlipAnimation()
    if (!showPopover) return null
    return renderContent()
  }

  return (
    <div className={classNames(classes.popover, className)} ref={popoverRef}>
      <span onClick={handleClick}>{children}</span>
      {renderContentWrapper()}
      {showPopover && showOverlay && <div className={classes.overlay} onClick={handleClickOutSide} />}
    </div>
  );
};

export default Popover;
