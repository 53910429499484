import { useState, useEffect } from "react";
import myFirebase from "../firebase";
import errorMessages from "../utils/errorMessages";

const { db } = myFirebase

const useVideo = (params = {}) => {
  const { fetchVideos, fetchVideosSection, id } = params

  const [loading, setLoading] = useState(fetchVideos || fetchVideosSection);
  const [error, setError] = useState();
  const [videos, setVideos] = useState([]);
  const [videosSection, setVideosSection] = useState({});


  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (fetchVideos) getVideos()
    if (fetchVideosSection) getVideosSection()
  }, [])

  const getVideosSection = async () => {
    setLoading(true)
    try {
      const videosSnapshot = await db.collection("videos").doc(id).get();
      setVideosSection(videosSnapshot.data())
      setLoading(false)
    } catch (error) {
      setError(errorMessages(error.code))
    }
  }

  const getVideos = async () => {
    setLoading(true)
    try {
      const videosSnapshot = await db.collection("videos").get();
      const videosList = []
      videosSnapshot.forEach(videoRef => {
        const data = videoRef.data()
        videosList.push(data)
      });
      setVideos(videosList)
      setLoading(false)
    } catch (error) {
      setError(errorMessages(error.code))
    }
  }

  const updateVideo = async (video) => {
    try {
      await db.collection("videos").doc(video.id).update(video);
    } catch {
      console.log('Error while updating course')
    }
  }

  const data = {
    videos,
    videosSection,
    updateVideo,
    loading,
    error
  }

  return data
};

export default useVideo;
