import React, { useRef, useState } from "react";
import SectionsMenu from './SectionsMenu'
import TopImage from './TopImage'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'
import Section7 from './Section7'
import InterestedContactUs from "./InterestedContactUs"

const Interested = () => {
  const [visisbleSection, setVisisbleSection] = useState({})
  const section0Ref = useRef()
  const section1Ref = useRef()
  const section2Ref = useRef()
  const section3Ref = useRef()
  const section4Ref = useRef()
  const section5Ref = useRef()
  const section6Ref = useRef()
  const section7Ref = useRef()
  const section8Ref = useRef()

  const handleVisisbleChange = (sectionNumber) => (visisble) => {
    setVisisbleSection((preVisisbleSection) => ({ ...preVisisbleSection, [sectionNumber]: visisble }))
  }

  return (
    <div>
      <SectionsMenu
        visisbleSection={visisbleSection}
        section0Ref={section0Ref}
        section1Ref={section1Ref}
        section2Ref={section2Ref}
        section3Ref={section3Ref}
        section4Ref={section4Ref}
        section5Ref={section5Ref}
        section6Ref={section6Ref}
        section7Ref={section7Ref}
        section8Ref={section8Ref}
      />
      <section ref={section0Ref}>
        <TopImage onVisisbleChange={handleVisisbleChange(0)} section1Ref={section1Ref} />
      </section>
      <section ref={section1Ref}>
        <Section1 onVisisbleChange={handleVisisbleChange(1)} />
      </section>
      <section ref={section2Ref}>
        <Section2 onVisisbleChange={handleVisisbleChange(2)} />
      </section>
      <section ref={section3Ref}>
        <Section3 onVisisbleChange={handleVisisbleChange(3)} />
      </section>
      <section ref={section4Ref}>
        <Section4 onVisisbleChange={handleVisisbleChange(4)} />
      </section>
      <section ref={section5Ref}>
        <Section5 onVisisbleChange={handleVisisbleChange(5)} />
      </section>
      <section ref={section6Ref}>
        <Section6 onVisisbleChange={handleVisisbleChange(6)} />
      </section>
      <section ref={section7Ref}>
        <Section7 onVisisbleChange={handleVisisbleChange(7)} />
      </section>
      <section ref={section8Ref}>
        <InterestedContactUs onVisisbleChange={handleVisisbleChange(8)} />
      </section>
    </div>
  );
}

export default Interested;
