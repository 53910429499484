import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import AdminLayout from './AdminLayout';
import AddLesson from "./Lesson/AddLesson";
import User from "./User/User";
import UsersTable from "./User/UsersTable";
import CoursesTable from "./Course/CoursesTable";
import BlogsTable from "./Blog/BlogsTable";
import AddBlog from "./Blog/AddBlog";
import EditBlog from "./Blog/EditBlog";
import AddCourse from "./Course/AddCourse";
import Navigation from "./Navigation";
import EditLesson from './Lesson/EditLesson';
import EditCourse from './Course/EditCourse';
import AdminCourse from './Course/AdminCourse'
import Videos from './Video/Videos';
import PageTexts from './PageTexts/PageTexts';
import classes from "./Admin.module.scss"

const Admin = () => {
  const { path } = useRouteMatch()

  return (
    <div className={classes.admin}>
      <Navigation />
      <AdminLayout>
        <Switch>
          <Route exact path={`${path}/courses/:courseId/lessons/:lessonId/edit`}>
            <EditLesson />
          </Route>

          <Route exact path={`${path}/courses/:courseId/edit`}>
            <EditCourse />
          </Route>

          <Route exact path={`${path}/courses/add`}>
            <AddCourse />
          </Route>

          <Route exact path={`${path}/courses/:courseId`}>
            <AdminCourse />
          </Route>

          <Route exact path={`${path}/courses`}>
            <CoursesTable />
          </Route>

          <Route exact path={`${path}/courses/:courseId/lessons/add`}>
            <AddLesson />
          </Route>

          <Route exact path={`${path}/users/:userId`}>
            <User />
          </Route>

          <Route exact path={`${path}/users`}>
            <UsersTable />
          </Route>

          <Route exact path={`${path}/blog/:blogId`}>
            <EditBlog />
          </Route>

          <Route exact path={`${path}/blogs/add`}>
            <AddBlog />
          </Route>

          <Route exact path={`${path}/blogs`}>
            <BlogsTable />
          </Route>

          <Route exact path={`${path}/videos`}>
            <Videos />
          </Route>

          <Route exact path={`${path}/texts`}>
            <PageTexts />
          </Route>

          <Route>
            <Redirect to={`${path}/courses`} />
          </Route>
        </Switch>
      </AdminLayout>
    </div>
  );
}

export default Admin;
