import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Space, Image } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import classes from "../Course/AddCourse.module.scss"
import myFirebase from "../../../firebase"
import useNotification from "../../../hooks/useNotificaiton";
import useBlog from "../../../hooks/useBlog"
import { useHistory } from "react-router-dom"
import RichTextEditor from "../../common/RichTextEditor"
import FormWrapper from '../../common/Form/FormWrapper'

const AddBlog = () => {
  const [form] = Form.useForm();
  const [blogDescription, setBlogDescription] = useState('');
  const { addBlog, success, error, loading } = useBlog();
  const [newBlogId] = useState(myFirebase.db.collection("blogs").doc().id)
  const { raiseNotification } = useNotification();
  const history = useHistory();
  const inputRef = React.useRef(null);
  const [imgUrl, setImgUrl] = useState('error');
  const [imgCounter, setImgCounter] = useState(0);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (success) {
      raiseNotification({ title: "בלוג חדש נוצר בהצלחה", description: "" });
      history.push('/admin/blogs');
    }
  }, [success])

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (error) {
      raiseNotification({ title: "יצירת הבלוג נכשלה", description: error })
      history.push('/admin/blogs');
    }
  }, [error])


  const onFinish = async ({ name, ...rest }) => {
    const blog = { name, description: blogDescription, id: newBlogId, ...rest }
    addBlog({ blog });
  };

  async function uploadImage(file) {
    const metadata = {
      contentType: 'image/jpg'
    };
    let imageUrl = 'error';
    const storageRef = await myFirebase.storage.ref();
    const imgFile = storageRef.child(`BlogsImages/${newBlogId}`);
    imgFile.put(file, metadata).then(async () => {
      raiseNotification({ title: 'העלאת תמונה בוצעה בהצלחה', description: '' })
      setImgCounter(imgCounter + 1);
      const imageRef = storageRef.child(`BlogsImages/${newBlogId}`);
      try {
        imageUrl = await imageRef.getDownloadURL();
        setImgUrl(imageUrl);
      } catch { }

    })
      .catch(() => ({ title: 'העלאת תמונה נכשלה', description: '' }));
  }

  const handleOnFileChosen = (e) => uploadImage(e.target.files[0]);

  const handleOpenFileInput = () => {
    inputRef.current.click();
  };

  return (
    <FormWrapper>
      <Card title="טופס הוספת בלוג" className={classes.card}>
        <Form form={form} name="add_blog" onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "יש להזין שם הבלוג" },
            ]}
          >
            <Input
              placeholder="שם הבלוג"
            />
          </Form.Item>

          <div className={classes.tiny}>
            <RichTextEditor initialValue="תיאור הבלוג" onChange={setBlogDescription} />
          </div>

          <Space size={12}>
            <Image
              src={imgUrl}
              width={200}
              key={imgCounter}
            />
              רזולוצית התמונה - 450 × 170
              <Button type="primary" icon={<UploadOutlined />} size='large' onClick={handleOpenFileInput}>
              העלה תמונת קורס
                <input multiple={false} ref={inputRef} style={{ display: 'none' }} type="file" onChange={handleOnFileChosen} accept="image/png, image/jpeg, image/jpg" />
            </Button>
          </Space>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                block
                className={classes.submitButton}
                type="primary"
                htmlType="submit"
                size='large'
                loading={loading}
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                הוסף בלוג
              </Button>
            )}
          </Form.Item>
        </Form>
      </Card>
    </FormWrapper>
  );
};

export default AddBlog;
