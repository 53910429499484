import React from "react";
import SectionHeader from './SectionHeader'
import MiddleLayout from "../../common/MiddleLayout"
import SectionScrollDetected from './SectionScrollDetected'
import classes from "./Section2.module.scss";

const Section2 = ({ onVisisbleChange }) => {
  return (
    <div className={classes.section} style={{ backgroundColor: '#f3f6ff' }}>
      <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
      <MiddleLayout className={classes.layout}>
        <SectionHeader
          number='2'
          color='header-grey'
          title='גמישות מרבית. הבחירה שלך!'
          text='במכלול הבחירה כולה שלך – מה ללמוד, איך ללמוד, מתי ובאיזה קצב ללמוד. תכניות הלימודים מאפשרות גמישות מרבית, על מנת שתוכל להמשיך במסלול החיים שלך.'
        />
      </MiddleLayout>
    </div>
  );
};

export default Section2;
