import React, { useState } from "react";
import { Typography } from "antd"
import { QuestionCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import ContactUsForm from './ContactUsForm'
import Popover from '../../common/Popover'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { useLocation } from "react-router-dom";
import classes from "./FloatContactUs.module.scss";
import classNames from "classnames"
const { Text } = Typography

const FloatContactUs = () => {
  const { isMobile } = useMediaQuery()
  const [forceClose, setForceClose] = useState()
  const [popoverOpen, setPopoverOpen] = useState()
  const [success, setSuccess] = useState()
  const { pathname } = useLocation()

  const handleSuccess = () => {
    setForceClose(true)
    setSuccess(true)
    setTimeout(() => {
      setForceClose(false)
    }, 0);
  }

  const handleVisibleChange = (visible) => {
    setPopoverOpen(visible)
  }

  const renderContent = () => (
    <div>
      <div>
        <Text className={classNames(classes.text, classes.brand)}>מתעניינים בלימודים? </Text>
        <Text className={classes.text}>השאירו פרטים ונחזור אליכם!</Text>
      </div>
      <ContactUsForm color='brand' onSuccess={handleSuccess} />
    </div>
  )

  const getIcon = () => {
    if (success) return <CheckCircleOutlined />
    if (popoverOpen) return <CloseCircleOutlined />
    return <QuestionCircleOutlined />
  }

  if (pathname.includes('admin')) return null

  return (
    <div className={classes.floatContactUs}>
      <Popover
        content={renderContent}
        contentClassName={classes.popoverContent}
        showOverlay={isMobile}
        forceClose={forceClose}
        onVisibleChange={handleVisibleChange}
        disabled={success}
        animation='flip'
        ignoreClickOutside
      >
        <div className={classNames(classes.button,
          { [classes.popoverOpen]: popoverOpen, [classes.success]: success })}>
          {getIcon()}
        </div>
      </Popover>
    </div>
  );
};

export default FloatContactUs;
