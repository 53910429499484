import React from "react";
import InfoPage from '../../common/InfoPage'
import { WarningOutlined } from '@ant-design/icons';
import classes from './CancellationPolicies.module.scss'

const renderContent = () => (
  <div className={classes.content}>
    {`1. לקוח שיבטל את הרשמתו לקורס, בין אם השתתף בשיעורים או בחלקם ובין אם לאו, מכל סיבה שהיא, לא יהא זכאי לכל החזר כספי בגין מחיר הקורס, בין אם שולם ובין אם לאו, למעט החזרים כמפורט להלן:

2. בגין עסקת מכר מרחוק: 

2.1. בגין ביטול הרשמה לקורס, אשר התבצעה במסגרת עסקה שבוצעה בטלפון, הנך רשאי לבטל את הרכישה, כל עוד לא בוצע שימוש בשירות ו/או בתוכן הקורס ו/או קבלת שם משתמש וסיסמה שעבורו שילמת, הנך רשאי לבטל את הרכישה בתוך 7 ימים מיום ההזמנה במידה ולא פתחת את תוכן השיעורים ו/או השתמש באחת משירותי הקורס, במידה ונחשפת לתוכן הקורס או חלק קטן ממנו הריי שאינך יכול להחזיר כלל את החומר הנלמד מאחר ואין באפשרותנו למחוק את החומר שלמדת מהזיכרון שלך, ועל כן החומר הנלמד נשאר אצלך ה"למד" ואין באפשרותך להחזיר אותו לבעליו "מכלול – המרכז ללימודים טכניים" ובכך אינך יכול לבקש את כספך בחזרה למעט אם לא הזנת קוד הפעלה של הקורס ולא נרשמת ו/או לא אישרנו את פרטי הגישה לקורס באתר עבורך. כמו כן, חייבת להיות סיבה מוצדקת לביטול הרכישה.

2.2. בגין ביטול הרשמה לאחר המועד הקבוע בסעיף 1.2 לעיל, יחול מדרג הביטול כמפורט בסעיף 4.3 להלן.

2.3. ביטולה של עסקת טלפונית יכול שייעשה באמצעות מסירת הודעת ביטול בכתב או בעל – פה למכלול – המרכז ללימודים טכניים או לכל גורם מיצג אחר בשמה , בציון שם הלקוח ומספר ת.ז. יובהר, כי אי שימוש בשיעורים ו/או כל התנהגות אחרת אינה נחשבת ולא תיחשב כהודעת ביטול עסקה, והלקוח מוותר מראש על כל טענה הנוגעת לבקשת ביטול, שלא נעשתה בהתאם להוראות סעיף זה.

הודעת ביטול תישלח לאימייל michlol.office@gmail.com 

4. ביטול רכישה:

4.1. ביטול עסקה לאחר 7 יום מיום ביצועה לא יהא זכאי להחזר כספי.

4.2. בשימוש וכניסה לאתר הנך מצהיר כי קראת בעיון רב את תנאי השימוש באתר, הנך מצהיר כי ההסכם בין מפעיל האתר " מכלול – המרכז ללימודים טכניים " לרוכש הזכות לשימוש באתר ברורה ומובנת לך. כתלמיד המבקש ללמוד לימוד עצמי וכי אין לזכותו אפשרות שיתוף התכנים באתר משתמשים אחרים וזאת במידה ורכש את הקורס ו/או גולש ומעיין בתכנים החינמיים של הקורס.

4.3. הנך רשאי לבטל את הרכישה, במידה ולא בוצע שימוש בשירות ו/או המוצר ו/או לא קיבלת מידע ו/או שיעורים ו/או לא פתחו לך הרשאות גישה לשיעורים הדיגיטליים באתר. הנך רשאי לבטל את הרכישה בתוך 7 ימים מיום ההזמנה באם ולא נחשפת לתוכן הקורס ו/או הדברים שהוצגו בפנייך לעיל.

4.4. לא תוכל לבטל את הקורס באם עמדת באחד מתנאי השימוש אשר הוצגו בפנייך לעיל ו/או בהתאם לסעיף 2.1 לעיל.

4.5. על מנת לבטל את הרכישה ובאם לא בוצע שימוש כל שהוא באתר כמו שצוין לפנייך לעיל עלייך ליצור קשר טלפוני עם מנהל האתר בטלפון המוצג באתר. לאחר ביטול הרכישה תזוכה בחלק מהמחיר ששילמת עבורו ובניכוי הוצאות האתר כדין. כל עלות ו/או נזק שנוצר עקב הרשמתך לקורס ינוכה מהתשלום כדין.

4.6. במקרה של ביטול עסקה כאמור, רשאי האתר לחייב אותך בדמי ביטול בשיעור 50% ממחיר המוצר ו/או השירות, מחיר הקורס הינו 10,900 ₪ כפי שמוצג באתר. דמי הביטול יהיו 5,450 ₪.

במידה והלקוח רכש את הקורס בעת מבצע במחיר מוזל יותר מ10,900 ₪ ינוכה 5,450 ₪ מהמחיר ששולם.

5. סמכות שיפוט

5.1. כללי תקנון מכלול – המרכז ללימודים טכניים

5.2. ראשית, תקנון זה מבטא את התנאים אשר חלים עליך במידה ותחליט לרכוש ולשלם עבור השירותים באתרwww.michlol-tsc.co.il או בתשלום דרך הטלפון ו/או בתשלום בצ'קים / אשראי / מזומן או כל צורת תשלום אחרת ל" מכלול – המרכז ללימודים טכניים " (להלן ה"אתר") הוראותיו יחולו על כל שימוש שיעשה על ידך באתר, ויהוו את הבסיס המשפטי לכל דיון בינך לבין בעלי האתר הוא בעל הזכויות. על כן, הנך מתבקש לקרוא תקנון זה במלואו ובעיון רב.

5.3. גלישה ושימוש באתר ו/או באילו מהשירותים המוצעים במסגרתו, מהווה את הסכמתך המלאה לקבל ולנהוג לפי התקנון, לכן אם אינך מסכים לתנאי מתנאיו הנך מתבקש לא לעשות כל שימוש באתר ולעזוב את האתר.

5.4. האתר פותח ומופעל ע"י מכלול – המרכז ללימודים טכניים. הקורס שרכשת נועד גם להיות אמצעי תשלום מקוון אשר יסייע לקונים לבצע תשלום כספי בצורה נוחה ומאובטחת.

5.5. הנהלת האתר שומרת לעצמה את הזכות לשנות ולעדכן את התקנון ללא כל הודעה מוקדמת.

6. מדיניות השירות:

6.1. תשלום מאובטח ברשת האינטרנט

6.2. האתר מציע מספר אפשרויות תשלום וכן מכיל טופס אמצעי תשלום מאובטח ברשת האינטרנט למשלמים אשר באמצעותו יוכל הגולש לשלם עבור המוצר ו/או השירות שאותו הוא מעוניין לרכוש.

6.3. האתר מאפשר את התשלום למוצר ו/או שירות המוצע למכירה באתר, כל פרטי משך המנוי, אפשרויות התשלום, שירות על המוצרים ומדיניות הביטולים מובאים לידיעת הגולש ומחובתו לקרוא אותן בקפידה לפני ביצוע התשלום, להפנות שאילת שאלות לשירות הלקוחות של האתר.

6.4. מאחר והאתר עוסק בין השאר במתן אפשרויות לביצוע תשלומים תהיה פעילות הסליקה תחת אחריותו של האתר בלבד ולא לתהליך המכירה והשירות.

6.5. במידה ולא התקבל המוצר ו/או השירות אשר בגינו בוצע התשלום, האתר יבצע החזר מלא של התשלום שהתקבל למעט המקרים בו נעשה שימוש לא נאות באפשרות רכישת הקורס, בכל מקרה שכזה נטל ההוכחה בגין אי קבלת המוצר ו/או השירות מוטל על הרוכש.

7. שימוש ורישום לאתר:

7.1. כל גולש רשאי לבצע תשלום לאחר מילוי פרטים אישיים וביצוע רישום באתר, בכפוף למילוי התנאים המצטברים המפורטים להלן:

7.1.1. המשתמש הינו כשיר לבצע פעולות משפטיות בחייבות. במידה והנך קטין ו/או איך זכאי לבצע פעולות משפטיות ללא אישור אפוטרופוס, יראו את שימושך באתר כאילו קיבלת את אישור האפוטרופוס.

7.1.2. המשתמש הינו בעל תעודת זהות ישראלית תקפה או תאגיד המאוגד ורשום כדין בישראל.

7.1.3. המשתמש הינו בעל כרטיס אשראי בתוקף אשר הונפק לו בישראל ע"י אחת מחברות כרטיסי האשראי המורשות.

7.1.4. המשתמש בעל דוא"ל ברשת האינטרנט ובעל כתובת בישראל.

7.2. היה והמשתמש הוא תאגיד, הנרשם בשם התאגיד מצהיר ומאשר כי הוא רשאי לפעול בשם התאגיד.

8. התחייבות בעת הרישום לאתר:

8.1. במילוי הפרטים באתר הנך מאשר לאתר לשמור מידע מסוים אודותיך ו/או אודות כרטיס/י האשראי או כל אמצעי תשלום אחר שברשותך.

8.2. הנך מתחייב למסור מידע אמיתי ומלא אודותיך כפי שמתבקש בטופס התשלום באתר. הנך מתחייב לעדכן את המידע כפי שנמסר בטופס הרישום במטרה לשמור על נכונותו ושלמותו.

8.3. הנך מאשר כי שמך, כתובת הדוא"ל, כתובת המגורים, ומספר הטלפון שלך ייחשפו לצורכי השלמת העסקה שבצעת בלבד.

8.4. האתר לא יהיה אחראי בגין נזק ו/או אובדן שיגרם לך כתוצאה משימוש שלא בהתאם להסכם תנאי השימוש שלהלן, וזאת בכפוף לכל חוק.

9. השימוש בשירות:

האתר מבקש להביא לתשומת ליבך ולהדגיש כי תשלום דרך האינטרנט ובכלל זה באמצעות האתר, הינו תשלום לכל דבר ועניין וחלים עליו כל דיני המכר הרלוונטיים הנהוגים במדינת ישראל ובכל מדינה רלוונטית לקונה ו/או למוכר בעסקה כלשהי.

10. הגבלת אחריות:

10.1. האתר מציע לך אפוא לנקוט בכל אמצעי זהירות ולבצע כל בדיקה, בחינה או הערכה שיידרשו לדעתך על מנת לאתר ולוודא את זהותו האמיתית של המשתמש בכל דרך על מנת לצמצם ככל האפשר נזקים ועוגמת נפש העלולים להיגרם לך מההתקשרות העסקית באמצעות האתר עם גורמים בלתי רצויים.

10.2. אתה פוטר בזה את האתר ו/או מי מעובדיו ו/או מי ממנהליו ו/או מי מבעלי מניותיו מכל טענה ו/או תביעה ו/או דרישה מכל סוג ומין שהן שעניינן נזק או הוצאה מקורם במישרין או בעקיפין בחילוקי דעות שיתגלעו בינך ובין בעל דברך בעסקה כלשהי, והכל בין שהיה ידוע לאתר דבר המחלוקת ובין אם לאו.

11. שמירה על סודיות המידע אודות המשתמש:

11.1. האתר לא יעביר את הפרטים המפורטים לעיל לצדדי ג' כלשהם, אלא, לצורך ביצוע התשלום.

11.2. מעבר לשימוש הנ"ל האתר מתחייב שלא לעשות כל שימוש במידע ללא הרשאתך, אלא אם כן הדבר נדרש עפ"י דין או כדי למנוע שימוש לרעה. האתר יאפשר גישה למידע רק לאלה מעובדיו הזקוקים למידע לצורך מתן השירות.

11.3. האתר נוקט באמצעי זהירות מקובלים על מנת לשמור ככל האפשר על סודיות המידע, על העברה של מספר כרטיסי אשראי מהאתר נעשית לפי תקן אך בקיום מקרים שאינם בשליטתה ו/או הנובעים מכוח עליון, האתר לא יהיה אחראי לכל נזק מכל סוג שהוא, בין אם בעקיפין או במישרין שייגרם לך ו/או למי מטעמך, אם מידע זה יאבד או אם יעשה בו שימוש לא מורשה כלשהו.

12. ביצוע תשלום באמצעות מערכת האתר , הינו בבחינת עסקה במסמך חסר, כמשמעו בחוק.

13. על הסכם זה יחולו אך ורק דיני מדינת ישראל, אולם לא תהיה תחולה לכללי ברירת הדין הבינלאומי הקבועים בהם.

14. ביהמ"ש המוסמך בהתאם להוראות חוק הבוררות, תשכ"ח 1968 יהיה ביהמ"ש שמושבו בעיר עכו.
   `}
  </div>
)

const CancellationPolicies = () => (
  <InfoPage
    title='מדיניות ביטולים'
    icon={<WarningOutlined />}
    content={renderContent()}
    image='https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/cancellationPolicies.jpg?alt=media&token=8ba6cff5-a10f-45b4-95df-4c3350fe1764'
  />
);

export default CancellationPolicies;
