import React from "react";
import SectionHeader from './SectionHeader'
import MiddleLayout from "../../common/MiddleLayout"
import SectionScrollDetected from './SectionScrollDetected'
import classes from "./Section2.module.scss";

const Section4 = ({ onVisisbleChange }) => {
  return (
    <div className={classes.section} style={{ backgroundColor: '#f1f1f1' }}>
      <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
      <MiddleLayout className={classes.layout}>
        <SectionHeader
          number='4'
          color='header-grey'
          title='אינסוף אפשרויות ללימודים'
          text='מכלול מציע מגוון רחב קורסים במסגרת לימודי תעודה. אפשר לבחור מה ללמוד מתוך מגוון אינסופי של תחומי לימוד, ואפשר גם לשלב בין תחומי לימוד שונים ולהרכיב את תכנית הלימודים שלך.'
        />
      </MiddleLayout>
    </div>
  );
};

export default Section4;
