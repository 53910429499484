import React, { useEffect } from "react"
import MiddleLayout from "../common/MiddleLayout";
import FormWrapper from '../common/Form/FormWrapper'
import SignIn from './SignIn'
import { Typography } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import classes from './SignUp.module.scss'

const SignInPage = () => {
  const { currentUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (currentUser) history.push("/");
  }, [history, currentUser]);

  return (
    <MiddleLayout>
      <FormWrapper size='small'>
        <Typography.Text className={classes.header}>התחברות</Typography.Text>
        <SignIn />
      </FormWrapper>
    </MiddleLayout>
  )
}

export default SignInPage
