import React, { useState } from "react";
import { Button, Modal } from "antd";
import UserForm from '../../common/UserForm'
import classes from "./AddUser.module.scss";
import { PlusOutlined } from '@ant-design/icons';

const AddUser = ({ onAdd, loading }) => {
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [userAddedNum, setUserAddedNum] = useState(0)


	const openModal = () => setIsModalVisible(true)
	const closeModal = () => setIsModalVisible(false)

	const handleSave = async (values) => {
		const isSucceed = await onAdd(values)
		if (isSucceed) {
			closeModal()
			setUserAddedNum(userAddedNum + 1)
		}
	}

	return (
		<div className={classes.addUser}>
			<Button icon={<PlusOutlined />} type="primary" onClick={openModal}>הוסף משתמש</Button>
			<Modal
				title='הוספת משתמש'
				visible={isModalVisible}
				onCancel={closeModal}
				footer={null}
			>
				<UserForm onSave={handleSave} key={userAddedNum} loading={loading} />
			</Modal>
		</div>
	);
};

export default AddUser;
