import React, { useState } from "react";
import { Typography } from "antd";
import classes from "./InlineFieldWrapper.module.scss";
import classnames from "classnames";
const { Text } = Typography;

const InlineFieldWrapper = ({ children, withIcon, placeholder, showLabelAlways, isInline, color, ...props }) => {
  const [isFocus, setIsFocus] = useState();
  const { value } = props;
  const renderLabel = () => {
    return (
      <Text
        strong
        className={classnames(classes.label, {
          [classes.show]: showLabelAlways || isFocus || value,
          [classes.isFocus]: isFocus,
          [classes.isInline]: isInline,
          [classes.withIcon]: withIcon,
          [classes[color]]: color
        })}
      >
        {placeholder}
      </Text>
    );
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleBlur = () => {
    setIsFocus(false);
  };

  return (
    <div onFocus={handleFocus} onBlur={handleBlur}>
      {renderLabel()}
      {React.cloneElement(children, {
        ...props,
        placeholder: !isFocus && placeholder,
        color
      })}
    </div>
  );
};

export default InlineFieldWrapper;
