import React from "react";
import Grid from "../../common/Grid";
import { useCourse } from "../../../contexts/CourseContext";
import { useHistory } from "react-router-dom";
import { Typography, Tooltip } from "antd";
import MiddleLayout from "../../common/MiddleLayout";
import CircleImage from '../../common/CircleImage'
import classes from './Courses.module.scss'
import { useAuth } from "../../../contexts/AuthContext";
import classnames from 'classnames';

const MyCourses = () => {
  const { courses, loading } = useCourse();
  const history = useHistory();
  const redirectToCourseLessons = courseId => {
    history.push(`/courses/${courseId}/lessons`)
  };
  const { currentUser } = useAuth();

  const renderCourse = course => {
    const isCourseEnabled = currentUser.courses[course.id] && currentUser?.isActive;
    return (
      <Tooltip className={classes.toolTip} title={!isCourseEnabled && 'עליך לרכוש את הקורס על מנת לצפות בשיעורים'}>
        <Grid.Item
          key={course.id}
        >
          <div className={classnames(classes.imgWrapper, { [classes.imgHover]: isCourseEnabled })}>
            <CircleImage
              src={course.roundImageUrl}
              notAllowed={!isCourseEnabled}
              onClick={isCourseEnabled && (() => redirectToCourseLessons(course.id))}
            />
          </div>
          <Typography.Text
            disabled={!isCourseEnabled}
            level={2}
            onClick={isCourseEnabled && (() => redirectToCourseLessons(course.id))}
            className={classes.title}>{course.name}</Typography.Text>
        </Grid.Item>
      </Tooltip>
    );
  }

  return (
    <MiddleLayout>
      <div className={classes.gridWrapper}>
        <Typography.Text className={classes.header}>הקורסים שלי</Typography.Text>
        <Grid loading={loading}>{courses.map(renderCourse)}</Grid>
      </div>
    </MiddleLayout>
  )
};

export default MyCourses;
