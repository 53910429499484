import React from "react";
import Slider from './Slider'
import { Skeleton } from 'antd';
import useVideo from "../../../hooks/useVideo"
import useMediaQuery from '../../../hooks/useMediaQuery'
import classes from './VideoSlider.module.scss'

const emptyItems = [...Array(3).keys()];

const VideoSlider = ({ id, title, color }) => {
  const { videosSection, loading } = useVideo({ fetchVideosSection: true, id });
  const { isMobile, isTablet } = useMediaQuery()

  const getChunkNumber = () => {
    let chunkNumber = 3
    if (isTablet) chunkNumber = 2
    if (isMobile) chunkNumber = 1
    if (videosSection?.links?.length < chunkNumber) chunkNumber = videosSection?.links?.length
    return chunkNumber
  }

  const renderVideo = (link) => (
    <iframe
      src={link}
      className={classes.video}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    >
    </iframe>
  )

  const renderLoading = () => (
    <Skeleton.Button active className={classes.skeleton} />
  )

  const chunkNumber = getChunkNumber()

  return (
    <Slider
      title={title}
      chunkNumber={chunkNumber}
      color={color}
      oneSlider={chunkNumber === videosSection?.links?.length}
    >
      {!loading && videosSection?.links?.map((link) => (
        <Slider.Item key={link} isVideo>
          {renderVideo(link)}
        </Slider.Item>
      ))}
      {loading && emptyItems.map((key) => (
        <Slider.Item key={key}>
          {renderLoading()}
        </Slider.Item>
      ))}
    </Slider>
  );
};

export default VideoSlider;
