import React, { useState } from "react"
import { Button } from "antd";
import {
  YoutubeOutlined, YoutubeFilled, FacebookOutlined, FacebookFilled,
  InstagramOutlined, InstagramFilled
} from '@ant-design/icons';
import classNames from "classnames"
import classes from "./SocialButtons.module.scss"

const SocialButtons = () => {
  const [youtubeIsHover, setYoutubeIsHover] = useState(false);
  const [facebookIsHover, setFacebookIsHover] = useState(false);
  const [instagramIsHover, setInstagramIsHover] = useState(false);

  return (
    <div className={classes.socialButtons}>
      <Button
        className={classNames(classes.button, classes.youtube)}
        onMouseEnter={() => setYoutubeIsHover(true)}
        onMouseLeave={() => setYoutubeIsHover(false)}
        onClick={() => window.open('https://www.youtube.com/channel/UCh69PJOPJzRngNphwaWdb6Q', '_blank', 'noopener,noreferrer')}
      >
        {youtubeIsHover ? <YoutubeFilled className={classes.icon} /> : <YoutubeOutlined className={classes.icon} />}
      </Button>
      <Button
        className={classNames(classes.button, classes.facebook)}
        onMouseEnter={() => setFacebookIsHover(true)}
        onMouseLeave={() => setFacebookIsHover(false)}
        onClick={() => window.open('https://www.facebook.com/michlol.tsc/', '_blank', 'noopener,noreferrer')}
      >
        {facebookIsHover ? <FacebookFilled className={classes.icon} /> : <FacebookOutlined className={classes.icon} />}
      </Button>
      <Button
        className={classNames(classes.button, classes.instagram)}
        onMouseEnter={() => setInstagramIsHover(true)}
        onMouseLeave={() => setInstagramIsHover(false)}
        onClick={() => window.open('https://www.instagram.com/internationallockingacademy/', '_blank', 'noopener,noreferrer')}
      >
        {instagramIsHover ? <InstagramFilled className={classes.icon} /> : <InstagramOutlined className={classes.icon} />}
      </Button>
    </div>
  )
}

export default SocialButtons
