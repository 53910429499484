import React, { useEffect, useState } from "react";
import { Card, Skeleton, Button } from "antd"
import { useParams, useHistory } from "react-router-dom";
import { useCourse } from "../../../contexts/CourseContext"
import classes from "../../portal/Lesson/Lesson.module.scss"
import useLesson from "../../../hooks/useLesson";
import LessonsTable from '../Lesson/LessonsTable'
import { EditOutlined } from '@ant-design/icons';

const AdminCourse = () => {
  const { courses, updateCourse } = useCourse();
  const { courseId } = useParams();
  const [course, setCourse] = useState();
  const [lessonsWithNumber, setLessonsWithNumber] = useState();
  const { getLessons } = useLesson();
  const history = useHistory();
  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    const currentCourse = courses.find(course => course.id === courseId);
    setCourse(currentCourse);
    getLessons(Object.values(currentCourse?.lessons || {})).then((lessonsHash) => {
      if (currentCourse && currentCourse.lessons && lessonsHash) {
        const newLessons = {}
        for (const lessonId of Object.keys(lessonsHash)) {
          newLessons[lessonId] = {
            ...lessonsHash[lessonId],
            key: lessonId,
            number: Object.keys(currentCourse.lessons).find(key => currentCourse.lessons[key] === lessonId)
          }
        }
        setLessonsWithNumber(newLessons)
      }
    })
  }, [courses, courseId]);

  const handleEdit = () => {
    history.push(`/admin/courses/${courseId}/edit`);
  }

  const handleDeleteLesson = (id) => {
    const newLessons = {}
    for (const lessonId of Object.keys(lessonsWithNumber)) {
      if (lessonId !== id) {
        newLessons[lessonId] = lessonsWithNumber[lessonId]
      }
    }
    const numbersAndIds = {}
    for (const id of Object.keys(newLessons)) {
      numbersAndIds[newLessons[id].number] = id;
    }
    let flag = false;
    const keysArr = Object.keys(numbersAndIds).sort(function(a, b){return Number(a) - Number(b)})
    keysArr.forEach((number, index) => {
      if ((Number(number) !== 1 && (Number(number) - 1 !== Number(keysArr[index - 1]))) || flag) {
        const currentId = numbersAndIds[number]
        newLessons[currentId] = { ...newLessons[currentId], number: Number(number) - 1 }
        delete newLessons[number]
        flag = true;
      }
    })
    const newNumbersAndIds = {}
    for (const id of Object.keys(newLessons)) {
      newNumbersAndIds[newLessons[id].number] = id;
    }
    setLessonsWithNumber({ newLessons })
    updateCourse({ ...course, lessons: { ...newNumbersAndIds } })
  }

  return (
    <>
      {!course ? <Skeleton active /> :
        <div>
          <Button icon={<EditOutlined />} className={classes.question} onClick={handleEdit} type="primary">ערוך קורס</Button>
          <Card title={course.name} className={classes.card}>
            <div dangerouslySetInnerHTML={{ __html: course.description }} />
          </Card>
          <LessonsTable deleteLesson={handleDeleteLesson} lessons={lessonsWithNumber} courseId={courseId} />
        </div>
      }
    </>
  );
};

export default AdminCourse;
