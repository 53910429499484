import React from "react"
import { Skeleton } from "antd"
import MiddleLayout from '../../common/MiddleLayout'
import InfoPage from '../../common/InfoPage'
import { useParams } from "react-router-dom"
import useBlog from '../../../hooks/useBlog'
import classes from "./Blog.module.scss"

const Blog = () => {
  const { blogId } = useParams();
  const { blog, loading } = useBlog({ fetchBlog: true, blogId });

  if (loading) return <MiddleLayout><Skeleton active /></MiddleLayout>

  return (
    <InfoPage
      title={blog.name}
      content={(
        <div className={classes.description}>
          <div className={classes.imageWrapper}>
            <img src={blog.imageUrl} className={classes.image} />
          </div>
          <div dangerouslySetInnerHTML={{ __html: blog.description }} />
        </div>
      )}
      image='https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/blog.jpg?alt=media&token=9c0aaccd-2b5a-4c4f-8ffa-39e3390b44bc'
    />
  );
};

export default Blog;
