import React from "react"
import MiddleLayout from "../../common/MiddleLayout"
import ContactUs from '../ContactUs/ContactUs'
import SocialButtons from '../SocialButtons/SocialButtons'
import SectionScrollDetected from './SectionScrollDetected'
import Row from '../../common/Row'

const InterestedContactUs = ({ onVisisbleChange }) => (
  <MiddleLayout>
    <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
    <Row color='orange'>
      <ContactUs inRow />
    </Row>
    <SocialButtons />
  </MiddleLayout>
)

export default InterestedContactUs
