import React from "react";
import InfoPage from '../../common/InfoPage'
import { Typography } from "antd"
import { SafetyCertificateFilled } from '@ant-design/icons';
import { Link } from "react-router-dom";
import classes from './Terms.module.scss'

const { Text } = Typography

const renderContent = () => (
  <div>
    <Text className={classes.text}>{
      `
      ברוכים הבאים לאתר www.michlol-tsc.co.il  (להלן ה"אתר"), אותו מפעילה מכלול – המרכז ללימודים טכניים בע"מ (להלן ה"חברה")

החברה מאשרת גישה אל האתר בכפוף לתנאים המפורטים להלן (להלן – "תנאי השימוש"). המשתמש מתבקש ואף נדרש לקרוא תקנון זה בעיון בטרם גלישה באתר ו/או הזמנת מוצר באמצעות האתר שכן המשך שהותו באתר, כולל גלישה ועיון במידע המפורסם באתר ו/או הזמנה של מוצר כלשהו באמצעות האתר מהווים את הסכמתו להוראות תקנון זה. המשתמש אינו חייב להסכים לאמור בתקנון זה אולם אם המשתמש אינו מסכים לאמור כאן, עליו לצאת מהאתר ולא לעשות בו כל שימוש.

החברה שומרת לעצמה את הזכות לעדכן את תנאי השימוש האלה, מעת לעת, לפי שיקול דעתה הבלעדי וללא צורך במסירת הודעה כלשהי מראש. תוקפו של שינוי תנאי השימוש יחל מרגע פרסומו באתר זה. השימוש בלשון זכר בלבד הינו לצורך הנוחות בלבד ותנאים אלו יחולו על כל משתמשי האתר.

השימוש באתר מכלול – המרכז ללימודים טכניים בע"מ המפעילה את האתר מקדמת בברכה את גולשיה והמשתמשים בשירותיה. השימוש באתר כפוף לתנאים המפורטים להלן, ומהווה הסכמה מפורשת מצד המשתמש לתנאים אלה. תנאי השימוש וכל הטקסטים באתר מנוסחים לעיתים בלשון זכר מטעמי נוחות בלבד, אולם הם מיועדים לגברים ולנשים כאחד.

אחריות כללית בגין פעילות האתר 1. החברה לא תישא בכל אחריות לפעילות בלתי חוקית שתעשה על ידי הגולשים באתר או בגין כל גורם אחר שאינו בשליטתה המלאה. החברה עושה ותעשה כל מאמץ לשמור על תקינות פעילותו של האתר, אך לא מתחייבת. 2 שהשירות בו לא יופרע עקב תקלות בחומרה, תוכנה או בקווי התקשורת, והיא לא תהיה אחראית לתקלות או שיבושים ברשת האינטרנט או בקווי התקשורת.

בעלות וזכויות יוצרים כל זכויות היוצרים באתר לרבות בעיצובו, ובכל תוכנה, יישום, קובץ גרפי, טקסטים, קוד מחשב, וכל חומר אחר הכלול בו – הינן של החברה בלבד ו/או ספקי התוכן שלה, ו/או שותפים עסקיים לפי העניין. אין להעתיק, להפיץ, להציג בפומבי, לתרגם או למסור לצד שלישי כל חלק מהנ"ל בלא קבלת הסכמתה המפורשת של החברה בכתב ומראש. שם החברה, שם המתחם של האתר וסימני המסחר (אלה שנרשמו ואלה שלא נרשמו) של החברה, הינם קניינה הבלעדי של החברה.

אבטחת מידע ופרטיות החברה מקפידה לנהל את האתר בזהירות, מיומנות, ובאופן מבוקר ונוקטת באמצעי זהירות מקובלים על מנת לשמור, ככל האפשר, על סודיות המידע שנמסר על-ידי הגולשים באתר במקרים שאינם בשליטת החברה ו/או בשל אירועים הנובעים מכוח עליון, החברה לא תהיה אחראית לכל נזק מכל סוג שהוא, עקיף או ישיר שייגרם לגולש או למי מטעמו, אם מידע זה יאבד או יגיע לגורם עוין ו/או יעשה בו שימוש שלא בהרשאה. החברה מתחייבת לא לעשות שימוש בפרטי הגולשים הרשומים באתר, אלא לצרכי תפעול האתר בלבד, ועל -מנת לאפשר את ביצוע הרישום לקורסים המוצעים.

סמכויות שיפוט בכל עניין שבמחלוקת הנובע מאתר זה במישרין ו/או בעקיפין ובכל עניין כאמור הנוגע לחברה ו/או מי מטעמה, סמכות השיפוט הייחודית מסורה לבית משפט השלום עכו וליתר בתי המשפט המוסמכים, לפי העניין, במקום מושבה של החברה.


      `
    }
    </Text>
    <Text className={classes.approveText}>
      הנני מאשר כי קראתי את
      <Link to='/cancellation_policies'>{` מדיניות הביטולים `}</Link>
      של הקורס ובאישור התקנון הנני מאשר גם את מדיניות הביטולים של הקורס.
    </Text>
  </div>
)

const Terms = () => (
  <InfoPage
    title='תקנון האתר'
    icon={<SafetyCertificateFilled />}
    content={renderContent()}
    image='https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/terms.jpg?alt=media&token=014a8c24-4e6e-46b6-ba91-4b1ad3de2200'
  />
);

export default Terms;
