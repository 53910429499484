import React from "react"
import Row from '../../common/Row'
import VideoSlider from '../../common/Slider/VideoSlider'
import ImageSlider from '../../common/Slider/ImageSlider'
import classes from "./Graduates.module.scss"

const Graduates = () => {
  return (
    <div className={classes.graduates}>
      <Row color='dark-brand'>
        <VideoSlider id='abouRecommends' title='בוגרים ממליצים' color='dark-brand' />
      </Row>
      <Row color='light-brand'>
        <VideoSlider id='aboutGraduates' title='בוגרים 2020' color='light-brand' />
      </Row>
      <Row color='dark-brand'>
        <ImageSlider id='AboutImages' title='גלריית בוגרים' color='dark-brand' />
      </Row>
    </div>
  )
}

export default Graduates
