import { useState, useCallback, useMemo } from "react";
import myFirebase from "../firebase";
import errorMessages from "../utils/errorMessages";
import firebase from "firebase/app"
import { compactHash } from '../utils/utils'

const ORDER = {
  descend: 'desc',
  // ascend: ''
}

const useUser = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [users, setUsers] = useState([]);
  const [size, setSize] = useState();
  const [lastUser, setLastUser] = useState()
  const [tableCustomize, setTableCustomize] = useState()

  const addUser = async values => {
    setLoading(true);
    setError(false)
    const createUserFromAdminFunc = firebase.functions().httpsCallable('createUserFromAdmin')
    const { data } = await createUserFromAdminFunc({ user: compactHash({ ...values, courses: {} }) })
    setLoading(false);
    if (data.error) {
      setError(errorMessages(data.error?.errorInfo?.code));
      return false
    }
    return true
  };

  const massDelete = async ({ selectedRows, perPage, currentPage, lastSortField }) => {
    setLoading(true)
    setError(false)
    try {
      const batch = myFirebase.db.batch()
      selectedRows.forEach((deleteUser) => {
        const deleteUserRef = myFirebase.db.collection('users').doc(deleteUser.id)
        batch.delete(deleteUserRef)
      })
      await batch.commit()
      getUsers(perPage, currentPage, lastSortField)
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
    }
  }

  const getUsersTableCustomize = async () => {
    setLoading(true)
    try {
      const tableCustomizeDoc = await myFirebase.db.collection("tablesCustomize").doc('users').get();
      const data = tableCustomizeDoc.data()
      setTableCustomize(data)
      getUsers(data.perPage, 1, data.lastSortField)
    } catch (e) {
      console.log(e)
    }
  }

  const updateUsersTableCustomize = async (values) => {
    setLoading(true);
    try {
      setTableCustomize({ ...tableCustomize, ...values })
      await myFirebase.db.collection('tablesCustomize').doc('users').update(values);
      getUsers(values.perPage, 1, values.lastSortField)
    } catch {
      setLoading(false);
    }
  }

  const getUsersSize = async () => {
    try {
      const usersSize = await myFirebase.db.collection("counters").doc('users').get();
      setSize(usersSize.data()?.docCounter)
    } catch {
      setSize(0)
    }
  };

  const getUser = async id => {
    setLoading(true);
    try {
      const userData = await myFirebase.db.collection("users").doc(id).get();
      setUser(userData.data());
      setLoading(false);
      return userData.data()
    } catch {
      setLoading(false);
    }
  };

  const updateUser = async ({ id, values }) => {
    setLoading(true);
    setError(false)
    try {
      await myFirebase.db.collection('users').doc(id).update(compactHash(values));
      setLoading(false);
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
    }
  };


  const getUsersWithOffset = async (perPage, offset, sorter) => {
    setLoading(true);
    setError(false)
    try {
      const getUsersByOffsetFunc = firebase.functions().httpsCallable('getUsersByOffset')
      const { data } = await getUsersByOffsetFunc({ offset, limit: parseInt(perPage), sorter })
      const usersList = data.map((item) => {
        let newDate = new Date(1970, 0, 1)
        newDate.setSeconds(item.createdAt._seconds);
        return { ...item, createdAt: newDate }
      })
      setUsers(usersList)
      setLoading(false);
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
    }
  }

  const getUsers = useCallback(async (perPage, pageNumber, sorter, searchObject) => {
    setLoading(true);
    setError(false)
    try {
      let usersRef = myFirebase.db.collection("users")
      if (sorter && !searchObject) {
        usersRef = usersRef.orderBy(sorter.field, ORDER[sorter.order])
      }
      if (searchObject) {
        usersRef = usersRef.orderBy(searchObject.field).startAt(searchObject.text).endAt(searchObject.text + "\uf8ff")
      }
      if (lastUser && pageNumber !== 1) usersRef = usersRef.startAfter(lastUser)
      const usersDoc = await usersRef.limit(perPage).get()
      setLastUser(usersDoc.docs[usersDoc.docs.length - 1])
      const usersList = [];
      let key = 0;
      usersDoc.forEach(userData => {
        usersList.push({ ...userData.data(), key: key++ });
      });
      pageNumber === 1 ? setUsers(usersList) : setUsers([...users, ...usersList])
      setLoading(false);
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
    }
  }, [users, lastUser]);

  const data = useMemo(() => ({
    user,
    size,
    loading,
    error,
    updateUser,
    getUser,
    users,
    getUsers,
    getUsersWithOffset,
    lastUser,
    getUsersSize,
    tableCustomize,
    getUsersTableCustomize,
    updateUsersTableCustomize,
    addUser,
    massDelete
  }), [user, size, loading, error, updateUser, users, lastUser])

  return data
};

export default useUser;
