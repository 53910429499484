import React, { useContext, useState, useMemo, useCallback } from "react";

const CacheContext = React.createContext();

export const useCache = () => {
  return useContext(CacheContext);
};

export const CacheProvider = ({ children }) => {
  const [lessonsCache, setLessonsCache] = useState({});

  const setLessonsCourse = useCallback(({ courseId, lessons, lessonsOrder }) => {
    setLessonsCache(preLessons => ({
      ...preLessons,
      [courseId]: { lessons, lessonsOrder }
    }))
  }, [])

  const value = useMemo(() => ({
    lessonsCache,
    setLessonsCourse
  }), [lessonsCache, setLessonsCourse])

  return (
    <CacheContext.Provider value={value}>{children}</CacheContext.Provider>
  );
};
