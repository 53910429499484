import React, { useState, useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { useCourse } from "../../../contexts/CourseContext";
import classes from "./Lessons.module.scss";
import { Tooltip, Typography } from 'antd';
import MiddleLayout from "../../common/MiddleLayout";
import Grid from "../../common/Grid";
import CircleImage from '../../common/CircleImage'
import useLesson from "../../../hooks/useLesson";
import { useCache } from "../../../contexts/CacheContext";
import classNames from "classnames";

const Lessons = () => {
  const { courses } = useCourse();
  const { currentUser } = useAuth();
  const history = useHistory();
  const { getLessons, lessons, loading, setLessonsData } = useLesson({ startLoading: true });
  const { courseId } = useParams();
  const [lessonsOrder, setLessonsOrder] = useState('loading')
  const { lessonsCache, setLessonsCourse } = useCache()

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (lessonsCache?.[courseId]) {
      setLessonsOrder(lessonsCache[courseId].lessonsOrder)
      setLessonsData(lessonsCache[courseId].lessons)
    } else {
      const currentCourseLessonsOrder = courses.find(course => course.id === courseId)?.lessons
      if (currentCourseLessonsOrder) {
        setLessonsOrder(currentCourseLessonsOrder);
        getLessons(Object.values(currentCourseLessonsOrder))
      }
    }
  }, [courses, courseId, lessonsCache]);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (!loading && Object.keys(lessons).length) {
      setLessonsCourse({ courseId, lessons, lessonsOrder })
    }
  }, [loading, lessonsOrder, lessons, courseId, setLessonsCourse])

  if (!currentUser.courses[courseId]) return <Redirect to='/' />
  if (!loading && !Object.keys(lessons).length) return <div className={classes.loading}><Typography.Title>אין שיעורים בקורס</Typography.Title></div>
  const redirectToLesson = lessonId => {
    history.push(`/courses/${courseId}/lessons/${lessonId}`)
  };
  return (
    <>
      {lessons && lessonsOrder &&
        <MiddleLayout>
          <div className={classes.gridWrapper}>
            <Typography.Text className={classes.header}>שיעורי הקורס</Typography.Text>
            <Grid loading={loading}>
              {Object.keys(lessonsOrder).map(lessonNumber => {
                const isLessonEnabled = Number(lessonNumber) <= Number(currentUser.courses[courseId])
                return (
                  <Tooltip className={classes.toolTip} title={!isLessonEnabled && 'עליך לסיים את השיעור הקודם על מנת להתקדם לשיעור זה'} key={lessonNumber}>
                    <Grid.Item
                      className={classes.item}
                      key={lessonNumber}
                    >
                      <div className={classNames(classes.imgWrapper, { [classes.enable]: isLessonEnabled })}>
                        <CircleImage
                          src={lessons[lessonsOrder[lessonNumber]]?.imageUrl}
                          notAllowed={!isLessonEnabled}
                          onClick={isLessonEnabled && (() => redirectToLesson(lessonsOrder[lessonNumber]))}
                          alt="Avatar"
                        />
                      </div>
                      <div className={classNames(classes.textsWrapper, { [classes.enable]: isLessonEnabled })}>
                        <Typography.Text
                          level={2}
                          disabled={!isLessonEnabled}
                          onClick={isLessonEnabled && (() => redirectToLesson(lessonsOrder[lessonNumber]))}
                          className={classes.title}>{`שיעור מספר ${lessonNumber}`}
                        </Typography.Text>
                        <Typography.Text
                          level={5}
                          disabled={!isLessonEnabled}
                          onClick={isLessonEnabled && (() => redirectToLesson(lessonsOrder[lessonNumber]))}
                          className={classes.subTitle}>{lessons[lessonsOrder[lessonNumber]]?.name}
                        </Typography.Text>
                      </div>
                    </Grid.Item>
                  </Tooltip>
                )
              })}</Grid>
          </div>
        </MiddleLayout>
      }
    </>
  )
};

export default Lessons;
