import React from "react";
import Popover from '../../common/Popover'
import { Typography } from "antd"
import { PhoneOutlined, PhoneFilled } from '@ant-design/icons';
import useMediaQuery from '../../../hooks/useMediaQuery'
import classNames from "classnames";
import classes from "./Phone.module.scss";
const { Text } = Typography

const Phone = () => {
  const { isMobile } = useMediaQuery();

  const renderContent = () => (
    <div className={classes.content}>
      <Text strong>יצירת קשר</Text>
      <Text>6372*</Text>
      <Text>ימים א-ה בין השעות 08:30-21:00</Text>
      <Text>ימי שישי וערבי חג 08:30-14:00</Text>
    </div>
  )

  return (
    <Popover content={renderContent} width={isMobile ? 200 : 300}>
      <div className={classes.phone}>
        <PhoneOutlined className={classNames(classes.icon, classes.outlined)} />
        <PhoneFilled className={classNames(classes.icon, classes.filled)} />
      </div>
    </Popover>
  );
};

export default Phone;
