import React, { useState } from "react";
import { Input, Button, Divider } from 'antd';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import message from '../../../utils/message'
import classes from "./VideosSection.module.scss";

const VideosSection = ({ video, onSave }) => {
  const [links, setLinks] = useState(video.links)

  const handleSave = () => {
    onSave({ ...video, links })
    message.success("שמירה בוצעה בהצלחה")
  }

  const handleDelete = (index) => {
    const newLinks = [...links]
    newLinks.splice(index, 1)
    setLinks(links.length === 1 ? [] : newLinks)
  }

  const handleAdd = () => {
    setLinks([...links, ''])
  }

  const handleVideoChange = (e, index) => {
    const newLinks = [...links]
    newLinks[index] = e.target.value
    setLinks(newLinks)
  }

  return (
    <div>
      <div className={classes.plusIcon}>
        <PlusCircleOutlined onClick={handleAdd} />
      </div>
      <ul className={classes.list}>
        {links.map((item, index) => (
          <li className={classes.item} key={index}>
            <div className={classes.content}>
              <Input className={classes.input} defaultValue={item} onChange={(e) => handleVideoChange(e, index)} />
              <iframe
                src={item}
                className={classes.video}
                allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              >
              </iframe>
              <DeleteOutlined onClick={() => handleDelete(index)} className={classes.deleteIcon} />
            </div>
            <Divider />
          </li>
        ))}
      </ul>
      <Button type='primary' onClick={handleSave}>שמירה</Button>
    </div>
  );
};

export default VideosSection;
