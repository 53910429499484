import React, { useState } from "react";
import classes from "./Questions.module.scss"
import { Button, Input, PageHeader, Radio, Collapse } from "antd"
import message from '../../utils/message'
import { PlusCircleOutlined, CheckCircleFilled } from "@ant-design/icons"
const { Panel } = Collapse;
const { TextArea } = Input;

const QuestionEditor = ({ handleFinish, isDeleteEnabled, number, initialQuestion, initialCorrectAnswer, handleDelete }) => {
  const [correctAnswer, setCorrectAnswer] = React.useState(initialCorrectAnswer || 1);
  const [question, setQuestion] = React.useState(initialQuestion || { correctAnswer: 1 });
  const onCorrectAnswerChange = e => {
    setCorrectAnswer(e.target.value);
    setQuestion({ ...question, correctAnswer: e.target.value })
  };

  const validateFields = () => {
    const validation = ['question', 'answer1', 'answer2', 'answer3', 'answer4'].map(id => (question[id] && question[id] !== ''))
    return validation.every(validate => validate)
  }
  const onFinish = () => {
    if (validateFields()) {
      handleFinish({ question, number })
      message.success("השאלה נוספה בהצלחה")
    } else {
      message.error("שגיאה בהוספת שאלה")
    }
  }
  const onChange = (e) => setQuestion({ ...question, [e.target.id]: e.target.value })

  return (
    <Radio.Group className={classes.form} onChange={onCorrectAnswerChange} value={correctAnswer}>
      <PageHeader className={classes.header} title={` שאלה מספר ${number}`} />
      <TextArea
        placeholder="שאלה"
        id='question'
        onChange={onChange}
        defaultValue={question?.question}
      />
      <div className={classes.answer}>
        <Radio value={1} />
        <Input
          placeholder="תשובה 1"
          id='answer1'
          onChange={onChange}
          defaultValue={question?.answer1}
        />
      </div>
      <div className={classes.answer}>
        <Radio value={2} />
        <Input
          placeholder="תשובה 2"
          id='answer2'
          onChange={onChange}
          defaultValue={question?.answer2}
        />
      </div>
      <div className={classes.answer}>
        <Radio value={3} />
        <Input
          placeholder="תשובה 3"
          id='answer3'
          onChange={onChange}
          defaultValue={question?.answer3}
        />
      </div>
      <div className={classes.answer}>
        <Radio value={4} />
        <Input
          placeholder="תשובה 4"
          id='answer4'
          onChange={onChange}
          defaultValue={question?.answer4}
        />
      </div>
      <Button
        className={classes.wrapper}
        disabled={!isDeleteEnabled || !validateFields()}
        danger
        type='primary'
        block
        size='large'
        onClick={() => handleDelete(number)}>
        מחק
      </Button>
      <Button disabled={!validateFields()} type='primary' block size='large' onClick={onFinish}>
        שמור
        </Button>
    </Radio.Group>
  );
};

const Questions = ({ onAddQuestion, initialValue }) => {
  const [questionsCounter, setQuestionsCounter] = useState(Object.keys(initialValue || {})?.length || 0);
  const [questions, setQuestions] = useState(initialValue || {});
  const handleAddQuestionClicked = () => setQuestionsCounter(questionsCounter + 1)
  const addQuestion = ({ question, number }) => {
    onAddQuestion({ ...questions, [number]: question });
    setQuestions({ ...questions, [number]: question });
  }

  const handleDelete = (number) => {
    const newQuestions = {}
    for (const questionNumber of Object.keys(questions)) {
      if (Number(questionNumber) !== Number(number)) {
        newQuestions[questionNumber] = questions[questionNumber]
      }
    }
    delete newQuestions[number]
    let flag = false;
    const keysArr = Object.keys(newQuestions).sort();
    keysArr.forEach((number, index) => {
      if (Number(number) !== 1 && (Number(number) - 1 !== Number(keysArr[index - 1]) || flag)) {
        newQuestions[Number(number) - 1] = newQuestions[number]
        delete newQuestions[number]
        flag = true;
      }
    })
    setQuestions(newQuestions)
    onAddQuestion(newQuestions)
    setQuestionsCounter(questionsCounter - 1)
    message.success("השאלה נמחקה בהצלחה")
  }
  return (
    <div className={classes.wrapper}>
      <Button block icon={<PlusCircleOutlined />} size='large' onClick={handleAddQuestionClicked}>
        הוסף שאלה
        </Button>
      <Collapse>
        {[...Array(questionsCounter).keys()].map(number => {
          return (
            <Panel extra={questions[`${number + 1}`] && <CheckCircleFilled style={{ color: '#52c41a' }} />} header={` שאלה מספר ${number + 1}`} key={number}>
              <QuestionEditor
                initialQuestion={questions[`${number + 1}`]}
                initialCorrectAnswer={questions[`${number + 1}`]?.correctAnswer}
                handleFinish={addQuestion}
                handleDelete={handleDelete}
                number={number + 1}
                key={questions[`${number + 1}`]?.question}
                isDeleteEnabled={!!questions[`${number + 1}`]}
              />
            </Panel>
          )
        })}
      </Collapse>
    </div>
  );
}

export default Questions;
