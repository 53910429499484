import { useState, useEffect } from "react";
import myFirebase from "../firebase";
import errorMessages from "../utils/errorMessages";

const { storage } = myFirebase

const useImage = (params = {}) => {
  const { fetchImages, id } = params

  const [loading, setLoading] = useState(fetchImages);
  const [error, setError] = useState();
  const [images, setImages] = useState([]);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (fetchImages) getImages()
  }, [])

  const getImages = async () => {
    setLoading(true)
    try {
      const storageRef = storage.ref()
      const listRef = storageRef.child(id)
      const imagesList = await listRef.listAll()
      const promises = []
      imagesList.items.forEach(image => {
        promises.push(image.getDownloadURL())
      })
      const urlsList = await Promise.all(promises)
      setImages(urlsList)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setError(errorMessages(error.code))
    }
  }

  const data = {
    images,
    loading,
    error
  }

  return data
};

export default useImage;
