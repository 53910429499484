import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Button, Card, Skeleton, Image, Space } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import classes from "../Course/AddCourse.module.scss"
import myFirebase from "../../../firebase"
import message from '../../../utils/message'
import useBlog from "../../../hooks/useBlog"
import { useHistory } from "react-router-dom"
import RichTextEditor from "../../common/RichTextEditor"
import FormWrapper from '../../common/Form/FormWrapper'

const EditBlog = () => {
  const { blogId } = useParams();
  const { blog, updateBlog } = useBlog({ fetchBlog: true, blogId });
  const [form] = Form.useForm();
  const [courseDescription, setCourseDescription] = useState('');
  const [imgCounter, setImgCounter] = useState(0);
  const history = useHistory();
  const inputRef = React.useRef(null);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (blog) setCourseDescription(blog.description)
  }, [blog])

  const onFinish = async values => {
    try {
      const { name } = values;
      updateBlog({ name, description: courseDescription, id: blogId })
      message.success("הקורס עודכן בהצלחה")
      history.push('/admin/blogs');
    } catch (error) {
      message.error("עדכון הקורס נכשל")
    }
  };

  async function uploadImage(file) {
    const metadata = {
      contentType: 'image/jpg'
    };

    const storageRef = await myFirebase.storage.ref();
    const imgFile = storageRef.child(`BlogsImages/${blogId}`);
    imgFile.put(file, metadata).then(() => {
      message.success("העלאת תמונה בוצעה בהצלחה")
      setImgCounter(imgCounter + 1)
    })
      .catch(() => ({ title: 'העלאת תמונה נכשלה', description: '' }));
  }

  const handleOnFileChosen = (e) => uploadImage(e.target.files[0]);

  const handleOpenFileInput = () => {
    inputRef.current.click();
  };

  return (
    <FormWrapper>
      {!blog ? <Skeleton active /> :
        <Card title="טופס עריכת בלוג" className={classes.card}>
          <Form form={form} name="edit_blog" onFinish={onFinish} initialValues={blog}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "יש להזין שם בלוג" },
              ]}
            >
              <Input
                placeholder="שם הבלוג"
              />
            </Form.Item>
            <div className={classes.tiny}>
              <RichTextEditor initialValue={blog.description} onChange={setCourseDescription} />
            </div>
            <Space size={12}>
              <Image
                src={`${blog.imageUrl}?${imgCounter}`}
                width={200}
                key={imgCounter}
              />
              <Button
                icon={<UploadOutlined />}
                type="primary"
                key={imgCounter}
                onClick={handleOpenFileInput}
              >
                עדכן תמונת בלוג
                <input multiple={false} ref={inputRef} style={{ display: 'none' }} type="file" onChange={handleOnFileChosen} accept="image/png, image/jpeg, image/jpg" />
              </Button>
              רזולוצית התמונה - 450 × 170
              </Space>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  block
                  className={classes.submitButton}
                  type="primary"
                  htmlType="submit"
                  size='large'
                  disabled={
                    !!form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  עדכן בלוג
                </Button>
              )}
            </Form.Item>
          </Form>
        </Card>}
    </FormWrapper>
  );
};

export default EditBlog;
