import React from "react";
import Grid from "../../common/Grid";
import { useCourse } from "../../../contexts/CourseContext";
import { useHistory } from "react-router-dom";
import { Typography } from "antd";
import MiddleLayout from "../../common/MiddleLayout";
import CircleImage from '../../common/CircleImage'
import classes from './Courses.module.scss'
import classnames from "classnames";

const Courses = () => {
  const { courses, loading } = useCourse();
  const history = useHistory();
  const redirectToCourseInfo = courseId => {
    history.push(`/courses/${courseId}`)
  };

  const renderCourse = course => (
    <Grid.Item
      key={course.id}
    >
      <div className={classnames(classes.imgWrapper, classes.imgHover)}>
        <CircleImage
          src={course.roundImageUrl}
          onClick={() => redirectToCourseInfo(course.id)}
        />
      </div>
      <Typography.Text level={2} onClick={() => redirectToCourseInfo(course.id)} className={classes.title}>{course.name}</Typography.Text>
    </Grid.Item>
  );

  return (
    <MiddleLayout>
      <div className={classes.gridWrapper}>
        <Typography.Text className={classes.header}>הקורסים שלנו</Typography.Text>
        <Grid loading={loading}>{courses.map(renderCourse)}</Grid>
      </div>
    </MiddleLayout>
  )
};

export default Courses;
