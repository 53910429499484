import React from "react";
import Carousel from './Carousel'
import MiddleLayout from '../../common/MiddleLayout'
import Row from '../../common/Row'
import CoursesSlider from './CoursesSlider'
import BlogsSlider from './BlogsSlider'
import VideoSlider from '../../common/Slider/VideoSlider'
import ContactUs from '../ContactUs/ContactUs'
import SocialButtons from '../SocialButtons/SocialButtons'

const Home = () => {
  return (
    <div>
      <Carousel />
      <MiddleLayout>
        <Row color='light-brand'>
          <CoursesSlider type='image' color='light-brand' title='הקורסים שלנו' redirect />
        </Row>
        <Row color='dark-brand'>
          <CoursesSlider type='video' color='dark-brand' title='סרטונים מקורסים' />
        </Row>
        <Row color='light-brand'>
          <VideoSlider id='homeContent' title='מכלול הדרכות' color='light-brand' />
        </Row>
        <Row color='dark-brand'>
          <BlogsSlider />
        </Row>
        <Row color='orange'>
          <ContactUs inRow />
        </Row>
        <SocialButtons />
      </MiddleLayout>
    </div>
  );
};

export default Home;
