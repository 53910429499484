import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import InlineFieldWrapper from "./Form/InlineFieldWrapper";
import FieldsInRowWrapper from "./Form/FieldsInRowWrapper";

const UserForm = ({ onSave, loading, isUpdate, initialValues }) => {
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: isUpdate ? 6 : 4 }
  };

  return (
    <Form
      name="basic"
      onFinish={onSave}
      scrollToFirstError
      initialValues={initialValues}
      form={form}
      {...layout}
    >
      <FieldsInRowWrapper>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: "יש להזין שם פרטי" }]}
        >
          <InlineFieldWrapper placeholder="* שם פרטי">
            <Input />
          </InlineFieldWrapper>
        </Form.Item>

        <Form.Item
          name="lastName"
          rules={[{ required: true, message: "יש להזין שם משפחה" }]}
        >
          <InlineFieldWrapper placeholder="* שם משפחה">
            <Input />
          </InlineFieldWrapper>
        </Form.Item>
      </FieldsInRowWrapper>
      <FieldsInRowWrapper>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: "יש להזין מספר טלפון" }]}
        >
          <InlineFieldWrapper placeholder="* טלפון">
            <Input type="number" />
          </InlineFieldWrapper>
        </Form.Item>

        <Form.Item
          name="israeliId"
          rules={[{ required: true, message: "יש להזין ת.ז" }]}
        >
          <InlineFieldWrapper placeholder="* ת.ז">
            <Input type="number" />
          </InlineFieldWrapper>
        </Form.Item>
      </FieldsInRowWrapper>

      {!isUpdate &&
        <Form.Item
          name="email"
          rules={[
            { required: true, message: "יש להזין מייל" },
            { type: "email", message: "יש להזין מייל חוקי" }
          ]}
        >
          <InlineFieldWrapper placeholder="* מייל">
            <Input />
          </InlineFieldWrapper>
        </Form.Item>
      }

      <FieldsInRowWrapper>
        <Form.Item name="city">
          <InlineFieldWrapper placeholder="עיר">
            <Input />
          </InlineFieldWrapper>
        </Form.Item>

        <Form.Item name="zipcode">
          <InlineFieldWrapper placeholder="מיקוד">
            <Input />
          </InlineFieldWrapper>
        </Form.Item>

        <Form.Item name="street">
          <InlineFieldWrapper placeholder="רחוב">
            <Input />
          </InlineFieldWrapper>
        </Form.Item>
      </FieldsInRowWrapper>

      {!isUpdate &&
        <>
          <Form.Item
            name="password"
            hasFeedback
            rules={[
              { required: true, message: "יש להזין סיסמא" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value?.length >= 6) return Promise.resolve();
                  return Promise.reject(new Error("הסיסמא חייבת להיות באורך 6"));
                }
              })
            ]}
          >
            <InlineFieldWrapper placeholder='* סיסמא'>
              <Input.Password />
            </InlineFieldWrapper>
          </Form.Item>

          <Form.Item
            name="password2"
            hasFeedback
            dependencies={["password"]}
            rules={[
              { required: true, message: "יש להזין אימות סיסמא" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value)
                    return Promise.resolve();
                  return Promise.reject(new Error("סיסמה לא תואמת"));
                }
              }),
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (isUpdate && getFieldValue("password") && !value)
                    return Promise.reject(new Error("אנא הכנס אימות סיסמא חדשה"));
                  return Promise.resolve();
                }
              })
            ]}
          >
            <InlineFieldWrapper
              placeholder={isUpdate ? "אימות סיסמא חדשה" : "* אימות סיסמא"}
            >
              <Input.Password />
            </InlineFieldWrapper>
          </Form.Item>

          <Form.Item
            name="agreement"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("יש לקרוא את התקנון ולאשר"))
              }
            ]}
          >
            <Checkbox>
              הנני מאשר כי קראתי את <a href="">התקנון</a>
            </Checkbox>
          </Form.Item>
        </>
      }

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {isUpdate ? "עדכן" : "הירשם"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
