import React, { useEffect } from "react";
import { Form, Button, Select } from "antd"
import message from '../../../utils/message'
import InlineFieldWrapper from "../../common/Form/InlineFieldWrapper";
import FieldsInRowWrapper from "../../common/Form/FieldsInRowWrapper";
import InlineInput from "../../common/Form/InlineInput";
import InlineSelect from "../../common/Form/InlineSelect";
import { useCourse } from "../../../contexts/CourseContext"
import useEmailService from '../../../hooks/useEmailService'
import classes from './ContactUsForm.module.scss'
import classNames from "classnames"
const { Option } = Select;

const successMsg = "ההודעה נשלחה בהצלחה, נציגנו יצרו איתך קשר בהקדם."

const layout = {
  labelCol: { span: 6 }
};

const ContactUsForm = ({ color, onSuccess }) => {
  const { courses } = useCourse();
  const { sendEmail, loading, success, error } = useEmailService()

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (error) message.error(error)
  }, [error])

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (success) {
      message.success(successMsg, 7)
      if (onSuccess) onSuccess()
    }
  }, [success])

  const onSave = ({ email, name, course, phone }) => {
    sendEmail({
      to: 'michlol.office@gmail.com',
      subject: 'מתעניין חדש בקורס',
      html: `
        <b>${name}</b> התעניין בקורס <b>${course}</b><br />
         מהספר הטלפון: ${phone}<br />
         ${email} :כתובת המייל<br />
         בהצלחה.
      `
    })
  }

  const renderOption = ({ name }) => (
    <Option key={name} value={name}>{name}</Option>
  )

  return (
    <Form
      name="basic"
      onFinish={onSave}
      scrollToFirstError
      {...layout}
    >
      <FieldsInRowWrapper>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "יש להזין שם מלא" }]}
        >
          <InlineFieldWrapper placeholder="* שם מלא" color={color}>
            <InlineInput />
          </InlineFieldWrapper>
        </Form.Item>

        <Form.Item name="email">
          <InlineFieldWrapper placeholder="דוא״ל" color={color}>
            <InlineInput />
          </InlineFieldWrapper>
        </Form.Item>

      </FieldsInRowWrapper>

      <FieldsInRowWrapper>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: "יש להזין מספר טלפון" }]}
        >
          <InlineFieldWrapper placeholder="* טלפון" color={color}>
            <InlineInput type="number" />
          </InlineFieldWrapper>
        </Form.Item>

        <Form.Item name='course'>
          <InlineFieldWrapper placeholder="בחר קורס" color={color}>
            <InlineSelect allowClear>
              {courses.map(renderOption)}
            </InlineSelect>
          </InlineFieldWrapper>
        </Form.Item>
      </FieldsInRowWrapper>

      <Form.Item className={classes.buttonItem}>
        <Button
          htmlType="submit"
          loading={loading}
          className={classNames(classes.button, { [classes.success]: success, [classes[color]]: color })}
          disabled={success}
        >
          {success ? 'הודעה נשלחה' : 'שליחה'}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ContactUsForm
