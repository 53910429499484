import React from "react";
import { Select } from "antd"
import classes from "./InlineSelect.module.scss";
import classNames from "classnames";

const InlineSelect = ({ children, className, color, ...props }) => {
  const selectClassName = classNames(
    className,
    classes.inlineSelect,
    { [classes[color]]: color }
  )

  return (
    <Select className={selectClassName} {...props}>
      {children}
    </Select>
  );
};

export default InlineSelect;
