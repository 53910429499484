import React from "react";
import SectionHeader from './SectionHeader'
import MiddleLayout from "../../common/MiddleLayout"
import SectionScrollDetected from './SectionScrollDetected'
import useMediaQuery from '../../../hooks/useMediaQuery'
import classes from "./Section7.module.scss";

const Section7 = ({ onVisisbleChange }) => {
  const { isMobile } = useMediaQuery()

  const renderText = () => (
    <>
      <span className={classes.text1}>
        <span className={classes.textHeader}>סיוע בפתיחת עסק</span>
        <span>
          {`אם החלום שלך הוא לפתוח עסק עצמאי, סגל המכללה גאה לסייע לך להגשים אותו.
          מקישורים לספקי הציוד והחומרים המובילים בארץ, דרך הנחיות, ליווי ותמיכה בהקמת העסק והעמדתו על הרגליים, ייעוץ לדרכי פרסום ושיווק ועד שיתופי פעולה עם הגופים והקרנות המובילים בארץ לתכנון ובניית עסקים קטנים - אנחנו איתך, לאורך כל הדרך.`}
        </span>
      </span>
      <span className={classes.text2}>
        <span className={classes.textHeader}>סיוע במציאת עבודה</span>
        <span>
          {`במכלול פועלת מחלקת השמה, המקושרת עם מיטב החברות המובילות במשק הישראלי, בכל התחומים הנלמדים במכללה.
          המחלקה מסייעת לחברות אלו להעסיק את העובדים המקצועיים הטובים בארץ - אתכם, תלמידינו ובוגרינו ! לאחר 3-4 חודשי לימוד (בהתאם לקורס בו בחרת) או בתום לימודיך, נוכל להתחיל לסייע לך להשתלב בעבודה במקצוע שבחרת.`}
        </span>
      </span>
    </>

  )

  const src = isMobile ?
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection7-mobile.jpg?alt=media&token=4fa74ce0-0a6a-46c4-9822-5468ad956d09' :
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection7.jpg?alt=media&token=b3b8a8fe-1d21-41af-888b-d97a742eb6da'

  return (
    <div className={classes.section} style={{ background: `url(${src})` }}>
      <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
      <MiddleLayout>
        <SectionHeader
          number='7'
          color='dark-brand'
          title={`סיוע השתלבות במעגל העבודה 
          לשכירים ועצמאים`}
          className={classes.header}
          text={renderText()}
          textWidth='550px'
        />
      </MiddleLayout>
    </div>
  );
};

export default Section7;
