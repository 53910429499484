import React from "react";
import { HomeOutlined, TeamOutlined } from '@ant-design/icons';
import classes from "./SectionsMenu.module.scss"
import classNames from "classnames"

const scrollToSection = (ref) => {
  ref.current.scrollIntoView({ behavior: "smooth", inline: "nearest", block: "start" })
}

const SectionsMenu = ({ visisbleSection, section0Ref, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, section7Ref, section8Ref }) => (
  <div className={classes.sideMenu}>
    <ul className={classes.list}>
      <li className={classNames(classes.listItem, { [classes.isActive]: visisbleSection[0] })} onClick={() => scrollToSection(section0Ref)}>
        <div className={classes.dot}>
          <span className={classNames(classes.dotText, classes.isIcon)}>
            <HomeOutlined />
          </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: !visisbleSection[0] && visisbleSection[1] })} onClick={() => scrollToSection(section1Ref)}>
        <div className={classes.dot}>
          <span className={classes.dotText}>
            1
      </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: visisbleSection[2] })} onClick={() => scrollToSection(section2Ref)}>
        <div className={classes.dot}>
          <span className={classes.dotText}>
            2
      </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: !visisbleSection[2] && !visisbleSection[4] && visisbleSection[3] })} onClick={() => scrollToSection(section3Ref)}>
        <div className={classes.dot}>
          <span className={classes.dotText}>
            3
      </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: visisbleSection[4] })} onClick={() => scrollToSection(section4Ref)}>
        <div className={classes.dot}>
          <span className={classes.dotText}>
            4
      </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: !visisbleSection[4] && !visisbleSection[6] && visisbleSection[5] })} onClick={() => scrollToSection(section5Ref)}>
        <div className={classes.dot}>
          <span className={classes.dotText}>
            5
      </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: visisbleSection[6] })} onClick={() => scrollToSection(section6Ref)}>
        <div className={classes.dot}>
          <span className={classes.dotText}>
            6
      </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: !visisbleSection[6] && visisbleSection[7] })} onClick={() => scrollToSection(section7Ref)}>
        <div className={classes.dot}>
          <span className={classes.dotText}>
            7
      </span>
        </div>
      </li>
      <li className={classNames(classes.listItem, { [classes.isActive]: visisbleSection[8] })} onClick={() => scrollToSection(section8Ref)}>
        <div className={classNames(classes.dot, classes.contactUs)}>
          <span className={classNames(classes.dotText, classes.isIcon)}>
            <TeamOutlined />
          </span>
        </div>
      </li>
    </ul>
  </div>
)

export default SectionsMenu;
