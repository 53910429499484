import React from "react";
import Slider from '../../common/Slider/Slider'
import { Typography } from "antd"
import { useCourse } from "../../../contexts/CourseContext"
import useMediaQuery from '../../../hooks/useMediaQuery'
import { useHistory } from "react-router-dom"
import classes from './CoursesSlider.module.scss'

const { Text } = Typography

const CoursesSlider = ({ type, title, color, redirect }) => {
  const { courses } = useCourse()
  const { isMobile, isTablet } = useMediaQuery()
  const history = useHistory()

  const getChunkNumber = () => {
    let chunkNumber = 3
    if (isTablet) chunkNumber = 2
    if (isMobile) chunkNumber = 1
    if (courses.length < chunkNumber) chunkNumber = courses.length
    return chunkNumber
  }

  const renderImage = (course) => (
    <div className={classes.imageSlider}>
      <img src={course.imageUrl} className={classes.image} />
      <div className={classes.textWrapper}>
        <Text className={classes.text}>{course.name}</Text>
      </div>
    </div>
  )

  const renderVideo = (course) => (
    <iframe
      src={course.videoUrl}
      className={classes.video}
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    >
    </iframe>
  )

  const chunkNumber = getChunkNumber()

  const redirectToCourseInfo = ({ id }) => {
    history.push(`/courses/${id}`)
  };

  return (
    <Slider
      title={title}
      chunkNumber={chunkNumber}
      color={color}
      oneSlider={chunkNumber === courses.length}
    >
      {courses.map((course) => (
        <Slider.Item
          key={course.id}
          onClick={() => redirect && redirectToCourseInfo(course)}
          isVideo={type === 'video'}
        >
          {type === 'image' && renderImage(course)}
          {type === 'video' && renderVideo(course)}
        </Slider.Item>
      ))}
    </Slider>
  );
};

export default CoursesSlider;
