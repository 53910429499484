import React, { useState } from "react";
import classes from "./TopBar.module.scss";
import classNames from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import Menu from './Menu'
import Interesting from './Interesting'
import Phone from './Phone'
import Logo from './Logo'
import LoginButton from './LoginButton'
import SignUpButton from './SignUpButton'
import ProfileButton from './ProfileButton'
import CoursesButton from './CoursesButton'
import { useAuth } from '../../../contexts/AuthContext'

const TopBar = () => {
  const { currentUser } = useAuth()
  const [isOnTop, setIsOnTop] = useState(true)

  const onChange = (isVisible) => {
    setIsOnTop(isVisible)
  }

  return (
    <>
      <VisibilitySensor onChange={onChange}><div className={classes.scrollDetected}>scroll</div></VisibilitySensor>
      <div className={classNames(classes.topBar, { [classes.isOnTop]: isOnTop })}>
        <div className={classes.content}>
          <div className={classes.rightSide}>
            <Menu />
            <Logo />
          </div>
          <div className={classes.leftSide}>
            <Interesting />
            <div className={classes.buttons}>
              <Phone />
              {currentUser ?
                <>
                  <CoursesButton />
                  <ProfileButton />
                </>
                :
                <>
                  <LoginButton />
                  <SignUpButton />
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
