import React, { useEffect, useState } from "react";
import { Button, Select, Form, Input, Skeleton, Checkbox } from "antd"
import useUser from "../../../hooks/useUser";
import FormWrapper from '../../common/Form/FormWrapper'
import message from '../../../utils/message'
import {
  useParams
} from "react-router-dom";
import FieldsInRowWrapper from "../../common/Form/FieldsInRowWrapper"
import InlineFieldWrapper from "../../common/Form/InlineFieldWrapper"
import { useCourse } from "../../../contexts/CourseContext"
import { useHistory } from "react-router-dom";
const { Option } = Select;

const User = () => {
  const { getUser, loading, updateUser } = useUser();
  const [user, setUser] = useState();
  const [currentCourses, setCurrentCourses] = useState(user?.courses)
  const { userId } = useParams();
  const { courses } = useCourse();
  const history = useHistory();

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    getUser(userId).then((res) => {
      setCurrentCourses(res.courses);
      setUser(res);
    })
  }, []);

  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 6 }
  };

  const renderCoursesOptions = () => (
    courses.map(course => (
      <Option key={course.id} value={course.id}>{course.name}</Option>
    ))
  )

  const onFinish = (values) => {
    const newCourses = {}
    for (const courseId of Object.keys(currentCourses)) {
      newCourses[courseId] = Number(values[courseId]) || 1
    }
    updateUser({ id: userId, values: { ...values, courses: newCourses } })
    message.success(`המשתמש ${user.firstName} ${user.lastName} עודכן בהצלחה`)
    history.push("/admin/users")
  }

  const onCoursesChanged = (values) => {
    const newCurrentCourses = {}
    for (const courseId of values) {
      if (currentCourses[courseId]) {
        newCurrentCourses[courseId] = currentCourses[courseId]
      } else {
        newCurrentCourses[courseId] = 1;
      }
    }
    setCurrentCourses(newCurrentCourses);
  }

  return (
    <FormWrapper>
      {!user ? <Skeleton active /> :
        <Form
          name="basic"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ ...user, ...currentCourses, courses: [] }}
          form={form}
          {...layout}
        >
          <FieldsInRowWrapper>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: "יש להזין שם פרטי" }]}
            >
              <InlineFieldWrapper placeholder="* שם פרטי">
                <Input />
              </InlineFieldWrapper>
            </Form.Item>

            <Form.Item
              name="lastName"
              rules={[{ required: true, message: "יש להזין שם משפחה" }]}
            >
              <InlineFieldWrapper placeholder="* שם משפחה">
                <Input />
              </InlineFieldWrapper>
            </Form.Item>
          </FieldsInRowWrapper>
          <FieldsInRowWrapper>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: "יש להזין מספר טלפון" }]}
            >
              <InlineFieldWrapper placeholder="* טלפון">
                <Input type="number" />
              </InlineFieldWrapper>
            </Form.Item>

            <Form.Item
              name="israeliId"
              rules={[{ required: true, message: "יש להזין ת.ז" }]}
            >
              <InlineFieldWrapper placeholder="* ת.ז">
                <Input type="number" />
              </InlineFieldWrapper>
            </Form.Item>
          </FieldsInRowWrapper>

          <FieldsInRowWrapper>
            <Form.Item name="city">
              <InlineFieldWrapper placeholder="עיר">
                <Input />
              </InlineFieldWrapper>
            </Form.Item>

            <Form.Item name="zipcode">
              <InlineFieldWrapper placeholder="מיקוד">
                <Input />
              </InlineFieldWrapper>
            </Form.Item>

            <Form.Item name="street">
              <InlineFieldWrapper placeholder="רחוב">
                <Input />
              </InlineFieldWrapper>
            </Form.Item>
          </FieldsInRowWrapper>

          <Form.Item>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="אנא בחר קורסים עבור המשתמש"
              defaultValue={Object.keys(currentCourses || {})}
              onChange={onCoursesChanged}
            >
              {renderCoursesOptions()}
            </Select>
          </Form.Item>
          {Object.keys(currentCourses).map(courseId => (
            <Form.Item key={courseId} name={courseId}>
              <InlineFieldWrapper placeholder={courses.find(course => course.id === courseId)?.name} showLabelAlways>
                <Input type="number" id={courseId} defaultValue={currentCourses[courseId]} />
              </InlineFieldWrapper>
            </Form.Item>
          ))}

          <Form.Item name="isActive" valuePropName="checked">
            <Checkbox>
              המשתמש מאושר
              </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              עדכן
              </Button>
          </Form.Item>
        </Form>
      }
    </FormWrapper>
  );
};

export default User;
