import React from "react";
import { Input } from "antd"
import classes from "./InlineInput.module.scss";
import classNames from "classnames";

const InlineInput = ({ className, color, ...props }) => {
  const inputClassName = classNames(
    className,
    classes.inlintInput,
    { [classes[color]]: color }
  )

  return (
    <Input className={inputClassName} {...props} />
  );
};

export default InlineInput;
