import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Button, Card, Skeleton, Image, Space } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import classes from "./AddCourse.module.scss"
import myFirebase from "../../../firebase"
import message from '../../../utils/message'
import { useCourse } from "../../../contexts/CourseContext"
import { useHistory } from "react-router-dom"
import RichTextEditor from "../../common/RichTextEditor"
import FormWrapper from '../../common/Form/FormWrapper'

const EditCourse = () => {
  const [form] = Form.useForm();
  const [course, setCourse] = useState(null);
  const [courseDescription, setCourseDescription] = useState('');
  const [imgCounter, setImgCounter] = useState(0);
  const [roundImgCounter, setRoundImgCounter] = useState(0);
  const { updateCourse } = useCourse();
  const history = useHistory();
  const { courseId } = useParams();
  const inputRef = React.useRef(null);
  const roundInputRef = React.useRef(null);
  const { courses } = useCourse();

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    const currentCourse = courses.find(course => course.id === courseId);
    setCourse(currentCourse)
    setCourseDescription(currentCourse?.description)
  }, [courses]);

  const onFinish = async values => {
    try {
      const { name, videoUrl } = values;
      updateCourse({ name, videoUrl, description: courseDescription, lessons: course.lessons, id: courseId, createdAt: course.createdAt })
      message.success("הקורס עודכן בהצלחה")
      history.push(`/admin/courses/${courseId}`);
    } catch (error) {
      message.error("עדכון הקורס נכשל")
    }
  };

  async function uploadImage({ file, path, setCounter, imgCounter }) {
    const metadata = {
      contentType: 'image/jpg'
    };
    const storageRef = await myFirebase.storage.ref();
    const imgFile = storageRef.child(path);
    imgFile.put(file, metadata).then(() => {
      message.success("העלאת תמונה בוצעה בהצלחה")
      setCounter(imgCounter + 1)
    })
      .catch(() => ({ title: 'העלאת תמונה נכשלה', description: '' }));
  }

  const handleImgChosen = (e) => uploadImage({ file: e.target.files[0], path: `CoursesImages/${courseId}`, setCounter: setImgCounter, imgCounter });
  const handleRoundImgChosen = (e) => uploadImage({ file: e.target.files[0], path: `CoursesRoundImages/${courseId}`, setCounter: setRoundImgCounter, imgCounter: roundImgCounter });

  const handleOpenFileInput = () => inputRef.current.click();
  const handleRoundOpenFileInput = () => roundInputRef.current.click();

  return (
    <FormWrapper>
      {!course ? <Skeleton active /> :
        <Card title="טופס עריכת קורס" className={classes.card}>
          <Form form={form} name="edit_course" onFinish={onFinish} initialValues={course}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "יש להזין שם קורס" },
              ]}
            >
              <Input
                placeholder="שם הקורס"
              />
            </Form.Item>
            <Form.Item
              name="videoUrl"
              rules={[
                { required: true, message: "יש להזין קישור לוידאו הקורס" },
              ]}
            >
              <Input
                placeholder="קישור לוידאו הקורס"
              />
            </Form.Item>
            <div className={classes.tiny}>
              <RichTextEditor initialValue={course.description} onChange={setCourseDescription} />
            </div>
            <Space direction='vertical' size={12}>
              <Image
                src={`${course.imageUrl}?${imgCounter}`}
                width={200}
                key={imgCounter}
              />
              <Button
                icon={<UploadOutlined />}
                type="primary"
                key={imgCounter}
                onClick={handleOpenFileInput}
              >
                עדכן תמונת תמונת קורס לעמוד הראשי
                <input multiple={false} ref={inputRef} style={{ display: 'none' }} type="file" onChange={handleImgChosen} accept="image/png, image/jpeg, image/jpg" />
              </Button>
              רזולוצית התמונה - 450 × 170
              <Image
                src={`${course?.roundImageUrl}?${roundImgCounter}`}
                width={200}
                key={roundImgCounter}
              />
              <Button
                icon={<UploadOutlined />}
                type="primary"
                key={roundImgCounter}
                onClick={handleRoundOpenFileInput}
              >
                עדכן תמונת קורס לרשימת הקורסים
                <input multiple={false} ref={roundInputRef} style={{ display: 'none' }} type="file" onChange={handleRoundImgChosen} accept="image/png, image/jpeg, image/jpg" />
              </Button>
              רזולוצית התמונה - 196 × 196
            </Space>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  block
                  className={classes.submitButton}
                  type="primary"
                  htmlType="submit"
                  size='large'
                  disabled={
                    !!form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                >
                  עדכן קורס
                </Button>
              )}
            </Form.Item>
          </Form>
        </Card>}
    </FormWrapper>
  );
};

export default EditCourse;
