import React, { useEffect, useState } from "react";
import { Typography, Card, Skeleton, Collapse, Radio, Button, Result, Layout, Menu, PageHeader, Modal } from "antd"
import useLesson from "../../../hooks/useLesson";
import MiddleLayout from "../../common/MiddleLayout";
import { useParams } from "react-router-dom";
import { useCourse } from "../../../contexts/CourseContext"
import { Link, useHistory } from "react-router-dom";
import classes from "./Lesson.module.scss";
import classnames from 'classnames';
import message from '../../../utils/message';
import { useAuth } from "../../../contexts/AuthContext";
import { BookOutlined } from '@ant-design/icons';
import { Redirect } from "react-router-dom";
import { useCache } from "../../../contexts/CacheContext";
const { Panel } = Collapse;

const Lesson = () => {
  const { courses, loading } = useCourse();
  const { lessonId, courseId } = useParams();
  const [lesson, setLesson] = useState();
  const [fetchingData, setFetchingData] = useState(true);
  const [answers, setAnswers] = useState({});
  const [lessonNumber, setLessonNumber] = useState(-1);
  const [solved, setSolved] = useState(false);
  const [canMove, setCanMove] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { getLesson, canMoveToNextLesson } = useLesson();
  const { currentUser, updateDetailsUser } = useAuth();
  const { lessonsCache } = useCache()
  const history = useHistory();

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (lessonsCache?.[courseId]) {
      setData(lessonsCache[courseId].lessons[lessonId])
    } else {
      if (fetchingData && !loading) {
        setFetchingData(false)
        getLesson(lessonId).then((res) => {
          setData(res)
        })
      }
    }
  }, [loading, lessonId, lesson, fetchingData, lessonsCache, courseId]);

  const setData = (data) => {
    setLesson(data)
    setLessonNumber(getLessonNumber(data))
    const defaultAnswers = {}
    for (const key of Object.keys(lesson?.questions || {})) {
      defaultAnswers[key] = 1;
    }
    setAnswers(defaultAnswers)
    canMoveToNextLesson({ userId: currentUser.id, courseId: data.courseId, lessonNumber })
      .then(result => setCanMove(result))
  }

  const getLessonNumber = (currentLesson) => {
    let lessonNumber = -1
    const currentCourse = courses.find(c => c.id === currentLesson.courseId)
    if (currentLesson && currentCourse) {
      for (const key of Object.keys(currentCourse.lessons)) {
        if (currentCourse.lessons[key] === lessonId)
          lessonNumber = key
      }
    }
    return Number(lessonNumber);
  }

  const handleCheckAnswer = () => {
    const userAnswers = Object.keys(answers).map(key => Number(lesson.questions[key].correctAnswer) === Number(answers[key]))
    if (userAnswers.length === Object.keys(lesson.questions).length && userAnswers.every(element => element === true)) {
      setSolved(true)
      if (isFinalLesson()) {
        setShowModal(true);
      } else {
        message.success('כל הכבוד! ענית נכון על כל השאלות, עתה ניתן לעבור לשיעור הבא בקורס')
      }
      const lessonNumber = getLessonNumber(lesson);
      if (lessonNumber === currentUser.courses[lesson.courseId]) {
        const newCourses = { ...currentUser.courses, [lesson.courseId]: lessonNumber + 1 }
        updateDetailsUser({ courses: newCourses })
      }
    } else {
      message.error('לא ענית נכון על כל השאלות, אנא נסה שוב');
    }
  }

  const handleAnswerSelected = ({ target }) => setAnswers({ ...answers, [target.name]: target.value })

  const renderQuestion = (key, question) => {
    const questionHeader = (
      <>
        <Typography.Title level={4} className={classes.questionHeaderTitle}>
          שאלה {key}
        </Typography.Title>
        {question.question}
      </>
    )
    const isLessonSolved = (lessonNumber !== -1 && canMove) || solved
    return (
      <Panel header={questionHeader} key={key}>
        <Radio.Group className={classes.answers} name={key} defaultValue={isLessonSolved ? Number(question.correctAnswer) : 1} onChange={handleAnswerSelected}>
          <Radio value={1} className={classes.option}>{question.answer1}</Radio>
          <Radio value={2} className={classes.option}>{question.answer2}</Radio>
          <Radio value={3} className={classes.option}>{question.answer3}</Radio>
          <Radio value={4} className={classes.option}>{question.answer4}</Radio>
        </Radio.Group>
      </Panel>
    )
  }

  const moveToNextLesson = () => {
    const nextLessonId = courses.find(c => c.id === lesson.courseId)?.lessons[`${getLessonNumber(lesson) + 1}`]
    history.push(`/courses/${lesson.courseId}/lessons/${nextLessonId}`);
  }

  const isFinalLesson = () => !courses.find(c => c.id === lesson.courseId)?.lessons[`${getLessonNumber(lesson) + 1}`]

  const renderSideMenuContent = () => {
    if (courses.length) {
      const currentLesson = currentUser.courses[courseId]
      const currentCourse = courses.find(c => c.id === lesson.courseId);
      return Object.keys(currentCourse.lessons).map(lessonNumber => (
        <Menu.Item icon={<BookOutlined />} disabled={Number(lessonNumber) > currentLesson} key={lessonNumber}>
          <Link to={`/courses/${lesson.courseId}/lessons/${currentCourse.lessons[lessonNumber]}`}>{`שיעור ${lessonNumber}`}</Link>
        </Menu.Item>
      ))
    }
  }

  if (!currentUser.courses[courseId]) return <Redirect to='/' />

  console.log({ courses })

  return (
    <MiddleLayout>
      {!lesson ? <Skeleton active /> :
        <div className={classes.wrapper}>
          <Modal
            wrapClassName={classes.modal}
            title='הקורס הושלם בהצלחה!'
            visible={showModal}
            onOk={() => setShowModal(false)}
            onCancel={() => setShowModal(false)}
          >
            <p>כל הכבוד! אנא צור קשר עם נציגנו לתיאום שיעורים מעשיים.</p>
            <Result status="success" title={"הקורס הושלם בהצלחה!"} />
          </Modal>
          <div className={classes.sider}>
            <PageHeader className={classes.title} title="שיעורי הקורס" />
            {lessonNumber > 0 && <Layout.Sider width={200}>
              <Menu
                mode="inline"
                defaultSelectedKeys={[`${lessonNumber}`]}
                style={{ height: '100%' }}
              >
                {renderSideMenuContent()}
              </Menu>
            </Layout.Sider>}
          </div>
          <div className={classes.rightSide}>
            <Card title={lesson.name} className={classnames(classes.content, classes.card, classes.withMenu)}>
              <div dangerouslySetInnerHTML={{ __html: lesson.content }} />
            </Card>
            <Card title="שאלות השיעור" className={classnames(classes.card, classes.questionsCard)}>
              <Collapse expandIconPosition='left' ghost className={classes.question}>
                {Object.keys(lesson?.questions).map(key => renderQuestion(key, lesson?.questions[key]))}
              </Collapse>
              {(!canMove && !solved) && <Button onClick={handleCheckAnswer} type="primary">בדוק תשובות</Button>}
              {(lessonNumber !== -1 && canMove) || solved ?
                <Result
                  status="success"
                  title={!isFinalLesson() ? "השיעור הושלם בהצלחה!" : "הקורס הושלם בהצלחה!"}
                  extra={!isFinalLesson() ? [
                    <Button onClick={moveToNextLesson} type="primary" key='button'>המשך לשיעור הבא</Button>
                  ] : []}
                />
                : null}
            </Card>
          </div>
        </div>
      }
    </MiddleLayout>
  );
};

export default Lesson;
