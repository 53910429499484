import React from "react"
import { Typography } from 'antd'
import colorClasses from '../../../style/colorClasses.module.scss'
import useMediaQuery from '../../../hooks/useMediaQuery'
import classNames from "classnames"
import classes from "./SectionHeader.module.scss"

const SectionHeader = ({ number, title, text, color, center, textWidth, className, textClassName }) => {
  const { isMobile } = useMediaQuery()

  const getTextWidth = () => {
    if (isMobile) return '100%'
    return textWidth || '40%'
  }

  return (
    <div className={classNames(
      classes.header,
      className,
      { [classes.center]: center },
      colorClasses[`border-color-${color}`]
    )}>
      <Typography.Text className={classNames(classes.title, colorClasses[`color-${color}`])}>
        {`${number} - ${title}`}
      </Typography.Text>
      {text && (
        <Typography.Text className={classNames(classes.text, textClassName, colorClasses[`color-${color}`])} style={{ width: getTextWidth() }}>
          {text}
        </Typography.Text>
      )}
    </div>
  )
}

export default SectionHeader
