import React, { useState, useEffect } from "react";
import useUser from "../../../hooks/useUser";
import { Table, Button, Popconfirm } from "antd";
import message from '../../../utils/message'
import { getUsersColumns } from '../../../utils/tableColumns'
import UsersTableCustomize from './UsersTableCustomize'
import AddUser from './AddUser'
import classes from "./UsersTable.module.scss";

const UsersTable = () => {
  const { users, getUsers, size, getUsersSize, loading, getUsersWithOffset, addUser, massDelete,
    tableCustomize, getUsersTableCustomize, updateUsersTableCustomize, error } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [visitPage, setVisitPage] = useState({ 1: true })
  const [search, setSearch] = useState({})
  const [offset, setOffset] = useState()
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    getUsersTableCustomize()
    getUsersSize()
  }, []);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (error) message.error(error);
  }, [error]);

  if (!tableCustomize) return null

  const { perPage, lastSortField } = tableCustomize

  const handleFirstSortChange = (sorter) => {
    setCurrentPage(1)
    setVisitPage({ 1: true })
    updateUsersTableCustomize({ lastSortField: { field: sorter.field, order: sorter.order } })
    getUsers(perPage, 1, sorter);
  }

  const isSortChange = sorter => (
    sorter && (sorter.field !== lastSortField.field || sorter.order !== lastSortField.order)
  )

  const handleChange = ({ current }, filters, sorter) => {
    if (isSortChange(sorter)) return handleFirstSortChange(sorter)
    if (current > currentPage + 1 || offset) {
      const newOffset = (current - 1) * perPage
      setOffset(newOffset)
      setVisitPage({ [current]: true })
      getUsersWithOffset(perPage, newOffset, sorter);
    } else if (current <= currentPage || visitPage[current]) {
      setCurrentPage(current)
    } else {
      getUsers(perPage, current, sorter);
      setVisitPage({ ...visitPage, [current]: true })
    }
    setCurrentPage(current)
  }

  const handleCustomizeChange = (values) => {
    setCurrentPage(1)
    setVisitPage({ 1: true })
    updateUsersTableCustomize(values)
  }

  const onSearchChange = (searchObject) => {
    setSearch(searchObject)
    setCurrentPage(1)
    setVisitPage({ 1: true })
    getUsers(perPage, 1, lastSortField, searchObject);
  }

  const handleMassDelete = () => {
    massDelete({ selectedRows, perPage, currentPage: 1, lastSortField })
    setSelectedRows([])
    setSelectedRowKeys([])
    message.success('המשתמשים המסומנים נמחקו')
  }

  const getPageSize = () => {
    if (!search?.field) return size
    const usersLength = users.length
    if (usersLength < (perPage * currentPage)) return usersLength
    return size
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys, newSelectedRows) => {
      setSelectedRows(newSelectedRows)
      setSelectedRowKeys(newSelectedRowKeys)
    }
  };

  return (
    <div>
      <div className={classes.top}>
        <div className={classes.rightSide}>
          <UsersTableCustomize tableCustomize={tableCustomize} onSave={handleCustomizeChange} />
          <AddUser onAdd={addUser} loading={loading} />
        </div>
        <div className={classes.leftSide}>
          <Popconfirm
            title="האם אתה בטוח שתרצה למחוק את המשתמשים המסומנים?"
            onConfirm={handleMassDelete}
            okText="כן"
            cancelText="לא"
          >
            <Button disabled={!selectedRows.length}>מחק מסומנים</Button>
          </Popconfirm>
        </div>
      </div>
      <Table
        columns={getUsersColumns(tableCustomize, onSearchChange)}
        dataSource={users}
        onChange={handleChange}
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        pagination={{
          total: getPageSize(),
          pageSize: perPage,
          current: currentPage,
          position: ['', 'bottomRight'],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
      />
    </div>
  );
};

export default UsersTable;
