import React from "react"
import classes from "./CoursesButton.module.scss"
import { Button, Typography } from "antd"
import useMediaQuery from '../../../hooks/useMediaQuery'
import { BookOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";
const { Text } = Typography;

const CoursesButton = () => {
  const { isMobile } = useMediaQuery();

  return (
    <Link to='/my_courses'>
      <div className={classes.login}>
        <Button icon={<BookOutlined />} className={classes.button} size='large' type='primary'>
          {!isMobile && <Text className={classes.text}>הקורסים שלי</Text>}
        </Button>
      </div>
    </Link>
  );
};

export default CoursesButton;
