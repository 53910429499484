import React, { useState } from "react";
import { Form, Input, Button, Card, Space, Image } from "antd"
import { UploadOutlined } from '@ant-design/icons';
import classes from "../Course/AddCourse.module.scss"
import myFirebase from "../../../firebase"
import message from '../../../utils/message'
import { useHistory, useParams } from "react-router-dom"
import { useCourse } from "../../../contexts/CourseContext"
import RichTextEditor from "../../common/RichTextEditor"
import Questions from "../../common/Questions"
import FormWrapper from '../../common/Form/FormWrapper'

const AddLesson = () => {
  const [form] = Form.useForm();
  const [lessonContent, setLessonContent] = useState('');
  const [imgUrl, setImgUrl] = useState('error');
  const [questions, setQuestions] = useState({});
  const [imgCounter, setImgCounter] = useState(0);
  const ref = myFirebase.db.collection("lessons").doc();
  const [newLessonId] = useState(ref.id);
  const history = useHistory();
  const inputRef = React.useRef(null);
  const { courses, addLessonToCourse } = useCourse();
  const { courseId } = useParams();

  const onFinish = async values => {
    try {
      const { name, ...rest } = values;
      const lesson = { name, id: newLessonId, courseId, content: lessonContent, questions, ...rest }
      const courseObject = courses.find(c => c.id === courseId);
      const numOfLessons = Object.keys(courseObject.lessons).length;
      addLessonToCourse({ currentLessons: courseObject.lessons, counter: numOfLessons + 1, lessonId: newLessonId, courseId, lesson })
      message.success("שיעור חדש נוצר בהצלחה");
      history.push(`/admin/courses/${courseId}`);
    } catch (error) {
      message.error("יצירת השיעור נכשלה")
    }
  };

  async function uploadImage(file) {
    const metadata = {
      contentType: 'image/jpg'
    };
    let imageUrl = 'error';
    const storageRef = await myFirebase.storage.ref();
    const imgFile = storageRef.child(`LessonsImages/${newLessonId}`);
    imgFile.put(file, metadata).then(async () => {
      message.success("העלאת תמונה בוצעה בהצלחה")
      setImgCounter(imgCounter + 1);
      const imageRef = storageRef.child(`LessonsImages/${newLessonId}`);
      try {
        imageUrl = await imageRef.getDownloadURL();
        setImgUrl(imageUrl);
      } catch { }
    })
      .catch(() => ({ title: 'העלאת תמונה נכשלה', description: '' }));
  }

  const handleOnFileChosen = (e) => uploadImage(e.target.files[0]);
  const handleOpenFileInput = () => {
    inputRef.current.click();
  };

  return (
    <FormWrapper>
      <Card title="טופס הוספת שיעור" className={classes.card}>
        <Form form={form} name="add_lesson" onFinish={onFinish}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "יש להזין שם שיעור" },
            ]}
          >
            <Input
              placeholder="שם השיעור"
            />
          </Form.Item>
          <div className={classes.tiny}>
            <RichTextEditor initialValue="תוכן השיעור" onChange={setLessonContent} />
          </div>


          <Space size={12}>
            {imgUrl !== 'error' && <Image
              src={imgUrl}
              width={200}
              key={imgCounter}
            />}
            <Button type="primary" block icon={<UploadOutlined />} size='large' onClick={handleOpenFileInput}>
              העלה תמונת שיעור
                <input multiple={false} ref={inputRef} style={{ display: 'none' }} type="file" onChange={handleOnFileChosen} accept="image/png, image/jpeg, image/jpg" />
            </Button>
          </Space>
          <Questions onAddQuestion={setQuestions} />
          <Form.Item shouldUpdate>
            {() => (
              <Button
                block
                className={classes.submitButton}
                type="primary"
                htmlType="submit"
                size='large'
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                הוסף שיעור
              </Button>
            )}
          </Form.Item>
        </Form>
      </Card>
    </FormWrapper>
  );
};

export default AddLesson;
