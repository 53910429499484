import { notification } from "antd"

const useNotification = () => {
  const raiseNotification = ({ title, description }) => {
    notification.open({
      message: title,
      description,
    });
  };

  const raiseNotificationWithIcon = ({ message, description, type }) => {
    notification[type]({
      message,
      description,
    });
  };
  return {
    raiseNotification,
    raiseNotificationWithIcon
  }
}

export default useNotification
