import React from "react"
import classes from "./Logo.module.scss"
import { useHistory } from "react-router-dom"

const Logo = () => {
  const history = useHistory()
  const handleOnClick = () => history.push('/')
  return (
    <div onClick={handleOnClick} className={classes.logoWrapper}>
      <div className={classes.cover}>
        <img
          src='https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/new_logo.png?alt=media&token=070ce945-ab61-426a-9232-2aac0c4f4073'
          alt="לוגו"
          title="לוגו"
          className={classes.logo}
        />
      </div>
    </div>
  )
}

export default Logo
