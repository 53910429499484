import { useState, useEffect } from "react";
import myFirebase from "../firebase";
import errorMessages from "../utils/errorMessages";
import { compactHash } from '../utils/utils'

const { db, storage } = myFirebase

const useBlog = (params = {}) => {
  const { fetchBlogs, fetchBlogsWithImages, fetchBlog, blogId } = params

  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(fetchBlogs || fetchBlog);
  const [error, setError] = useState();
  const [blog, setBlog] = useState();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (fetchBlogs || fetchBlogsWithImages) getBlogs()
    if (fetchBlog) getBlog()
  }, [])

  const getBlogs = async () => {
    setLoading(true)
    try {
      const blogsSnapshot = await db.collection("blogs").get()
      const storageRef = storage.ref()
      const blogsList = [];
      const promises = []
      blogsSnapshot.forEach(blogData => {
        const data = blogData.data()
        blogsList.push({ ...data, key: data.id })
        if (fetchBlogsWithImages) {
          const imageRef = storageRef.child(`BlogsImages/${data.id}`);
          promises.push(imageRef.getDownloadURL())
        }
      });
      if (fetchBlogsWithImages) {
        const urlsList = await Promise.all(promises.map(p => p.catch(() => false)))
        urlsList.forEach((imageUrl, index) => blogsList[index] = { ...blogsList[index], imageUrl })
      }
      setBlogs(blogsList)
      setLoading(false)
    } catch (error) {
      setError(errorMessages(error.code))
    }
  }

  const getBlog = async () => {
    setLoading(true);
    try {
      const blogDoc = await db.collection("blogs").doc(blogId).get();
      const blogData = blogDoc.data()
      const storageRef = storage.ref()
      try {
        const imageUrl = await storageRef.child(`BlogsImages/${blogData.id}`).getDownloadURL();
        blogData.imageUrl = imageUrl
      } catch {
        blogData.imageUrl = null
      }
      setBlog(blogData);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const addBlog = async ({ blog }) => {
    setLoading(true)
    setSuccess(false)
    try {
      await db.collection("blogs").doc(blog.id).set(compactHash(blog));
      setLoading(false)
      setSuccess(true)
    } catch (error) {
      setLoading(false)
      setSuccess(false)
      setError(errorMessages(error.code))
    }
  };

  const updateBlog = async (blog) => {
    try {
      await db.collection("blogs").doc(blog.id).update(blog);
    } catch {
      console.log('Error while updating course')
    }
  }

  const massDelete = async (selectedRowsValues) => {
    setLoading(true)
    setError(false)
    try {
      const batch = myFirebase.db.batch()
      selectedRowsValues.forEach((deleteBlog) => {
        const deleteBlogRef = myFirebase.db.collection('blogs').doc(deleteBlog.id)
        batch.delete(deleteBlogRef)
      })
      await batch.commit()
      getBlogs()
    } catch (error) {
      setError(errorMessages(error.code));
      setLoading(false);
    }
  }

  const data = {
    blogs,
    blog,
    loading,
    success,
    error,
    addBlog,
    updateBlog,
    massDelete
  }

  return data
};

export default useBlog;
