import React, { useContext, useState, useMemo, useCallback } from "react";
import myFirebase from "../firebase";
const { storage } = myFirebase
const { db } = myFirebase

const StorageContext = React.createContext();

export const useStorage = () => {
  return useContext(StorageContext);
};

export const StorageProvider = ({ children }) => {
  const [coverImages, setCoverImages] = useState([])
  const [coverImagesCounter, setCoverImagesCounter] = useState(-1)

  const getCoverImages = useCallback(async () => {
    try {
      const storageRef = storage.ref()
      const listRef = storageRef.child('CoverImages');
      const imagesList = await listRef.listAll()
      const promises = []
      imagesList.items.forEach(image => {
        promises.push(image.getDownloadURL())
      })
      const urlsList = await Promise.all(promises)
      let textsData = {}
      try {
        const textsSnapshot = await db.collection("texts").doc('cover').get();
        textsData = textsSnapshot.data()
      } catch {
        console.log('texts fails to load')
      }

      setCoverImagesCounter(urlsList.length)
      preLoadImages(urlsList)
      const header1 = urlsList.filter(item => item.includes('header1.'))[0]
      const header1Mobile = urlsList.filter(item => item.includes('header1-mobile'))[0]
      const header2 = urlsList.filter(item => item.includes('header2.'))[0]
      const header2Mobile = urlsList.filter(item => item.includes('header2-mobile'))[0]
      const header3 = urlsList.filter(item => item.includes('header3.'))[0]
      const header3Mobile = urlsList.filter(item => item.includes('header3-mobile'))[0]
      setCoverImages([
        { desktopUrl: header1, mobileUrl: header1Mobile, ...textsData?.texts?.[0] },
        { desktopUrl: header2, mobileUrl: header2Mobile, ...textsData?.texts?.[1] },
        { desktopUrl: header3, mobileUrl: header3Mobile, ...textsData?.texts?.[2] },
      ])

    } catch {
      setCoverImages([])
    }
  }, [])

  const preLoadImages = (images) => {
    const imageLoad = () => {
      setCoverImagesCounter((count) => count - 1)
    }

    images.forEach((image) => {
      const img = new Image();
      img.onload = imageLoad
      img.src = image
    })
  }

  const value = useMemo(() => ({
    coverImages,
    getCoverImages,
    coverImagesCounter
  }))

  return (
    <StorageContext.Provider value={value}>{children}</StorageContext.Provider>
  );
};
