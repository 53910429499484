import React, { useEffect } from "react";
import message from '../../utils/message'
import { Typography } from "antd";
import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import UserForm from "../common/UserForm";
import MiddleLayout from "../common/MiddleLayout";
import FormWrapper from '../common/Form/FormWrapper'
import classes from './SignUp.module.scss'

const SignUp = () => {
  const { currentUser, signUp, loading, error } = useAuth();
  const history = useHistory();

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (currentUser) history.push("/");
  }, [currentUser]);

  const handleSave = values => {
    signUp(values);
  };

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (error) message.error(error);
  }, [error]);

  return (
    <MiddleLayout>
      <FormWrapper size='small'>
        <Typography.Text className={classes.header}>הצטרפות</Typography.Text>
        <UserForm onSave={handleSave} loading={loading} />
      </FormWrapper>
    </MiddleLayout>
  );
};

export default SignUp;
