import React from "react";
import { Editor } from '@tinymce/tinymce-react';
import myFirebase from "../../firebase";
import message from "../../utils/message";

const RichTextEditor = ({ onChange, initialValue }) => {
  return (
    <Editor
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: false,
          directionality: 'rtl',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'lists link image paste help wordcount textcolor table'
          ],
          toolbar:
              'undo redo | formatselect | bold italic underline forecolor backcolor | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | link image | media | help\
              table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          file_picker_types: 'image',
          file_picker_callback: function (cb, value, meta) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            /*
              Note: In modern browsers input[type="file"] is functional without
              even adding it to the DOM, but that might not be the case in some older
              or quirky browsers like IE, so you might want to add it to the DOM
              just in case, and visually hide it. And do not forget do remove it
              once you do not need it anymore.
            */

            input.onchange = function () {
              const file = this.files[0];

              const reader = new FileReader();
              reader.onload = async () => {
                /*
                  Note: Now we need to register the blob in TinyMCEs image blob
                  registry. In the next release this part hopefully won't be
                  necessary, as we are looking to handle it internally.
                */
                const id = 'blobid' + (new Date()).getTime();
                const blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                const metadata = {
                  contentType: 'image/jpg'
                };
                let imageUrl = 'error';
                const storageRef = await myFirebase.storage.ref();
                const imgFile = storageRef.child(`RiceTextContentImages/${id}`);
                imgFile.put(file, metadata).then(async () => {
                  message.success("העלאת תמונה בוצעה בהצלחה")
                  const imageRef = storageRef.child(`RiceTextContentImages/${id}`);
                  try {
                    imageUrl = await imageRef.getDownloadURL();
                    cb(imageUrl, { title: file.name });
                  } catch { message.error('העלאת תמונה נכשלה') }
                })
                  .catch(() => message.error('העלאת תמונה נכשלה'));
                /* call the callback and populate the Title field with the file name */
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },
        }}
        onEditorChange={onChange}
    />
  );
};

export default RichTextEditor;
