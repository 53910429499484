import React from "react"
import MiddleLayout from '../../common/MiddleLayout'
import Graduates from './Graduates'
import { Typography, Tabs, Skeleton } from "antd";
import { Img } from 'react-image'
import ContactUs from '../ContactUs/ContactUs'
import SocialButtons from '../SocialButtons/SocialButtons'
import Row from '../../common/Row'
import classes from "../HowToStudy/HowToStudy.module.scss"
import useMediaQuery from '../../../hooks/useMediaQuery'
import { useLocation } from "react-router-dom"
import * as qs from 'query-string';

const { Text } = Typography
const { TabPane } = Tabs;

const About = () => {
  const { isMobile } = useMediaQuery()
  const { search } = useLocation()
  const params = qs.parse(search);
  const renderSkeleton = () => <div><Skeleton.Button active className={classes.skeleton} /></div>
  const aboutText = {
    __html: `מכלול, בית הספר הטכנולוגי המתקדם בישראל, נוסד במטרה לתת מענה לצורך הולך ומתגבר בלימודי תעודה מקצועיים, איכותיים וממוקדי מטרה, בתחומי לימוד המאפשרים להשתלב בהצלחה בשוק העבודה בישראל. מתוך ראיה מפוקחת את המגמות המשתנות במשק הישראלי במסחר ובתעשייה.
 מכלול מתמקדת בתחומים הטכניים המשגשגים והנכונים ביותר למשק הישראלי. בכך היא מאפשרת לבוגריה ותלמידיה להיות תמיד הבחירה הראשונה של המעסיקים. שילוב אידיאלי של לימודי תיאוריה ופרקטיקה מעשית מכין את התלמידים במדויק לעבודה שיבצעו בפועל, וכך ידם תמיד על העליונה. רוצה גם אתה להבטיח את עתידך המקצועי? בוא ללמוד מקצוע מעשי ומרתק במכלול ולהפוך למקצוען אמיתי!

<b>כשהבסיס טוב - הכל טוב</b>
במכלול מאמינים בבסיס איכותי ומושקע, שמאפשר לך להמריא לגבהים מקצועיים שתמיד חלמת עליהם. הקורס הנכון, בתנאים הנכונים, במקום הנכון - יכול לשנות את חייך.

<b>צוות הוראה מעולה</b>
מורה טוב הוא מורה לחיים, ובמכלול בוחרים את המורים בקפידה יתרה. מעבר לכישורי הוראה בלתי מעורערים, המורים שלנו מגיעים מהשטח - כל אחד מהם מגיע עם ניסיון מעשי רחב יריעה בתחומו, ואצבע על הדופק לכל המגמות והחידושים, הן בשיטות וכלי העבודה, והן במוצרים חדשים, פיתוחים טכנולוגיים ותקנים בעבודה ובבטיחות.

<b>כיתות מאובזרות</b>
במכלול תמצא כיתות לימוד נוחות, מרווחות וממוזגות, המאפשרות לך להתרכז במה שחשוב באמת - החומר הנלמד. מעבר לכיתות התיאוריה מציעה המכללה מעבדות מקצועיות לכל תחום לימוד. כל מעבדה מצוידת בכלי עבודה מהשטח, מוצרים מכל קטגוריה קיימת המתאימים לתחום הנלמד, ומספיק מקום כדי להתאמן כמו שצריך בפירוק, הרכבה, תיקון והתקנה.

<b>חומרי לימוד מקוריים</b>
על חומרי הלימוד שמספקת המכללה לתלמידיה אמונים מיטב המוחות המקצועיים בישראל. השיעורים התאורטיים מועברים דרך המחשב או הטלפון של הלמד ע"י סרטוני הדרכה מקוריים והסברים טכניים מפורטים, המפוקחים ומבוקרים בקפידה על ידי הסגל המקצועי במכללה, ואף נכתבים בידי סגל ההוראה עצמו.

<b>חושבים עליך בראש ובראשונה</b>
אנחנו במכלול רואים בכם תלמידינו ובוגרינו - חלק מאתנו. אתם הנציגים האמיתיים שלנו מחוץ לגבולות המכללה, במהלך לימודיהם ובמיוחד לאחריהם. לכן אנו תמיד כאן בשבילכם !

<b>גמישות מרבית</b>
על מנת לסייע לכם לשמור על שגרת חייכם ויומכם במסגרת הלימודים, ואפשרות להחזקה במשרה הנוכחית - מסלולי הלימוד במכללה מתקיימים במהלך כל שעות היום (לימודי בוקר וערב) כולל ימי שישי.

<b>תנאים מצוינים</b>
מעבר לאסתטיקה, מיזוג ונוחות בכיתות הלימוד ובשטח המכללה, לשירות התלמידים עומדות עמדות הכנת שתייה חמה, קרה וכיבוד קל ללא עלות.

<b>תמיד איתך</b>
סיימת את לימודיך ואתה זקוק להנפקת אישורים ומסמכים או סתם לחזור על החומר? פעם תלמיד במכלול, תמיד תלמיד במכלול. צור קשר עם מזכירות המכללה ונשמח לסייע בכל דרך, בכל עת.

<b>כשלומדים נכון - עובדים נכון</b>
לימודי המקצוע במכלול, מטרתם היא רכישת מקצוע שיאפשר לכם להשתלב עימו בשוק העבודה בישראל, או להקים עסק משלכם. אנחנו מלווים אתכם לאורך כל הדרך.

<b>סדנאות העשרה והסמכות חיוניות</b>
במסגרת לימודיכם תחשפו לשיטות עבודה שונות, תקבלו הסמכות ייחודיות הקשורות לתחום לימודיכם – המכללה יצרה ויוצרת תמיד שיתופי פעולה עם החברות המובילות בשוק, כתלמידי המכללה, תעברו סדנאות מקצועיות למציאת עבודה, שיפור כישורי התקשורת והניהול ופתיחת עסק עצמאי !

<b>סיוע בפתיחת עסק</b>
אם החלום שלך הוא לפתוח עסק עצמאי, סגל המכללה גאה לסייע לך להגשים אותו. מקישורים לספקי הציוד והחומרים המובילים בארץ, דרך הנחיות, ליווי ותמיכה בהקמת העסק והעמדתו על הרגליים, ייעוץ לדרכי פרסום ושיווק ועד שיתופי פעולה עם הגופים והקרנות המובילים בארץ לתכנון ובניית עסקים קטנים - אנחנו איתך, לאורך כל הדרך.

<b>אלפי בוגרים אינם טועים</b>
בוגרי הקורסים של מכלול השתלבו בהצלחה רבה בשוק העבודה והם מועסקים ע"י מיטב החברות והארגונים המובילים במשק הישראלי!
עכשיו, נותר לך לבחור באיזה קורס אתה מעוניין להשתלב ו... קדימה להצלחה!`
  };

  return (
    <MiddleLayout>
      <div className={classes.imageWrapper}>
        <Img
          src={isMobile ? 'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/headersImages%2Fabout_cover_mobile.png?alt=media&token=f97ae1c2-6895-4dcb-9a24-8dd64af6e4d9' : 'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/headersImages%2Fabout_cover.png?alt=media&token=18eb6169-1b94-463b-8a5a-689a5d476208'}
          className={classes.image}
          loader={renderSkeleton()}
        />
        <Text className={classes.imageTitle}>אודות</Text>
      </div>
      <Tabs defaultActiveKey={params?.tab || '1'} className={classes.tab} size='large'>
        <TabPane tab="אודות מכלול" key="1">
          <div className={classes.tabContent}>
            <span dangerouslySetInnerHTML={aboutText} />
          </div>
        </TabPane>
        <TabPane tab="בוגרי מכלול" key="2">
          <Graduates />
        </TabPane>
        <TabPane tab="דבר היו״ר" key="3">
          <div className={classes.tabContent}>
            {`מועמדות ומועמדים יקרים,
            אנו במכלול מחדשים ומתחדשים עבורכם ולמענכם בכדי להכין אתכם לאתגרי המחר. מכלול היא קהילה לומדת חדשנית שנותנת כלים חיוניים לחיים: עבודה מעשית, עבודת צוות, גמישות מחשבתית, מיקוד מסרים ופתרון בעיות מורכבות. שיטות ההוראה ותחומי הלימוד שלנו מתעדכנים תדיר לפי דרישות המעסיקים בישראל, ואנו מכשירים את בוגרינו למקצועיות, ליזמות ולהתמודדות עם אתגרי המחר.

            אנו מקדישים מחשבה, מאמצים ומשאבים כדי להפוך את חוויית הלימודים במכלול לחוויה חיובית, מאפשרת ומרחיבת אופקים. הלימודים אינם מוגבלים לחדרי הכיתה אלא כוללים התנסויות מעשיות, סימולציות והטמעה של כלי העבודה המקצועיים. כך אנו מייצרים עבורכם יתרון משמעותי בשוק התעסוקה העתידי.

            תקופת הלימודים יכולה לשמש מנוף משמעותי לשינוי אישי וכלכלי. לכן אנו מקיימים מגוון פעילויות בשיתוף החברות הגדולות במשק הישראלי, המסייעת לסטודנטים שלנו להשתלב באופן מיטבי בשוק העבודה.

            לשמחתי, לכלל היוזמות הללו יש ביטוי בהצלחת תלמידנו המשתלבים בחברות המובילות בשוק התעסוקה, ביטוי נוסף להצלחה עולה מכוון התלמידים שבחרו להיות קבלנים מבצעיים או נותני שרות עצמאים.

            אני שמח שבחרתם לבחון את האפשרות ללמוד כאן ומזמין אתכן ואתכם לראות מעבר לאופק. במכלול תזכו בחוויית לימודים מעשירה, מעצימה ותומכת עם סגל מיומן, קשוב ומקצועי ועם תנאים פיזיים מיטביים שיוצרים חיי לימוד תוססים.

            כל אלו יאפשרו לכם ללמוד, ליהנות ולהצליח.

            על החתום,
            יו"ר שגיב איטח
            יו"ר דור מלכה
            `}
          </div>
        </TabPane>
      </Tabs>
      <Row color='orange'>
        <ContactUs inRow />
      </Row>
      <SocialButtons />
    </MiddleLayout>
  )
}

export default About;
