import React, { useState } from "react";
import { Form, Button, Checkbox, Modal, Select, Divider } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";
import { usersColumns } from '../../../utils/tableColumns'
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import classes from "./UsersTableCustomize.module.scss";
import arrayMove from 'array-move';

const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

const SortableItem = sortableElement(({ value, columnsList, ...rest }) => {
  return (
    <li className={classes.listItem}>{renderItem(value, columnsList)}</li>
  )
})

const renderItem = (field, columnsList) => (
  <Form.Item name={field} valuePropName="checked" className={classes.checkbox}>
    <Checkbox>{columnsList[field].title}</Checkbox>
  </Form.Item>
)

const UsersTableCustomize = ({ tableCustomize, onSave }) => {
  const { order, show, perPage } = tableCustomize
  const [isModalVisible, setIsModalVisible] = useState(false);
  const columnsList = usersColumns({})
  const [newOrder, setNewOrder] = useState(order)

  const openModal = () => setIsModalVisible(true)
  const closeModal = () => setIsModalVisible(false)

  const onSortEnd = ({ oldIndex, newIndex }) => setNewOrder(arrayMove(newOrder, oldIndex, newIndex))

  const onFinish = ({ perPage: newPerPage, ...newShow }) => {
    onSave({ order: newOrder, perPage: newPerPage, show: newShow })
    closeModal()
  }

  const handleCancel = () => {
    setNewOrder(order)
    closeModal()
  }

  return (
    <div>
      <UnorderedListOutlined className={classes.icon} onClick={openModal} />
      <Modal
        title="הגדרות טבלה"
        placement="right"
        closable={false}
        onCancel={closeModal}
        visible={isModalVisible}
        footer={null}
      >
        <Form
          name="customize"
          onFinish={onFinish}
          initialValues={{ ...show, perPage }}
          key={isModalVisible}
        >
          <SortableContainer onSortEnd={onSortEnd}>
            {newOrder.map((value, index) => (
              <SortableItem key={`item-${value}`} index={index} value={value} columnsList={columnsList} />
            ))}
          </SortableContainer>
          <Divider />
          <Form.Item label="שורות לדף" name='perPage'>
            <Select>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="40">40</Select.Option>
              <Select.Option value="80">80</Select.Option>
              <Select.Option value="100">100</Select.Option>
            </Select>
          </Form.Item>
          <Divider />
          <div className={classes.buttons}>
            <div className={classes.submit}><Button type="primary" htmlType="submit">שמור</Button></div>
            <Button onClick={handleCancel}>בטל</Button>
          </div>
        </Form>
      </Modal>
    </div >
  );
};

export default UsersTableCustomize;
