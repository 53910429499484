import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Form, Input, Button, Typography } from "antd";
import { UserOutlined, LockOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import InlineFieldWrapper from "../common/Form/InlineFieldWrapper";
import classes from "./SignIn.module.scss";
const { Text } = Typography;

const SignIn = ({ onSuccess, onError }) => {
  const { signIn, loading, success, error, forgotPassword } = useAuth();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({}); // To disable submit button at the beginning.
  const [isForgotPassword, setIsForgotPassword] = useState(false)

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    const message = isForgotPassword ? 'מייל לשינוי סיסמא נשלח' : 'התחברת בהצלחה'
    if (success && onSuccess) onSuccess(message)
  }, [success, isForgotPassword]);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    if (error && onError) onError(error)
  }, [error]);

  useEffect(() => {
    console.log('THIS IS USE EFFECT')
    forceUpdate({});
  }, []);

  const handleForgotPassword = () => setIsForgotPassword(!isForgotPassword)

  const onFinish = values => {
    if (isForgotPassword) forgotPassword(values)
    else signIn(values)
  };

  const renderEmail = () => (
    <Form.Item
      name="email"
      rules={[
        { required: true, message: "יש להזין מייל" },
        { type: "email", message: "יש להזין מייל חוקי" }
      ]}
    >
      <InlineFieldWrapper placeholder="מייל" withIcon showLabelAlways>
        <Input prefix={<UserOutlined className={classes.icon} />} />
      </InlineFieldWrapper>
    </Form.Item>
  )

  const renderButton = () => (
    <Form.Item shouldUpdate className={classes.button}>
      {() => (
        <div className={classes.buttonContent}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={
              !form.isFieldsTouched(true) ||
              !!form.getFieldsError().filter(({ errors }) => errors.length)
                .length
            }
          >
            {isForgotPassword ? 'שלח מייל' : 'התחבר'}
          </Button>
          {!isForgotPassword && <Text className={classes.forgotPasswordLink} onClick={handleForgotPassword}>שכחתי סיסמא</Text>}
        </div>
      )}
    </Form.Item>
  )

  const renderPassword = () => (
    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: "יש להזין סיסמא"
        }
      ]}
    >
      <InlineFieldWrapper placeholder="סיסמא" withIcon showLabelAlways>
        <Input
          prefix={<LockOutlined className={classes.icon} />}
          type="password"
        />
      </InlineFieldWrapper>
    </Form.Item>
  )

  const renderBackIcon = () => (
    <div className={classes.back} onClick={handleForgotPassword}>
      <Text className={classes.backText}>חזרה</Text>
      <ArrowLeftOutlined className={classes.arrowIcon} />
    </div>
  )

  return (
    <div>
      <Form form={form} name="login" onFinish={onFinish}>
        {isForgotPassword && renderBackIcon()}
        {renderEmail()}
        {!isForgotPassword && renderPassword()}
        {renderButton()}
      </Form>
    </div>
  );
};

export default SignIn;
