import React from "react";
import { Form, Input, Button } from "antd";
import InlineFieldWrapper from "../../common/Form/InlineFieldWrapper";

const UserChangeAuth = ({ onSave, loading, initialValues }) => {
  const [form] = Form.useForm();

  const layout = {
    labelCol: { span: 6 }
  };

  return (
    <Form
      name="basic"
      onFinish={onSave}
      scrollToFirstError
      initialValues={initialValues}
      form={form}
      {...layout}
    >

      <Form.Item
        name="oldPassword"
        dependencies={["password", "password2"]}
        rules={[
          { required: true, message: "יש להזין סיסמא" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && getFieldValue("password")) {
                return Promise.reject(
                  new Error("אנא הכנס סיסמא ישנה בשביל להחליף סיסמא")
                );
              }
              return Promise.resolve();
            }
          })
        ]}
      >
        <InlineFieldWrapper placeholder="* סיסמא ישנה">
          <Input.Password />
        </InlineFieldWrapper>
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { type: "email", message: "יש להזין מייל חוקי" }
        ]}
      >
        <InlineFieldWrapper placeholder="מייל חדש">
          <Input />
        </InlineFieldWrapper>
      </Form.Item>

      <Form.Item
        name="password"
        hasFeedback
        rules={[
          () => ({
            validator(_, value) {
              if (!value || value?.length >= 6) return Promise.resolve();
              return Promise.reject(new Error("הסיסמא חייבת להיות באורך 6"));
            }
          })
        ]}
      >
        <InlineFieldWrapper placeholder='סיסמא חדשה'>
          <Input.Password />
        </InlineFieldWrapper>
      </Form.Item>

      <Form.Item
        name="password2"
        hasFeedback
        dependencies={["password"]}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value)
                return Promise.resolve();
              return Promise.reject(new Error("סיסמה לא תואמת"));
            }
          }),
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (getFieldValue("password") && !value)
                return Promise.reject(new Error("אנא הכנס אימות סיסמא חדשה"));
              return Promise.resolve();
            }
          })
        ]}
      >
        <InlineFieldWrapper placeholder='אימות סיסמא'>
          <Input.Password />
        </InlineFieldWrapper>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          עדכן
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserChangeAuth;
