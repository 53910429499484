import React, { useState } from "react"
import classes from "./ProfileButton.module.scss"
import Popover from '../../common/Popover'
import { Button, Typography, message } from "antd"
import { UserOutlined } from '@ant-design/icons'
import { useAuth } from '../../../contexts/AuthContext'
import { useHistory } from "react-router-dom";
import useMediaQuery from '../../../hooks/useMediaQuery'
const { Text } = Typography;

const ProfileButton = () => {
  const { isMobile } = useMediaQuery();
  const [forceClose, setForceClose] = useState();
  const { signOut } = useAuth();
  const history = useHistory();

  const handleSignOut = () => {
    signOut();
    history.push('/');
    message.success('התנתקת בהצלחה')
  }

  const handleAccountClick = () => {
    history.push("/profile");
    setForceClose(true)
    setTimeout(() => {
      setForceClose(false)
    }, 0);
  }

  const renderContent = () => (
    <ul className={classes.list}>
      <li className={classes.item} onClick={handleAccountClick}>
        <Text>חשבון</Text>
      </li>
      <li className={classes.item} onClick={handleSignOut}>
        <Text >התנתק</Text>
      </li>
    </ul>
  )

  return (
    <div className={classes.profile}>
      <Popover content={renderContent} width={155} forceClose={forceClose}>
        <Button className={classes.button} size={isMobile ? 'medium' : 'large'} >
          <UserOutlined />
        </Button>
      </Popover>
    </div>
  );
};

export default ProfileButton;
