import React from "react";
import SectionHeader from './SectionHeader'
import MiddleLayout from "../../common/MiddleLayout"
import SectionScrollDetected from './SectionScrollDetected'
import useMediaQuery from '../../../hooks/useMediaQuery'
import classes from "./Section5.module.scss";

const Section5 = ({ onVisisbleChange }) => {
  const { isMobile } = useMediaQuery()

  const src = isMobile ?
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection5-mobile.jpg?alt=media&token=5626a7dd-dd9c-453a-8712-37b471dfaafd' :
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fsection5.jpg?alt=media&token=dd3cfca6-584c-4b39-a45e-9ce822d32f2d'

  return (
    <div className={classes.section} style={{ backgroundImage: `url(${src})` }}>
      <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
      <MiddleLayout>
        <SectionHeader
          number='5'
          color='dark-brand'
          title='גמישות מרבית. הבחירה שלך!'
          text={
            `אנו במכלול שמים דגש על לימוד מעשי בקבוצות קטנות המאפשרות יחס אישי ותקשורת מרבית עם המורה ועם הסטודנטים האחרים. \n
          מכלול שם לעצמו דגש על מעבדות לימוד מקצועיות ומאובזרות ברמה הגבוה ביותר המאפשרות לסטודנט תרגול מעשי באופן מקיף תוך מתן יחס אישי לכל סטודנט.
          `}
        />
      </MiddleLayout>
    </div>
  );
};

export default Section5;
