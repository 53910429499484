import React from "react";
import { Typography } from 'antd';
import Slide from 'react-reveal/Slide';
import SectionScrollDetected from './SectionScrollDetected'
import { ArrowDownOutlined } from '@ant-design/icons';
import useMediaQuery from '../../../hooks/useMediaQuery'
import classes from "./TopImage.module.scss";

const TopImage = ({ onVisisbleChange, section1Ref }) => {
  const { isMobile } = useMediaQuery()

  const handleDownButtonClick = () => {
    section1Ref.current.scrollIntoView({ behavior: "smooth", inline: "nearest", block: "start" })
  }

  const src = isMobile ?
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fcover-mobile.jpg?alt=media&token=51b603fe-a6f7-4f6c-bd47-ab5b284b7368' :
    'https://firebasestorage.googleapis.com/v0/b/michlol.appspot.com/o/Intersted%2Fcover.jpg?alt=media&token=be08e1cc-e13e-4a04-bc2b-87349b204997'

  return (
    <div className={classes.imageWrapper}>
      <SectionScrollDetected onVisisbleChange={onVisisbleChange} />
      <img className={classes.image} src={src} />
      <div className={classes.textWrapper}>
        <Slide bottom>
          <Typography.Title className={classes.title}>{`
          7 סיבות לבחור
          במכלול
          `}</Typography.Title>
          <Typography.Text className={classes.text}>{`
          אתם רוצים ללמוד מקצוע ולהשתלב כאנשי מקצוע במשק הישראלי,
          אבל החיים דינמיים ומהירים ואתם לא רוצים לעצור אותם אפילו לרגע.
          מכלול מציעה לכם לימודי תעודה במגוון מסלולי לימוד
          המשתלבים במסלול החיים שלכם.
          `}</Typography.Text>
          <div className={classes.downButton} onClick={handleDownButtonClick}>
            <ArrowDownOutlined className={classes.icon} />
          </div>
        </Slide>
      </div>
    </div>
  )
}

export default TopImage
