import React from "react";
import Slider from '../../common/Slider/Slider'
import { Typography } from "antd"
import { Link } from "react-router-dom";
import useBlog from "../../../hooks/useBlog"
import useMediaQuery from '../../../hooks/useMediaQuery'
import classes from './CoursesSlider.module.scss'

const { Text } = Typography

const BlogsSlider = () => {
  const { blogs, loading } = useBlog({ fetchBlogsWithImages: true });
  const { isMobile, isTablet } = useMediaQuery()

  if (loading) return <div>Loading</div>

  const getChunkNumber = () => {
    let chunkNumber = 3
    if (isTablet) chunkNumber = 2
    if (isMobile) chunkNumber = 1
    if (blogs.length < chunkNumber) chunkNumber = blogs.length
    return chunkNumber
  }

  const chunkNumber = getChunkNumber()

  return (
    <Slider title='בלוג' chunkNumber={chunkNumber} color='dark-brand' oneSlider={chunkNumber === blogs.length}>
      {blogs.map((blog) => (
        <Slider.Item key={blog.id}>
          <Link to={`/blog/${blog.id}`}>
            <div className={classes.imageSlider}>
              <img src={blog.imageUrl} className={classes.image} />
              <div className={classes.textWrapper}>
                <Text className={classes.text}>{blog.name}</Text>
              </div>
            </div>
          </Link>
        </Slider.Item>
      ))}
    </Slider>
  );
};

export default BlogsSlider;
