import React, { useState } from "react"
import classes from "./LoginButton.module.scss"
import Popover from '../../common/Popover'
import useMediaQuery from '../../../hooks/useMediaQuery'
import { Button, Typography } from "antd"
import message from '../../../utils/message'
import { UnlockOutlined } from '@ant-design/icons'
import SignIn from '../SignIn'
const { Text } = Typography;

const LoginButton = () => {
  const [forceClose, setForceClose] = useState()
  const { isMobile } = useMediaQuery();

  const handleSuccess = (content) => {
    setForceClose(true)
    message.success(content)
  }

  const handleError = (content) => {
    message.error(content)
  }

  const renderContent = () => (
    <SignIn onSuccess={handleSuccess} onError={handleError} />
  )

  return (
    <div className={classes.login}>
      <Popover content={renderContent} forceClose={forceClose} width={300}>
        <Button className={classes.button} size='large' type='primary'>
          <UnlockOutlined className={classes.icon} />
          {!isMobile && <Text className={classes.text}>כניסה</Text>}
        </Button>
      </Popover>
    </div>
  );
};

export default LoginButton;
